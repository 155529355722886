import moment from "moment";

export const dateToString = date => moment(date).format('MM-DD-YYYY');

export const weekDayDict = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday'
}

// use this to handle timezone
export const mapDateToString = (str, yearFirst = false) => {
    const year = str.slice(0, 4);
    const month = str.slice(5, 7);
    const day = str.slice(8, 10);
    return yearFirst ? `${year}-${day}-${year}`: `${month}-${day}-${year}`;
}

export const getDates = (startDate, endDate) => {
    const dateArray = [], stopDate = moment.utc(endDate);
    let currentDate = moment.utc(startDate);
    while (currentDate <= stopDate) {
        dateArray.push(dateToString(currentDate));
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

export const disableDates = (date, includesDates, mode, excludeDates) => {
    const newDate = date.toISOString().replace(/(T\d{2})/, 'T00');//MUI is using Local Time Zone
    if(mode === 'edit item') return excludeDates.includes(date.toISOString());
    if(mode === 'add item') return false;
    return !includesDates.includes(newDate);
};

export const dateHelper = calendarDate => ({ year, month, date }) => date === calendarDate.getDate() && month === calendarDate.getMonth() + 1 && year === calendarDate.getFullYear();

export const getDateType = item => {
    const year = Number(item?.slice(0, 4)), month = Number(item?.slice(5, 7)), date = Number(item?.slice(8, 10));
    return { year, month, date }
};

export const checkBrowserIsFirefox = () => navigator.userAgent.search("Firefox") > -1;

export const checkOverLap = (mode, date, slots, startDate) => {
    if (mode === 'start' && slots.findIndex(s => s.startDate === startDate)) {
        const getLastEndDate = slots[slots.findIndex(s => s.startDate === startDate) - 1]?.endDate;
        if ((new Date(getLastEndDate)).getTime() >= (new Date(date)).getTime()) {
            return true;
        }
    }
    else if (mode === 'end' && slots.findIndex(s => s.startDate === startDate) !== slots.length - 1) {
        const getNextStartDate = slots[slots.findIndex(s => s.startDate === startDate) + 1]?.startDate;
        if ((new Date(getNextStartDate)).getTime() <= (new Date(date)).getTime()) {
            return true;
        }
    }
    return false;
}

export const checkDateValid = d => d instanceof Date && !isNaN(d.getTime());

export const setHours = (time, date) => {
    const today = new Date(date);
    today.setHours(time.slice(0, 2));
    today.setMinutes(time.slice(3, 5));
    today.setSeconds(0);
    return today;
}

export const handleTimeZoneOffset = time => {
    console.log(time.getTimezoneOffset(), 76666);
    const offset = time.getTimezoneOffset() * 60000;
    return new Date(time.getTime() + offset);
}
export const getTimeFromDate = dateObject => {
    // Extract hours, minutes, and seconds from the Date object
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const seconds = dateObject.getSeconds();

    // Format the hours, minutes, and seconds with leading zeros if needed
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const formatDate = (dateObject, leadingZero = true) => {
    // Extract month, date, and year from the Date object
    const month = dateObject.getMonth() + 1; // Months are zero-based in JavaScript
    const date = dateObject.getDate();
    const year = dateObject.getFullYear();

    if (leadingZero) {
        // Format the month, date, and year with leading zeros if needed
        const formattedMonth = String(month).padStart(2, '0');
        const formattedDate = String(date).padStart(2, '0');

        // Return the formatted date string
        return `${formattedMonth}-${formattedDate}-${year}`;
    }
    return `${month}-${date}-${year}`;
}
