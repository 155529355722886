import axios from 'axios';
import React from 'react';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

const LoadingPage = () => {
    const bookableItem = useSelector((store) => store.selectedBookableItem);
    const dispatch = useDispatch();
    const {id} = useParams();

    useEffect(() => {
        const init = async() => {
            const { data: account } = await axios.get('/stripe/account-wrapper', { params: { id: id } });
            dispatch({
                type: 'SET_ACCOUNT_WRAPPER',
                payload: account,
            });
        }
        init()
    }, [bookableItem]);

    return (
        <>
            {/* //TODO: if customer has not set up stripe account, this page will show */}
        </>
    )
}
export default LoadingPage;
