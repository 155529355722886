import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Select, MenuItem, InputLabel, NativeSelect } from '@mui/material'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import {timeZoneSelection} from "../InviteClient/TimeZone";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputMask from "react-input-mask";
import Box from '@mui/material/Box';


/* 
Toivo's notes, 10/11/2022
There's no password rules on here, 
it also saves the hashed password to the database 
*/


function EditClient() {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams()

    useEffect(() => {
        dispatch({
            type: 'FETCH_ACTIVE_CLIENT', 
            payload: params.id
        })
    }, [params.id])


const handleChange = (evt, property) => {

    if(property === 'allow_sms' || property === 'require_inquiry'){
        const checkVal = eval(property)
        dispatch({
            type: 'EDIT_CLIENT_ONCHANGE',
            payload: { property: property, value: !checkVal }
        })
    }
    else {
      // If phone number, then remove all nondigit characters from the event target value
      // that was added by the mask.
      if(property === 'phone_number_1' || property === 'phone_number_2') {
        dispatch({
          type: 'EDIT_CLIENT_ONCHANGE',
          payload: { property: property, value: evt.target.value.replace(/\D/g, '') }
        })
      }
      else {
        dispatch({
          type: 'EDIT_CLIENT_ONCHANGE',
          payload: { property: property, value: evt.target.value }
        })
      }
    }
    
}

    const client = useSelector((store => store.activeClient))

    const user = useSelector(store => store.user)
    const username = client.username;
    const password = client.password;

    const firstName = client.first_name;
    const middleName = client.middle_name;
    const lastName = client.last_name
    const email = client.email
    const phoneNumber1 = client.phone_number_1;
    const phoneNumber2 = client.phone_number_2

    const companyName = client.company_name;
    const companyAddress1 = client.company_address_1
    const companyAddress2 = client.company_address_2
    const companyCity = client.company_city
    const companyState = client.company_state
    const companyZipCode = client.company_zipcode
    const companyWebsiteUrl = client.website_url
    const cancellationPolicy = client.cancellation_policy
    // const otherFee = client.other_fee;
    const bankName = client.bank_name
    const bankAddress1 = client.bank_address_1
    const bankAddress2 = client.bank_address_2
    const bankCity = client.bank_city
    const bankState = client.bank_state
    const bankZipCode = client.bank_zipcode;
    const bankRoutingNumber = client.bank_routing_number;
    const bankCheckingNumber = client.bank_checking_number;
    const timeZone = client.time_zone;
    const allow_sms = client.allow_sms;


    const [success, setSuccess] = useState(false);

    const editClient = (event) => {
        event.preventDefault();

        /* data being sent to inviteClient.saga.js */

            dispatch({
                type: 'UPDATE_CLIENT',
                payload: {
                    id: params.id,
                    username: username,
                    password,

                    firstName: firstName,
                    middleName: middleName,
                    lastName: lastName,
                    email: email,
                    phoneNumber1: phoneNumber1,
                    phoneNumber2: phoneNumber2,

                    companyName: companyName,
                    companyAddress1: companyAddress1,
                    companyAddress2: companyAddress2,
                    companyCity: companyCity,
                    companyState: companyState,
                    companyZipCode: companyZipCode,
                    companyWebsiteUrl: companyWebsiteUrl,


                    bankName: bankName,
                    bankAddress1: bankAddress1,
                    bankAddress2: bankAddress2,
                    bankCity: bankCity,
                    bankState: bankState,
                    bankZipCode: bankZipCode,
                    bankRoutingNumber: bankRoutingNumber,
                    bankCheckingNumber: bankCheckingNumber,
                    cancellationPolicy: cancellationPolicy,
                    timeZone,
                    allow_sms: allow_sms, 
                    require_inquiry: require_inquiry
                }

            });
        
       
        
        // todo: this has to be changed
        setSuccess(true)
    };

    
    const goBack = () => {
        history.push("/user");
    };

    const goDashboard = () => {
        history.push('/');
    };

    const require_inquiry = client.require_inquiry
    
    return (
        <>
            <div className="clientView">
                <form className="formPanel" onSubmit={editClient}>
                    {user.authLevel === 'admin'
                        ? <Typography component="h2" variant="h4" align="center" mt={2}>Edit Client
                        </Typography>

                        : <Box margin={3}><Typography component="h2" variant="h4" align="center" mt={2}>Profile
                        </Typography></Box>

                    }

                    <Grid container maxWidth="sm" mx="auto" direction="column" my={4}>
                            <FormControlLabel control={<Checkbox value={allow_sms} checked={allow_sms === true}  onChange={(e) => handleChange(e, 'allow_sms')} />} label="Allow Sms" />
                        <FormControlLabel control={<Checkbox checked={require_inquiry === true} onChange={(e) => handleChange(e, 'require_inquiry')} />} label="Require Inquiry Before Booking" />
                        

                        {user.authLevel === 'admin' && 
                        <>
                            <Typography component="h3" variant="h5" align="left" mt={2}>
                                Account Information
                            </Typography>
                            {/*  */}

                            {/* <FormControlLabel control={<Checkbox value={allow_sms}  checked={allow_sms === true} onChange={(e) => handleChange(e, 'allow_sms')} />} label="Allow Sms" /> */}
                            {/* <FormControlLabel control={<Checkbox onChange={() => setRequireInquiry(!requireInquiry)} />} label="Require Inquiry Before Booking" /> */}

                            <Grid item>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Username"
                                        type="text"
                                        name="username"
                                        value={username}
                                        required
                                        onChange={(event) => handleChange(event, 'username')}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Password"
                                        type="password"
                                        name="password"
                                        value={password}
                                        required
                                        onChange={(event) => handleChange(event, 'password')}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                            
                        }
                        

                        <Typography component="h3" variant="h5" align="left" mt={2}>
                            Personal Information
                        </Typography>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="First Name"
                                    type="text"
                                    name="firstName"
                                    value={firstName}
                                    required
                                    onChange={(event) => handleChange(event, 'first_name')}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Middle Name"
                                    type="text"
                                    name="middleName"
                                    value={middleName}
                                    onChange={(event) => handleChange(event, 'middle_name')}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Last Name"
                                    type="text"
                                    name="lastName"
                                    value={lastName}
                                    required
                                    onChange={(event) => handleChange(event, 'last_name')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Email"
                                    type="text"
                                    name="email"
                                    value={email}
                                    required
                                    onChange={(event) => handleChange(event, 'email')}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                              <InputMask
                                mask="(999) 999-9999"
                                value={phoneNumber1}
                                disabled={false}
                                maskChar=" "
                                onChange={(event) => handleChange(event, 'phone_number_1')}
                              >
                                {() => <TextField label="Phone 1" required type={'text'}/>}
                              </InputMask>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                              <InputMask
                                mask="(999) 999-9999"
                                value={phoneNumber2}
                                disabled={false}
                                maskChar=" "
                                onChange={(event) => handleChange(event, 'phone_number_2')}
                              >
                                {() => <TextField label="Phone 2" type={'text'}/>}
                              </InputMask> 
                            </FormControl>
                        </Grid>

                        <Typography component="h3" variant="h5" align="left" mt={2}>
                            Company Information
                        </Typography>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Company Name"
                                    type="text"
                                    name="companyName"
                                    value={companyName}
                                    required
                                    onChange={(event) => handleChange(event, 'company_name')}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    name="companyAddress1"
                                    // variant="standard"
                                    label="Address Line 1"
                                    value={companyAddress1}
                                    required
                                    onChange={(event) => handleChange(event, 'company_address_1')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    name="companyAddress2"
                                    label="Address Line 2"
                                    value={companyAddress2}
                                    onChange={(event) => handleChange(event, 'company_address_2')}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item container spacing={2}>
                            <Grid item sm={4}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField
                                        name="companyCity"
                                        label="City"
                                        value={companyCity}
                                        required
                                        onChange={(event) => handleChange(event, 'company_city')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={4}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField
                                        name="companyState"
                                        // variant="standard"
                                        label="State"
                                        value={companyState}
                                        required
                                        onChange={(event) => handleChange(event, 'company_state')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={4}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField
                                        name="companyZipCode"
                                        // variant="standard"
                                        label="Zip"
                                        value={companyZipCode}
                                        required
                                        onChange={(event) => handleChange(event, 'company_zipcode')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Website URL"
                                    type="text"
                                    name="companyWebsiteUrl"
                                    value={companyWebsiteUrl}
                                    onChange={(event) => handleChange(event, 'website_url')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {/* left this as a nativeSelect because value wouldn't display otherwise */}
                            <FormControl margin="normal" fullWidth>
                                <InputLabel >Cancellation Policy</InputLabel>
                                <NativeSelect
                                    value={cancellationPolicy}
                                    defaultValue={cancellationPolicy}
                                    onChange={(e) => handleChange(e, 'cancellation_policy')}
                                    required
                                    focused
                                >
                                    <option value={'strict'}>Strict</option>
                                    <option value={'flexible'}>Flexible</option>
                                    <option value={'moderate'}>Moderate</option>
                                </NativeSelect>
                            </FormControl>
                        </Grid>
                        {user.authLevel === 'admin' &&
                        <>
                        {/* not displaying value from redux for some reason. confusing. s*/}
                        <Grid item>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel >Time Zone</InputLabel>
                                <Select
                                    label="time zone"
                                    value={timeZone}
                                    displayEmpty={true}
                                    variant='outlined'
                                    focused
                                    onChange={(event) => handleChange(event, 'time_zone')}
                                    required
                                >
                                    {Object.entries(timeZoneSelection).map(([key, val]) =>
                                        (<MenuItem value={key}>{val}</MenuItem>)
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        </>
                        }
                        
                        {/* <Typography component="h3" variant="h5" align="left" mt={2}>
                            Bank Information
                        </Typography>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Bank Name"
                                    type="text"
                                    name="bankName"
                                    value={bankName}

                                    onChange={(event) => handleChange(event, 'bank_name')}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    name="bankAddress1"
                                    variant="standard"
                                    label="Address Line 1"
                                    value={bankAddress1}
                                    
                                    onChange={(event) => handleChange(event, 'bank_address_1')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    name="bankAddress2"
                                    variant="standard"
                                    label="Address Line 2"
                                    value={bankAddress2}
                                    onChange={(event) => handleChange(event, 'bank_address_2')}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item container spacing={2}>
                            <Grid item sm={4}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField
                                        name="bankCity"
                                        variant="standard"
                                        label="City"
                                        value={bankCity}

                                        onChange={(event) => handleChange(event, 'bank_city')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={4}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField
                                        name="bankState"
                                        variant="standard"
                                        label="State"
                                        value={bankState}
                                        onChange={(event) => handleChange(event, 'bank_state')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={4}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField
                                        name="bankZipCode"
                                        variant="standard"
                                        label="Zip"
                                        value={bankZipCode}

                                        onChange={(event) => handleChange(event, 'bank_zipcode')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Routing Number"
                                    type="text"
                                    name="bankRoutingNumber"
                                    value={bankRoutingNumber}

                                    onChange={(event) => handleChange(event, 'bank_routing_number')}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Checking Number"
                                    type="text"
                                    name="bankCheckingNumber"
                                    value={bankCheckingNumber}

                                    onChange={(event) => handleChange(event, 'bank_checking_number')}
                                />
                            </FormControl>
                        </Grid> */}



                        <Grid item style={{ textAlign: "center" }}>
                            <FormControl sx={{ width: 0.75 }} margin="normal">
                                <Button type="submit" variant="contained" size="large">
                                    Submit
                                </Button>
                            </FormControl>
                        </Grid>

                        <Grid item style={{ textAlign: "center" }}>
                            <FormControl sx={{ width: 0.25 }} margin="normal">
                                <Button variant="outlined" onClick={goBack} size="large">Back</Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>

                <Dialog open={success} onClose={goDashboard}>
                    {user.authLevel === 'admin'
                    ? <>
                            <DialogTitle>Invite Client</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Client Update successful!
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={goDashboard}>Dashboard</Button>
                            </DialogActions>
                    </>
                    : <>
                            <DialogTitle>Update Profile</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Profile Update Successful!
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={goDashboard}>Dashboard</Button>
                            </DialogActions>
                    </>
                    }
                    
                </Dialog>

            </div>
        </>
    )
}

export default EditClient;