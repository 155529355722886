import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import React from "react";
import {formatDate} from "../Utils/share-functions";
import Box from "@mui/material/Box";

const CustomPaymentInfo = ({duration, price, tnf, ef, total, comments, customStartTime, customDate}) => {
    const customDateStr = formatDate(customDate, false);
    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return (
        <Grid container spacing={2}>
            <Grid item xs={6} textAlign="left">
                <Typography>Price</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
                <Typography>{USDollar.format(price)}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="left">
                <Typography>Taxes & Fees</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
                <Typography>{USDollar.format(tnf + ef)}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="left">
                <Typography>Total</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
                <Typography>{USDollar.format(total)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{borderBottom: 1}}></Box>
            </Grid>
            <Grid item xs={6} textAlign="left">
                <Typography>Date</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
                <Typography>{customDateStr}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="left">
                <Typography>Start Time</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
                <Typography>{customStartTime}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="left">
                <Typography>Duration</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
                <Typography>{duration} hours</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{borderBottom: 1}}></Box>
            </Grid>
            <Grid item xs={6} textAlign="left">
                <Typography>Comments</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
                <Typography>{comments || ''}</Typography>
            </Grid>
        </Grid>
    )
}
export default CustomPaymentInfo;
