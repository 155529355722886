import React from "react";
import Carousel from 'react-material-ui-carousel';
export const CarouselDisplay = ({ pics }) => {
  if (!pics) return null;
  // console.log('###carouselDisplay pics', pics);
  return (
    <Carousel navButtonsAlwaysVisible={true}
      fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
      >
      {pics.map(p => (
        <div align={'center'} >
          <img src={`/categoryList/picKey/${p}`}
            alt={'img'}
            style={{ height: 380, width: 570, maxWidth: "100%", objectFit: "contain" }}
          />
        </div>
      ))}
    </Carousel>
  )
};
