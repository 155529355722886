import React, {useState, useEffect} from 'react'
import { TableContainer, Button, Paper, Typography, Box} from '@mui/material'
import axios from 'axios'
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';


// needs request date and title 
//sorted by request date
export default function itemRequestTable() {


    function createData(date_created, title) {
        return { date_created, title };
    }

    const [rows, setRows] = useState([])


    const handleSubmit = () => {

        axios.put(`api/bookableItem/request`, {selectedRows})

        window.location.reload()
    }
    const columns = [
        { field: 'id', headerName: 'id', minWidth: 30, flex: 0.4 },
        { field: 'date_created', headerName: 'Request Date', minWidth: 90, flex: 0.5 },
        { field: 'title', headerName: 'Item Title', minWidth: 100, flex: 0.8 },
        { field: 'company_name', headerName: 'Company Name', minWidth: 120, flex: 0.8 },
    ]

    const history = useHistory()

    const limit = (str = '', limit = 0) => {
        if (str === null || str === undefined) {
            return
        } else {
            return str.substring(0, limit)
        }
    };
    
    const [selectedRows, setSelectedRows] = useState();

    console.log('selectedRows', selectedRows);

    const formattedRows = rows.map((row, index) => {
        return (
            {
                id: row.id, 
                date_created: limit(row.date_created, 10), 
                title: row.title, 
                company_name: row.company_name,
                index: index, 
                completed: row.completed
            }
        )
    })
    useEffect(() => {
        const init = async () => {
            const answer = await axios.get('/api/bookableItem/request')
            console.log('answer ', answer);
            setRows(answer.data)
        }
        init()
    }, [])



    return(
        <>
            <Box sx={{ mt: 2 }}>
                <Typography variant="h4" align='center' gutterBottom='true'>
                    Bookable Item Requests
                </Typography>
                <Typography variant="h6" align='center' gutterBottom='true'>
                    Double Click to View Details 
                </Typography>

            </Box>
            <Box width='100%' display='flex' justifyContent='center'>
                <Button onClick={handleSubmit} variant="contained">Mark Selected as Complete</Button>
            </Box>

            {/* Have it set to only display incomplete requests. */}

            {/* easy fix if you still want them to display*/}

            {/* just uncomment line 105 and remove the where clause in the post request */}
            <TableContainer component={Paper}  >
                <div style={{ height: 600, width: '100%', alignContent: 'center' }}>
                    <DataGrid
                        sx={{ m: 2, boxShadow: 2 }}
                        aria-label="a dense table"
                        rows={formattedRows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[8]}
                        onRowDoubleClick={(e) => {history.push(`/admin/itemRequestTable/detail/${e.id}`)}}
                        checkboxSelection={true}
                        onSelectionModelChange={id => setSelectedRows(id)}
                        // isRowSelectable={(params) => params.row.completed === false}
                    />
                </div>
            </TableContainer>

        </>
    )
};