import React from 'react'

const RenterFooter = () => {
  return (
    <footer>
      Powered by <img src="./assets/images/eazie-book-logo-horizontal.png" id="logo" />
    </footer>
  )
}

export default RenterFooter