import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Input,
  FormControl,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography
} from '@mui/material'
import Box from '@mui/material/Box';
import ReactQuill from 'react-quill';
import AddIcon from '@mui/icons-material/Add'
import EditBookableItemDates from "./EditBookableItemDates";
import BuildTreeView from "../ManageCategory/BuildTreeView";
import UploadPhotos from "../UploadPhotos/UploadPhotos";
import BookableItemSingleDayHours from "../AddBookableItem/BookableItemSingleDayHours";
import AddonInputRow from "../AddBookableItem/InputRows";
import SelectTax from "../ManageTax/SelectTax";
import Dialog from '@mui/material/Dialog';
import BlockCalendar from "../BlockCalendar/BlockCalendar";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SeasonalPricing from "../SeasonalPricing/SeasonalPricing";
import { Paper } from '@material-ui/core';
import axios from 'axios'
import DiscountRow from '../AddBookableItem/DiscountRow';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function EditBookableItemForm() {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [booking, setBooking] = useState([]);
  const modules = {
    toolbar: [
      [{ 'font': [] }],
      [{ 'size': ['small', false, 'large', 'huge'] }],  // Custom dropdown
      ['bold', 'italic', 'underline', 'strike'],        // Toggled with buttons
      [{ 'color': [] }, { 'background': [] }],          // Dropdown with defaults
      [{ 'script': 'sub'}, { 'script': 'super' }],      // Subscript/superscript
      [{ 'header': 1 }, { 'header': 2 }, 'blockquote', 'code-block'],
      [{ 'list': 'ordered'}, { 'list': 'bullet'}, { 'indent': '-1'}, { 'indent': '+1' }],
      ['direction', { 'align': [] }],
      ['link', 'image', 'video', 'formula'],            // Add link, image, video, and formula
      ['clean']                                         // Remove formatting button
    ]
  };
  // set data
  const { user: { authLevel }, selectedBookableItem, activeClient, photoReducer: { prevPhotos }, categoryList: list }
    = useSelector(store => store);
  // // Grab the "selected bookableItem" from the redux store
  const clientId = query.get('clientId');
  const {
    duration_type, availability = [], availableHours = [], min_lead_time,
    prep_time, min_duration, client_fee_percent, eaziebook_fee_percent, hourly_increment, add_ons,
    onSubmitEdit, discounts: prevDiscounts
  } = selectedBookableItem;
  const { data: categoryList } = list;
  const [discounts, setDiscounts] = useState([]);
  const { email: clientEmail, first_name: clientFirstName } = activeClient;
  // handle date functions
  const checkDateFormat = arr => !!arr[0].date_available;
  const convertDateFormat = date => new Date(date.slice(6, 10), date.slice(0, 2) - 1, date.slice(3, 5)).toISOString();
  const getSingleAvail = startFlag => {
    if (!availability || !availability.length) return null;
    if (startFlag) {
      return checkDateFormat(availability) ? availability[0]?.date_available : convertDateFormat(availability[0]);
    } else {
      return checkDateFormat(availability) ? availability[availability.length - 1]?.date_available
        : convertDateFormat(availability[availability.length - 1]);
    }
  }
  const [addons, setAddons] = useState(add_ons || []);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [seasonalPricingOpened, setSeasonalPricingOpened] = useState(false);
  const getMultiAvail = () => {
    if (!availability || !availability.length) return [];
    return checkDateFormat(availability) ? availability.map(a => a.date_available) : availability;
  }

  const handleAddAddOns = () => {
    setAddons(currentState => {
      const newItem = { description: '', price: '', available: true, title: '' };
      const next = currentState.concat(newItem);
      handleChange(next, 'addOns');
      return next;
    });
  }


  const handleDeleteDiscount = (_, index) => {
    setDiscounts(prev => prev.filter((_, i) => i !== index));
  };
  
  // fetches booking if applicable
  useEffect(() => {
    const init = async () => {
      const answer = await axios.get(`/api/renter/confirm/${params.id}`);
      setBooking(answer.data);
    }
    init()
  }, []);

  useEffect(() => {
    if(prevDiscounts) {
      setDiscounts(prevDiscounts.map(p => ({
        ...p,
        minUnits: p.min_units,
        discountRate: p.rate
      })));
    }
  }, [prevDiscounts]);

  // user logic: if no repeating, the select all from start to last
  useEffect(() => {
    dispatch({
      type: 'FETCH_SELECTED_BOOKABLE_ITEM',
      payload: params.id
    });
    dispatch({
      type: 'FETCH_ACTIVE_CLIENT',
      payload: clientId
    })
    dispatch({
      type: "FETCH_ITEM_PREV_PHOTOS",
      payload: params.id
    })
  }, [params.id]); // 👈 fetch the member again, if the url changes

  useEffect(() => {
    dispatch({
      type: 'FETCH_BOOKABLEITEM_AVAILABILITY',
      payload: { itemId: params.id, isEdit: true, adminFlag: authLevel === 'admin' }
    });
  }, [selectedBookableItem.id]);

  useEffect(() => {
    const init = async () => {
      const answer = await axios.get(`/api/tax/${clientId}`)
      dispatch({
        type: 'SET_SELECTED_TAX', 
        payload: answer.data
      })
    }
    init()
  }, []);

  useEffect(() => {
    if (duration_type === 'singleDay' && availability?.length) {
      dispatch({
        type: 'FETCH_AVAILABLE_HOURS',
        payload: {
          itemId: params.id,
          date: checkDateFormat(availability) ? availability[0].date_available : availability[0]
        }
      })
    }
  }, [duration_type, availability?.length]);

  useEffect(() => {
    if (add_ons) {
      setAddons(add_ons);
      handleChange(add_ons, 'addOns');
    }
  }, [add_ons]);

  const instanceMaker = (x) => {
    let instance = []

    for (let i = 0; i < x; i++) {
      instance.push('')
    }

    dispatch({
      type: 'EDIT_FORM_ONCHANGE',
      payload: { property: 'instances', value: instance }
    });
  }

  const blockDateDialog = () => (
    <Box display='flex' justifyContent='center' margin='normal'>
      <Button
        variant="contained"
        onClick={() => setDialogOpened(true)}>
        Block Dates
      </Button>
      <Dialog
        fullScreen
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setDialogOpened(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Please Choose Dates to Block
            </Typography>
          </Toolbar>
        </AppBar>
        <BlockCalendar />
      </Dialog>
    </Box>
  )

  const seasonalPricingDialog = () => (
    <Box display='flex' justifyContent='center' margin='normal' marginTop={'10px'}>
      <Button
        variant="contained"
        onClick={() => setSeasonalPricingOpened(true)}>
        Override Price
      </Button>
      <Dialog
        fullScreen
        open={seasonalPricingOpened}
        onClose={() => setSeasonalPricingOpened(false)}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setSeasonalPricingOpened(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Override Price
            </Typography>
          </Toolbar>
        </AppBar>
        <SeasonalPricing />
      </Dialog>
    </Box>
  )

  // Called when the submitted button is pressed
  const handleSubmit = event => {
    event.preventDefault();
    let alertMsg = '';
    const validate = () => {
      if (selectedBookableItem.duration_type === 'singleDay'
        && (!selectedBookableItem.startTime || !selectedBookableItem.endTime) && !availableHours) {
        alertMsg = 'please select a correct start time / end time';
        return false;
      }
      if (selectedBookableItem.duration_type === 'singleDay'
        && (!selectedBookableItem.startDate || !selectedBookableItem.endDate) && !availability) {
        alertMsg = 'please select a correct start date / end date';
        return false;
      }
      return true;
    }
    if (!validate()) {
      alert(alertMsg);
      return;
    }
    dispatch({
      type: 'SAVE_BOOKABLE_ITEM',
      payload: {
        selectedBookableItem: selectedBookableItem,
        clientEmail: clientEmail,
        clientFirstName: clientFirstName,
        discounts: discounts
      }
    });
    if (duration_type !== 'singleDay' ) {
      history.push(`/user`);
    }
  };

  const deleteItem = async () => {
    // TODO: add input validation for booked single day items
    if (!booking) {
      await axios.delete(`/api/bookableItem/${params.id}`)
      alert('delete successful')
      history.push(`/user`);
    } else {
      console.error('delete failed');
      alert('this item has reservations and cannot be deleted')
    }
  }

  const handleChange = (evt, property) => {
    const directChangeableList = ['cover', 'summary', 'detail', 'categoryId', 'availability', 'addOns', 'itemTax', 'addOnTax'];
    if (directChangeableList.includes(property)) {
      // setNewBookableItem({ ...newBookableItem, [property]: evt })
      dispatch({
        type: 'EDIT_FORM_ONCHANGE',
        payload: { property: property, value: evt }
      });
    } else if (property === 'deposit' || property === 'damage_deposit') {
      const checkVal = eval(evt.target.value)
      dispatch({
        type: 'EDIT_FORM_ONCHANGE',
        payload: { property: property, value: !checkVal }
      });
    } else {
      dispatch({
        type: 'EDIT_FORM_ONCHANGE',
        payload: { property: property, value: evt.target.value }
      });
    }
  }

  const handleDeleteAddOn = indexToDelete => {
    setAddons(currentState => {
      const next = currentState.filter((a, index) => index !== indexToDelete);
      handleChange(next, 'addOns');
      return next;
    });
  }

  const handleDropFiles = files => {
    dispatch({
      type: 'EDIT_FORM_ONCHANGE',
      payload: { property: 'photos', value: files }
    });
  }

  const handleDeletePhotos = photo => {
    if(!photo) return;
    const { id } = photo;
    dispatch({
      type: 'EDIT_FORM_ONCHANGE',
      payload: { property: 'photoToDelete', value: [...(selectedBookableItem?.photoToDelete ?? []), id] }
    })
  }

  const { instances } = selectedBookableItem

  const setInstanceField = (e, index) => {
    let newInstances = [...instances]
    newInstances[index] = e.target.value;
    dispatch({
      type: 'EDIT_FORM_ONCHANGE',
      payload: { property: 'instances', value: newInstances }
    });
  }

  return (
    <>
      <Container component='main'>
        {authLevel === 'client' &&
          <Box
            onSubmit={handleSubmit}
            component="form"
          >
            <Box margin={3}>
              <Typography
                sx={{ m: 2 }}
                variant="h4"
                align="center">
                Edit Bookable Item
              </Typography>
            </Box>
            {/* edit form inputs */}
            <Paper elevation={8}>
              <Grid container maxWidth="md" mx="auto" direction="column" my={4}>



                <Grid item>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="title"
                      variant="standard"
                      label="Title"
                      required

                      onChange={(evt) => handleChange(evt, "title")}
                      value={selectedBookableItem.title ?? ''}
                    />
                  </FormControl>
                </Grid>
                <Grid item sx={{ display: 'none' }}>
                  <Typography padding='8px' align='center' variant='h5'>Summary</Typography>
                </Grid>
                <Grid container item justifyContent="center" sx={{ display: 'none' }}>
                  <Grid item sm={10}>
                    <FormControl margin="normal" fullWidth>
                      <ReactQuill
                        onChange={(evt) => handleChange(evt, "summary")}
                        value={selectedBookableItem.summary}
                        placeholder='Item Summary'
                        theme='snow'
                        modules={modules}
                      />

                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography padding='8px' align='center' variant='h5'>Description</Typography>
                </Grid>
                <Grid container item justifyContent="center">
                  <Grid item sm={10}>
                    <FormControl margin="normal" fullWidth>
                      <ReactQuill
                        onChange={(evt) => handleChange(evt, "detail")}
                        value={selectedBookableItem.detail}
                        placeholder='Detailed Description'
                        theme='snow'
                        modules={modules}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            {/* photo upload */}
            <Paper elevation={8}>
              <Typography
                component="h2"
                variant="h5"
                align="center">
                Photos
              </Typography>
              <Grid container maxWidth='md' mx='auto' direction='column' alignContent='center' my={4}>
                <Grid item>
                  <UploadPhotos
                    handleDropFiles={handleDropFiles}
                    prevPhoto={prevPhotos}
                    handleDeletePrev={handleDeletePhotos}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={8}>
              <Typography
                component="h2"
                padding='8px'
                variant="h5"
                align="center">
                Availability
              </Typography>
              <Grid container maxWidth="md" alignContent='center' mx="auto" direction="column" mb={4}>
                {duration_type === 'singleDay' ?
                  <BookableItemSingleDayHours
                    itemId={params.id}
                    increment={hourly_increment}
                    adminFlag={authLevel === 'admin'}
                    onSubmitEdit={onSubmitEdit}
                  />
                  : <EditBookableItemDates multiDayAvailability={() => getMultiAvail()} />}
              </Grid>
            </Paper>
            {/* Add-Ons */}
            <Paper elevation={8}>
              <Box display='flex' justifyContent='center' margin='normal' padding={3}>
                <Button
                  variant="contained"
                  onClick={handleAddAddOns}>
                  Create an Add-On
                </Button>
              </Box>
              {/* <Grid container maxWidth="md" mx="auto" direction="column" padding={4}> */}
              <Grid container mx="auto" direction="column" padding={4}>
                {addons?.map((addon, index) =>
                  <AddonInputRow
                    key={index}
                    setAddons={setAddons}
                    handleDelete={handleDeleteAddOn}
                    updateAddOn={handleChange} addon={addon} index={index} isEdit={true} />)}
              </Grid>
            </Paper>
            <Paper elevation={8}>
              <Box margin={3} padding={3}>
                {blockDateDialog()}
              </Box>
            </Paper>
            <Paper elevation={8}>
              <Box margin={3} padding={3}>
                {seasonalPricingDialog()}
              </Box>
            </Paper>
            {/* <ClientBlocksOffDates bookableId={params.id} /> */}
            {/* end edit form inputs */}

            <Box display="flex" justifyContent="right" margin="normal">
              <Button
                type="submit"
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
              >
                Confirm Edit
              </Button>
            </Box>
          </Box>
        }
        {authLevel === 'admin' &&
          <Box
            onSubmit={handleSubmit}
            component="form"
          >
            {/* edit form inputs */}
            <Grid container maxWidth="md" mx="auto" direction="column" my={4}>

              <Grid item>
                <Typography
                  sx={{ m: 2 }}
                  variant="h4"
                  align="center">
                  Edit Bookable Item
                </Typography>
              </Grid>


              <Grid item>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    name="tileTitle"
                    // variant="standard"
                    label="Tile Title"
                    required
                    onChange={(evt) => handleChange(evt, "tileTitle")}
                    value={selectedBookableItem.tileTitle ?? ''}

                  />
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl margin="normal" fullWidth>
                  {/* <ReactQuill
                                    theme='snow'
                                    onChange={(evt) => handleChange(evt, 'title')}
                                    value={selectedBookableItem.title}
                                    placeholder='title'
                                    toolbar={toolbar}
                                /> */}
                  <TextField
                    name="title"
                    // variant="standard"
                    label="Title"
                    required

                    onChange={(evt) => handleChange(evt, "title")}
                    value={selectedBookableItem.title ?? ''}

                  />
                </FormControl>
              </Grid>
              <Grid container item justifyContent="center">
                <Grid item sm={10}>
                  <FormControl margin="normal" fullWidth>
                    <ReactQuill
                      onChange={(evt) => handleChange(evt, "summary")}
                      value={selectedBookableItem.summary}
                      placeholder='Item Summary'
                      theme='snow'
                      modules={modules}
                    />

                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container spacing={6}>
                <Grid item sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="leadTime"
                      // variant="standard"
                      label="Lead Time"
                      type='number'

                      onChange={(evt) => handleChange(evt, "min_lead_time")}
                      value={min_lead_time ?? ''}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="Rate"
                      // variant="standard"
                      label="Rate"
                      required

                      onChange={(evt) => handleChange(evt, "rate")}
                      value={selectedBookableItem.rate ?? ''}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container spacing={6}>
                <Grid item sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="prepTime"
                      // variant="standard"
                      label="Prep Time"
                      type='number'

                      onChange={(evt) => handleChange(evt, "prep_time")}
                      value={prep_time ?? ''}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="min duration"
                      // variant="standard"
                      label="Min Duration"
                      type='number'
                      required

                      onChange={(evt) => handleChange(evt, "min_duration")}
                      value={min_duration ?? ''}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="maxDuration"
                      // variant="standard"
                      label="Max Duration"
                      type='number'

                      required
                      onChange={(evt) => handleChange(evt, "max_duration")}
                      value={selectedBookableItem.max_duration ?? ''}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container spacing={6}>
                <Grid item sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="clientFeePercent"
                      // variant="standard"
                      label="Client Fee %"

                      required
                      type='float'
                      onChange={(evt) => handleChange(evt, "client_fee_percent")}
                      value={client_fee_percent ?? ''}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="eazieBookFeePercent"
                      // variant="standard"

                      label="Eaziebook Fee %"
                      type='float'
                      required
                      onChange={(evt) => handleChange(evt, "eaziebook_fee_percent")}
                      value={eaziebook_fee_percent ?? ''}
                    />
                  </FormControl>
                </Grid>
                {selectedBookableItem.duration === "singleDay" && (
                  <Grid item sm={4}>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        inputProps={{
                          step: 0.5,
                          min: 0,
                          max: 24,
                          type: 'number',
                        }}
                        name="Rent Hour Increment"
                        // variant="standard"
                        label="Rent Hour Increment"
                        onChange={(evt) => handleChange(evt, "hourly_increment")}
                        value={hourly_increment ?? ''}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>

              <Grid item container spacing={6}>
                <Grid item sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <FormControlLabel control={<Checkbox value={selectedBookableItem.deposit}
                      checked={selectedBookableItem.deposit === true}
                      onChange={(e) => handleChange(e, 'deposit')} />}
                      label="Deposit" />
                  </FormControl>
                </Grid>
                {selectedBookableItem.deposit === true && (
                  <Grid item sm={4}>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        type='float'
                        name="deposit_amt"
                        label="Deposit Percent (%)"
                        required
                        onChange={(evt) => handleChange(evt, "deposit_amt")}
                        value={selectedBookableItem.deposit_amt ?? ''}
                      />
                    </FormControl>
                  </Grid>
                )}
                {selectedBookableItem.deposit === true && (
                  <Grid item sm={4}>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        type='float'
                        name="max_deposit_amt"
                        label="Maximum Deposit Amount"
                        required
                        onChange={(evt) => handleChange(evt, "max_deposit_amt")}
                        value={selectedBookableItem.max_deposit_amt ?? ''}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <FormControlLabel control={<Checkbox value={selectedBookableItem.damage_deposit}
                      checked={selectedBookableItem.damage_deposit === true}
                      onChange={(e) => handleChange(e, 'damage_deposit')} />}
                      label="Damage Deposit" />

                  </FormControl>
                </Grid>
                {selectedBookableItem.damage_deposit === true && (
                  <Grid item sm={4}>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        type='number'
                        name="damage_deposit_amt"
                        // variant="standard"
                        label="Damage Deposit Amount ($)"
                        required
                        onChange={(evt) => handleChange(evt, "damage_deposit_amt")}
                        value={selectedBookableItem.damage_deposit_amt ?? ''}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>

              <Grid container item justifyContent="center">
                <Grid item sm={10}>
                  <FormControl margin="normal" fullWidth>
                    <ReactQuill
                      onChange={(evt) => handleChange(evt, "detail")}
                      value={selectedBookableItem?.detail}
                      placeholder='Detailed Description'
                      theme='snow'
                      modules={modules}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item>
                <FormControl fullWidth margin="normal">
                  <BuildTreeView
                    categoryList={categoryList}
                    callFrom={'bookableItem'}
                    handleSelect={(event, nodeId) => {
                      handleChange(nodeId, 'categoryId');
                    }}
                    selectId={selectedBookableItem.categoryId}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth margin="normal">

                  <SelectTax currentTaxSelection={selectedBookableItem.tax} onTaxChange={val => handleChange(val, 'itemTax')} editMode={true}/>
                              
                </FormControl>
              </Grid>

              <Grid container maxWidth='md' mx='auto' direction='column' alignContent='center' my={4}>
                <Grid item sm={2}>
                  <Typography padding='20px' variant='h4'>Select And Name Your instances</Typography>

                </Grid>

                <Grid item alignSelf='center' sm={2} padding='20px'>
                  <Input
                    type='number'
                    required
                    value={(instances?.length)}
                    placeholder='Instance #'
                    onChange={(e) => instanceMaker(e.target.value)}
                  />
                </Grid>


              </Grid>

              <Grid container alignContent='center' maxWidth="md" mx="auto" direction="column" my={4}
                padding={4}>

                {instances?.map((item, index) => (
                  <Grid item padding='12px' sm={10}>
                    <TextField
                      onChange={(e) => setInstanceField(e, index)}
                      key={index}
                      fullWidth

                      variant='standard'
                      placeholder={`instance ${index + 1}`}
                      required
                      value={instances[index].name}
                      size='medium'
                      sx={{ width: { sm: 100, md: 400 } }}
                    />
                  </Grid>
                ))}

              </Grid>

              <Typography padding='12px' align='center' variant='h4' >Add your discounts, if applicable</Typography>
                    <Box display='flex' padding='22px' justifyContent='center' margin='normal'>
                        <Button
                            variant="contained"

                            onClick={() => {
                                setDiscounts(currentState => {
                                    const newItem = { minUnits: '', discountRate : '' };
                                    return [...currentState, newItem]; 
                                });
                            }} 

                            padding='10px'
                            >
                            Add a discount
                        </Button>
                    </Box>

                    {/* TODO: make this work with discounts  */}

                    <Grid container maxWidth="md" mx="auto" direction="column" padding={4}>
                        {discounts.map((discount, index) =>
                         <DiscountRow  handleDeleteDiscount={(e,i) => handleDeleteDiscount(e,index)} 
                                      setDiscounts={setDiscounts} discount={discount} index={index} 
                        />)}
                    </Grid>

              {/* photo upload */}
              <Grid container maxWidth='md' mx='auto' direction='column' alignContent='center' my={4}>
                <Grid item>
                  <UploadPhotos
                    handleDropFiles={handleDropFiles}
                    prevPhoto={prevPhotos}
                    handleDeletePrev={handleDeletePhotos}
                  />
                </Grid>
              </Grid>
              {/* end photo upload */}
            </Grid>
            {/* end edit form inputs */}

            <Typography
              component="h2"
              padding='8px'
              variant="h5"
              align="center">
              Availability
            </Typography>
            <Grid container maxWidth="md" alignContent='center' mx="auto" direction="column" mb={4}>
              {duration_type === 'singleDay' ?
                <BookableItemSingleDayHours
                  increment={hourly_increment}
                  itemId={params.id}
                  adminFlag={authLevel === 'admin'}
                  onSubmitEdit={onSubmitEdit}
                />
                : <EditBookableItemDates multiDayAvailability={() => getMultiAvail()} />}
            </Grid>
            <Box display='flex' justifyContent='center' margin='normal'>
              <Button
                variant="contained"
                onClick={handleAddAddOns}>
                Create an Addon
              </Button>
            </Box>

            <Grid container maxWidth="md" mx="auto" direction="column" padding={4}>

              {addons?.map((addon, index) =>
                <AddonInputRow key={index} setAddons={setAddons} updateAddOn={handleChange}
                  addon={addon} index={index} handleDelete={handleDeleteAddOn}
                  isEdit={true}
                />)}
            </Grid>
            {blockDateDialog()}
            {seasonalPricingDialog()}

            <Box display="flex" justifyContent="right" margin="normal">
              <Button
                type="submit"
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
              >
                Confirm Edit
              </Button>
            </Box>
            <Box display="flex" justifyContent="right" margin="normal">
              <Button
                variant="contained"
                size="large"
                onClick={deleteItem}
              >
                delete item
              </Button>
            </Box>
          </Box>
        }
      </Container>

    </>
  );
}

export default EditBookableItemForm;
