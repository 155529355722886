import React from 'react'
import { useSelector } from 'react-redux';
import { Select, Grid, MenuItem, FormControl, FormControlLabel, Checkbox, Typography, InputLabel, TextField } from '@mui/material'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import BuildTreeView from "../ManageCategory/BuildTreeView";
import SelectTax from "../ManageTax/SelectTax";



export default function BookableItemInfo({ handleChange }) {
    const { addBookableItemInputs } = useSelector((store) => store.bookableItem);
    const {data: categoryList} = useSelector(({categoryList: list}) => list);
    //grab the list of item names from the reducer 
    const modules = {
        toolbar: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],  // Custom dropdown
          ['bold', 'italic', 'underline', 'strike'],        // Toggled with buttons
          [{ 'color': [] }, { 'background': [] }],          // Dropdown with defaults
          [{ 'script': 'sub'}, { 'script': 'super' }],      // Subscript/superscript
          [{ 'header': 1 }, { 'header': 2 }, 'blockquote', 'code-block'],
          [{ 'list': 'ordered'}, { 'list': 'bullet'}, { 'indent': '-1'}, { 'indent': '+1' }],
          ['direction', { 'align': [] }],
          ['link', 'image', 'video', 'formula'],            // Add link, image, video, and formula
          ['clean']                                         // Remove formatting button
        ]
      };
    const handleRentHour = evt => {
        if (!(evt?.target?.value && Number(evt.target.value) && !isNaN(Number(evt.target.value)))) return;
        const num = Number(evt.target.value);
        // edge case
        if (num > 0 && num < 0.5) {
            handleChange(0.5, "hourlyIncrement");
            return;
        }
        handleChange(Math.round(num * 2) / 2, "hourlyIncrement");
    }
    return(
        <>
        <Grid container maxWidth="md" mx="auto" direction="column" my={4}>
            <Grid item>
                <Typography sx={{ m: 2 }} component="h2" variant="h4" align='center'>
                    Enter Bookable item info
                </Typography>
            </Grid>
            
            <Grid item>
                <FormControl margin="normal" fullWidth>
                    <TextField
                        name="tileTitle"
                        // variant="standard"
                        label="Tile Title"
                        required
                        onChange={(evt) => handleChange(evt, "tileTitle")}
                        value={addBookableItemInputs.tileTitle}
                        
                    />
                </FormControl>
            </Grid>

            <Grid item>
                <FormControl margin="normal" fullWidth>
                    {/* <ReactQuill
                        theme='snow'
                        onChange={(evt) => handleChange(evt, 'title')}
                        value={addBookableItemInputs.title}
                        placeholder='title'
                        toolbar={toolbar}
                    /> */}
                    <TextField 
                        name='title'
                        // variant='standard'
                        label='Title'
                        required
                        value={addBookableItemInputs.title}
                        onChange={(evt) => handleChange(evt, 'title')}
                        placeholder='Title'

                    />
                </FormControl>
            </Grid>
            <Grid container item justifyContent="center">
                <Grid item sm={10}>
                    <FormControl margin="normal" fullWidth>
                        <ReactQuill
                            onChange={(evt) => handleChange(evt, "summary")}
                            value={addBookableItemInputs.summary}
                            placeholder='Item Summary'
                            theme='snow'
                            modules={modules}
                        />

                    </FormControl>
                </Grid>
            </Grid>

            <Grid item container spacing={6}>
                <Grid item sm={4}>
                    <FormControl margin="normal" fullWidth>
                            <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                            <Select
                                label="duration"
                                value={addBookableItemInputs.duration}
                                onChange={(e) => handleChange(e, 'duration')}
                                required
                            >
                                <MenuItem value={'singleDay'}>Single Day</MenuItem>
                                <MenuItem value={'multiDay'}>Multi Day</MenuItem>
                                <MenuItem value={'singleNight'}>Single Night</MenuItem>
                                <MenuItem value={'multiNight'}>Multi Night</MenuItem>
                            </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <FormControl margin="normal" fullWidth>
                        <TextField
                            name="rate"
                            // variant="standard"
                            label="Rate"
                            type='number'
                            required
                            onChange={(evt) => handleChange(evt, "rate")}
                            value={addBookableItemInputs.rate}
                        />
                    </FormControl>
                </Grid>
                    <Grid item sm={4}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                name="leadTime"
                                // variant="standard"
                                label="Min Lead Time"
                                type='number'
                                onChange={(evt) => handleChange(evt, "leadTime")}
                                value={addBookableItemInputs.leadTime}
                            />
                        </FormControl>
                    </Grid>
            </Grid>

                <Grid item container spacing={6}>
                    <Grid item sm={4}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                name="prepTime"
                                // variant="standard"
                                label="Prep Time"
                                type='number'
                                onChange={(evt) => handleChange(evt, "prepTime")}
                                value={addBookableItemInputs.prepTime}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                name="min duration"
                                // variant="standard"
                                label="Min Duration"
                                type='number'
                                required
                                onChange={(evt) => handleChange(evt, "minDuration")}
                                value={addBookableItemInputs.minDuration}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                name="maxDuration"
                                // variant="standard"
                                label="Max Duration"
                                type='number'
                                required
                                onChange={(evt) => handleChange(evt, "maxDuration")}
                                value={addBookableItemInputs.maxDuration}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container spacing={6}>
                    <Grid item sm={4}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                name="clientFeePercent"
                                // variant="standard"
                                label="Client Fee %"
                                required
                                type='float'
                                onChange={(evt) => handleChange(evt, "clientFeePercent")}
                                value={addBookableItemInputs.clientFeePercent}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                name="eazieBookFeePercent"
                                // variant="standard"
                                label="Eaziebook Fee %"
                                type='float'
                                required
                                onChange={(evt) => handleChange(evt, "eazieBookFeePercent")}
                                value={addBookableItemInputs.eazieBookFeePercent}
                            />
                        </FormControl>
                    </Grid>
                    {addBookableItemInputs.duration === "singleDay" && (
                        <Grid item sm={4}>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    inputProps={{
                                        step: 0.5,
                                        min: 0,
                                        max: 24,
                                        type: 'number',
                                    }}
                                    name="Rent Hour Increment"
                                    // variant="standard"
                                    label="Rent Hour Increment"
                                    onChange={handleRentHour}
                                    value={addBookableItemInputs.hourlyIncrement}
                                />
                            </FormControl>
                        </Grid>
                    )}

                </Grid>

                <Grid item container spacing={6}>
                    <Grid item sm={4}>
                        <FormControl margin="normal" fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">Deposit</InputLabel> */}
                            {/* <Select
                                label="Deposit"
                                value={addBookableItemInputs.deposit}
                                onChange={(e) => handleChange(e, 'deposit')}
                                required
                            >
                                <MenuItem value={true}>True</MenuItem>
                                <MenuItem value={false}>False</MenuItem>
                            </Select> */}
                            <FormControlLabel control={<Checkbox value={addBookableItemInputs.deposit} checked={addBookableItemInputs.deposit === true} onChange={(e) => handleChange(e, 'deposit')} />} label="Deposit" />

                        </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                        <FormControl margin="normal" fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">Damage Deposit</InputLabel>
                            <Select
                                label="Deposit"
                                value={addBookableItemInputs.damage_deposit}
                                onChange={(e) => handleChange(e, 'damage_deposit')}
                                required
                            >
                                <MenuItem value={true}>True</MenuItem>
                                <MenuItem value={false}>False</MenuItem>
                            </Select> */}
                            <FormControlLabel control={<Checkbox value={addBookableItemInputs.damage_deposit} checked={addBookableItemInputs.damage_deposit === true} onChange={(e) => handleChange(e, 'damage_deposit')} />} label="Damage Deposit" />

                        </FormControl>
                    </Grid>
                    {addBookableItemInputs.deposit === true && (
                        <Grid item sm={4}>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    type='float'
                                    name="deposit_amt"
                                    // variant="standard"
                                    label="Deposit Percent (%)"
                                    required
                                    onChange={(evt) => handleChange(evt, "deposit_amt")}
                                    value={addBookableItemInputs.deposit_amt}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    {addBookableItemInputs.deposit === true && (
                        <Grid item sm={4}>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    type='float'
                                    name="max_deposit_amt"
                                    label="Maximum Deposit Amount"
                                    required
                                    onChange={(evt) => handleChange(evt, "max_deposit_amt")}
                                    value={addBookableItemInputs.max_deposit_amt}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    {addBookableItemInputs.damage_deposit === true && (
                        <Grid item sm={4}>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    type='number'
                                    name="damage_deposit_amt"
                                    // variant="standard"
                                    label="Damage Deposit Amount ($)"
                                    required
                                    onChange={(evt) => handleChange(evt, "damage_deposit_amt")}
                                    value={addBookableItemInputs.damage_deposit_amt}
                                />
                            </FormControl>
                        </Grid>
                    )}
                </Grid>

                

            <Grid container item justifyContent="center">
                <Grid item sm={10}>
                    <FormControl margin="normal" fullWidth>
                        <ReactQuill
                            onChange={(evt) => handleChange(evt, "details")}
                            value={addBookableItemInputs.details}
                            placeholder='Detailed Description'
                            theme='snow'
                            modules={modules}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item>
                <FormControl fullWidth margin="normal">
                    <BuildTreeView
                        categoryList={categoryList}
                        callFrom={'bookableItem'}
                        handleSelect={(event, nodeId) => {
                            handleChange(nodeId, 'categoryId');
                        }}
                        selectId={Number(addBookableItemInputs.categoryId)}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth margin="normal">
                    <SelectTax onTaxChange={val => handleChange(val, 'itemTax')} />
                </FormControl>
            </Grid>
        </Grid>
        </>
    )
};