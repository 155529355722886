import axios from 'axios';
import { TextField, Button } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import {useSelector} from 'react-redux';

import moneyFormatter from '../../formatters/money.format';
import costCalculator from '../../helpers/costCalculator.helper';

export default function AddCharge ({handleClose}){
    const selectedBooking = useSelector(store => store.selectedBooking);
    const [inputIndex, setInputIndex] = useState();
    const [loading, setLoading] = useState(false);
    const [totals, setTotals] = useState({eazieFee: 0, clientFee: 0, taxes: 0, total: 0});
    const [form, setForm] = useState({
        amount: '0',
        description: '',
        tax_percentage: '0',
    });
    const inputEl = useRef(null);
    const initAmount = () => {
        const index = inputEl.current.value.length;
        inputEl.current.focus();
        inputEl.current.setSelectionRange(index, index);
    }

    useEffect(() => {
        if(!inputIndex) return
        initAmount();
    },[inputIndex]);
    
    useEffect(() => {
        setTotals(costCalculator({ ...selectedBooking, charge: form.amount, tax_percentage: form.tax_percentage, description: form.description }));
    },[form]);

    const handleForm = (e) => {
        if(e.target.name === 'description'){
            setForm({...form, [e.target.name]: e.target.value});
        } if(e.target.name === 'amount'){
            const deFormat = e.target.value.replace(/[$.,]/g, '').replace(/0*(.*)/, '$1');
            if(!/^(\d*)$/.test(deFormat)){
                return
            }
            setForm({...form, [e.target.name]: deFormat});
        } else {
            //TODO create percentage mask
            setForm({...form, [e.target.name]: e.target.value});
        }
    }
    const submitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        const test = await axios.post('/api/payment/create-charge', { selectedBooking, amount: form.amount, tax_percentage: form.tax_percentage, description: form.description });
        if(test.status === 201){
            console.log('closing');
            handleClose();
        }
        setLoading(false);
    }
    // console.log(costCalculator({ selectedBooking, amount: form.amount, tax_percentage: form.tax_percentage, description: form.tax_percentage }));
    return <div onClick={handleClose} className='booking-detail-modal-background'>
        <div className='booking-detail-modal'>
            <div className='modal-content' id='add-charge-modal'>
                <form id='new-charge-form' onSubmit={e => submitForm(e)}>
                    <TextField inputRef={inputEl} id='add-charge-form-amount' value={moneyFormatter(form.amount)}  onChange={handleForm} onClick={initAmount} name='amount' label="Amount" variant="outlined" />
                    <TextField onChange={handleForm} name='description' label="Description" variant="outlined" />
                    <TextField onChange={handleForm} name='tax_percentage' label="Tax Percentage" variant="outlined" />
                    <p>EazieBook Fee: {moneyFormatter(totals.eazieFee)}</p>
                    <p>Client Fee: {moneyFormatter(totals.clientFee)}</p>
                    <p>Taxes: {moneyFormatter(totals.taxes)}</p>
                    <p>Total: {moneyFormatter(totals.total)}</p>
                    <Button disabled={loading} variant='contained' type='submit'>Charge Now</Button>
                </form>
            </div>
        </div>
    </div>
}