import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import * as React from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';

export default function CancelBooking ({ open, handleClose }){
    const limit = (str = '', limit = 0) => {
        if (str === null || str === undefined) {
            return
        } else {
            return str.substring(0, limit)
        }
    };

    const selectedBooking = useSelector(store => store.selectedBooking);
    const { renter_booking_id } = selectedBooking;
    const user = useSelector(store => store.user);
    const { phone_number_1 } = user;
    const [form, setForm] = useState({
        amount: null,
        reason: null,
        date: null,
        id: renter_booking_id,
        renterFirstName: selectedBooking.first_name,
        renterEmail: selectedBooking.email,
        companyName: user.company_name,
        clientEmail: user.email,
        clientPhone: phone_number_1,
        startDate : limit(selectedBooking.start_date, 10),
        endDate: limit(selectedBooking.end_date, 10)
    });
    const [loading, setLoading] = useState(false);

    const handleFormChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`/api/payment/cancel-booking/`, form);
            if(response.status === 201){
                handleClose();
            }
        } finally {
            setLoading(false); // Stop loading regardless of the response
        }
    }
    return (
        <div>
            <Dialog open={open} onClose={() => { setLoading(false); handleClose(); }}>
            <DialogTitle>Cancel Booking</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                    Please be sure to use the correct date to ensure the correct date as well as a refund amount as per your cancellation policy. 
                </DialogContentText> */}
                {/* <TextField
                    required
                    autoFocus
                    margin="dense"
                    id="date"
                    name="date"
                    label="Date"
                    type="date"
                    fullWidth
                    variant="standard"
                    value={form.date}
                    onChange={handleFormChange}
                />
                <TextField
                    required
                    autoFocus
                    margin="dense"
                    id="amount"
                    name="amount"
                    label="Amount"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={form.amount}
                    onChange={handleFormChange}
                /> */}
                <TextField
                    required
                    autoFocus
                    margin="dense"
                    id="reason"
                    name="reason"
                    label="Reason"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={form.reason}
                    onChange={handleFormChange}
                />
                {loading && <CircularProgress size={24} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setLoading(false); handleClose(); }}>GO BACK</Button>
                <Button onClick={handleSubmit} disabled={loading}>CANCEL BOOKING</Button>
            </DialogActions>
            </Dialog>
        </div>
    )
}
