import axios from 'axios';
import Button from '@mui/material/Button';
import {useEffect, useState} from "react";
export default function DeclineButton ({renterBookingID, status, setStatus}){
    const [disable, setDisable] = useState(status === 'refunded' || status === 'declined' || status === 'booked' ? true : false);
    useEffect(() => {
        setDisable(status === 'refunded' || status === 'declined' || status === 'booked' ? true : false);
    }, [status])
    const handleDecline = () => {
        setStatus('declined');
        axios.post('/payment/decline', {renterBookingID});
    }
    return <>
        <Button variant="contained" disabled={disable} onClick={handleDecline} color="error">
            DECLINE
        </Button>
    </>
}