import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import {useState} from 'react';
import axios from 'axios';
import './StripeOnboard.css'
const StripeOnBoardButton = () => {
    const [loading, setLoading] = useState(false)
    const onboard = async () => {
        setLoading(true)
        const stripeOnBoardingUrl = await axios.get('/stripe/onboard');
        console.log(stripeOnBoardingUrl);
        location.href = stripeOnBoardingUrl.data.url;
    }
    return (<>
    <Alert id="stripe-onboard-alert" onClick={() => onboard()} severity="error">
        You have not completed the Stripe onboarding! Click here to finish. {loading && <CircularProgress size='0.875rem' thickness={8} />}
    </Alert>
    </>)
}

export default StripeOnBoardButton