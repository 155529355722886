import * as React from 'react';
import {getComparator, stableSort} from "./ClientBookedItemsConstant";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import {EnhancedTableToolbar} from "./EnhancedTableToolBar";
import {EnhancedTableHead} from "./EnhancedTableHead";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useHistory} from "react-router-dom";
import {mapDateToString} from "../Utils/share-functions";
import moment from "moment";

const ClientBookedItems = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { cancelButtonFlag, userId } = props;
    const {data: bookableItem} = useSelector(store => store.bookableItem.clientBookedItemReducer);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('dateBooked');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { id } = useParams();

    useEffect(() => {
        const clientId = id ? id : userId;
        dispatch({ type: 'GET_CLIENT_BOOKED_ITEM_REQUEST', clientId });
    }, [dispatch]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = bookableItem.map(bookedItem => bookedItem.orderId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelect = (e, item) => {
        dispatch({
            type: 'SET_SELECTED_BOOKABLE_ITEM',
            payload: item,
        })
        history.push(`/booked-item/detail/${item.id}`);
    }

    const isSelected = name => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bookableItem.length) : 0;

    const DisplayTableBody = () => (
        <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(bookableItem, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((bookedItem, index) => {
                    const isItemSelected = isSelected(bookedItem.orderId);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                        <TableRow
                            hover
                            className={`${bookedItem.status === 'pending' ? 'requires-action': null}`}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={bookedItem.orderId}
                            selected={isItemSelected}
                            onClick={(e) => handleSelect(e, bookedItem)}
                        >
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                            >
                                {mapDateToString(bookedItem.dateBooked || '')}
                            </TableCell>
                            <TableCell align="left">{mapDateToString(bookedItem.dateScheduled || '')}</TableCell>
                            <TableCell align="left">{mapDateToString(bookedItem.dateEnd || '')}</TableCell>
                            <TableCell align="left" padding="none">{bookedItem.singleDayNightFlag ? moment(bookedItem.startTime, ['hh:mm:ss']).format('hh:mm A') : null}</TableCell>
                            <TableCell align="left" padding="none">{bookedItem.singleDayNightFlag ? moment(bookedItem.endTime, ['hh:mm:ss']).format('hh:mm A') : null}</TableCell>
                            <TableCell align="left">{bookedItem.confirmationNum}</TableCell>
                            <TableCell align="left">{bookedItem.tileTitle}</TableCell>
                            <TableCell align="left">{bookedItem.firstName} {bookedItem.lastName}</TableCell>
                            <TableCell align="left">{bookedItem.email}</TableCell>
                            <TableCell align="left">{bookedItem.status}</TableCell>
                        </TableRow>
                    );
                })}
            {emptyRows > 0 && (
                <TableRow
                    style={{
                        height: (33) * emptyRows,
                    }}
                >
                    <TableCell colSpan={6}/>
                </TableRow>
            )}
        </TableBody>
    )



    return (
        <Box sx={{width: '90vw', margin: 'auto'}}>
            <Paper sx={{width: '100%', mb: 2}}>
                <EnhancedTableToolbar numSelected={selected.length}/>
                <TableContainer>
                    <Table
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={bookableItem.length}
                            cancelButtonFlag={cancelButtonFlag}
                        />
                        <DisplayTableBody />
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={bookableItem.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    )
}

export default ClientBookedItems;
