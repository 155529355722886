import axios from 'axios';
import { TextField, Button } from '@mui/material';
import { useState, useRef } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moneyFormatter from '../../../formatters/money.format';

export default function RefundModal ({payments, handleClose}){
    const positivePayments = payments?.filter(payment => payment.amount > 0) || [];
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [payment, setPayment] = useState(null);
    const [form, setForm] = useState({
        amount: '0',
        reason: ''
    });
    const inputEl = useRef(null);
    const handleForm = (e) => {
        if(e.target.name === 'description'){
            setForm({...form, [e.target.name]: e.target.value});
        } if(e.target.name === 'amount'){
            const deFormat = e.target.value.replace(/[$.,]/g, '').replace(/0*(.*)/, '$1');
            if(!/^(\d*)$/.test(deFormat)){
                return
            }
            setForm({...form, [e.target.name]: moneyFormatter(deFormat)});
        } else {
            //TODO create percentage mask
            setForm({...form, [e.target.name]: e.target.value});
        }
    }
    const submitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage(null);
        const refund = await axios.post('/api/payment/refund', { amount: form.amount, payment, reason: form.reason });
        if(refund.status === 201){
            handleClose();
        } else {
            setLoading(false);
            setMessage(refund.data); 
        }
    }
    const selectPayment = (e) => {
        setPayment(e.target.value)
    }

    return <div onClick={handleClose} className='booking-detail-modal-background'>
        <div className='booking-detail-modal'>
            <div className='modal-content'>
                <form id='new-charge-form' onSubmit={e => submitForm(e)}>
                    <Select onChange={selectPayment} >
                        {positivePayments.map(item => (
                            <MenuItem value={item.payment_intent}>{item.payment_intent}</MenuItem>
                        ))}
                    </Select>
                    <TextField inputRef={inputEl} id='add-charge-form-amount' value={form.amount}
                               onChange={handleForm} name='amount' label="Amount" variant="outlined" />
                    <TextField value={form.reason}  onChange={handleForm} name='reason' label="Reason" variant="outlined" />
                    <Button disabled={loading} type='submit' variant='contained'>Refund</Button>
                    {message && <p>{message}</p>}
                </form>
            </div>
        </div>
    </div>
}