const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

export const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const headerCells = [
    {
        id: 'dateBooked',
        numeric: false,
        disablePadding: true,
        label: 'Date Booked',
    },
    {
        id: 'dateScheduled',
        numeric: false,
        disablePadding: true,
        label: 'Start Date',
    },
    {
        id: 'dateEnd',
        numeric: false,
        disablePadding: true,
        label: 'End Date',
    },
    {
        id: 'startTime',
        numeric: false,
        disablePadding: true,
        label: 'Start Time',
    },
    {
        id: 'endTime',
        numeric: false,
        disablePadding: true,
        label: 'End Time',
    },
    {
        id: 'confirmationNum',
        numeric: false,
        disablePadding: true,
        label: 'Confirmation #',
    },
    {
        id: 'title',
        numeric: false,
        disablePadding: true,
        label: 'Bookable Item Title',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: 'Customer Email',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'Status',
    }
];
