import axios from 'axios';
import Button from '@mui/material/Button';
import {useEffect, useState} from "react";
export default function AcceptButton ({renterBookingID, status, setStatus}){
    const [disable, setDisable] = useState(status === 'refunded' || status ==='booked' || status === 'declined' ? true : false);
    useEffect(() => {
        setDisable(status === 'refunded' || status === 'declined' || status === 'booked' ? true : false);
    }, [status])
    const handleAccept = async () => {
        setStatus('booked');
        const accepted = await axios.post('/api/payment/accept', {renterBookingID});
        console.log(accepted)
    }
    return <>
        <Button variant="contained" disabled={disable} onClick={handleAccept} >
            ACCEPT
        </Button>
    </>
}