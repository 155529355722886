import React, {useEffect} from 'react'
import { Button } from "@material-ui/core";
import { Modal, Box, Checkbox, FormControlLabel, Grid, Typography, Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import axios from 'axios'


export default function CouponModal ({open, handleCloseModal}) {

    const dispatch = useDispatch()

    const params = useParams()
    

    const clientId = params.id

    const [couponCode, setCouponCode] = React.useState('')

    const [description, setDescription] = React.useState('')

    const [rate, setRate] = React.useState(0)

    const [effectiveDate, setEffectiveDate] = React.useState(null)

    const [expirationDate, setExpirationDate] = React.useState(null)

    const [active, setActive] = React.useState(false)

    

    // console.log('active is', active);

    const handleSubmit = async (e) => {
        e.preventDefault()
        // TODO: add clientId 
        axios.post(`/api/coupons/${clientId}`, {couponCode, description, rate, effectiveDate, expirationDate, active})


        setCouponCode('')
        setDescription('')
        setRate(0)
        setEffectiveDate(null)
        setExpirationDate(null)
        setActive(false)
        handleCloseModal();
    }

    return(
        <>

        <Modal 
            open={open}
            onClose={handleCloseModal}
        >
            <Grid container sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1000,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
            }} spacing={2}>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h5">
                            Add New Coupon
                        </Typography>
                    </Grid>
                    {/** coupon code  - textfield */}
                    <Grid item xs={4} >
                        <TextField
                            required
                            // value={form.title}
                            fullWidth
                            onChange={(e) => setCouponCode(e.target.value)}
                            label="Code"
                            placeholder='Code'
                        />
                    </Grid>
                    {/** description - textfield */}
                    <Grid item xs={4} >
                        <TextField
                            required
                            value={description}
                            fullWidth
                            onChange={(e) => setDescription(e.target.value)}
                            id="description"
                            label="Description"
                            // placeholder='Description'
                        />
                    </Grid>
                    {/** rate - number input */}
                    <Grid item xs={4} >
                        <TextField
                            required
                            type='float'
                            value={rate}
                            fullWidth
                            onChange={(e) => setRate(e.target.value)}
                            id="rate"
                            label="Rate"
                            // placeholder='rate'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Effective Date"
                                value={effectiveDate}
                                onChange={(event) => setEffectiveDate(event)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {/** effective date - dateformat input */}
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Expiration Date"
                                onChange={(event) => setExpirationDate(event)}
                                value={expirationDate}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {/** expiration date - dateformat input */}
                    <Grid item xs={4}>
                        <FormControlLabel style={{ padding: 10 }} control={<Checkbox
                            onChange={(event) => setActive(event.target.checked)}
                            checked={active}
                             />
                        } label="Active" />
                    </Grid>
                    {/** active - boolean checkbox */}
                    <Grid item xs={4}>
                        <Button onClick={handleSubmit}>Submit</Button>
                    </Grid>
            </Grid>
        </Modal>

        </>
    )
}