import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {disableDates} from "../Utils/share-functions";

// todo: last day should be available for the next one to rent (but some days it can be included);
// todo: when start date is empty, end date is disabled
const MultiDayCalendar = ({ 
        changeDates, mode, excludeDates = [], maxDate, minDate, range, clearRange, onOpen
    }) => {
    const dispatch = useDispatch();
    const avail = useSelector(store => store.selectedBookableItem.availabilityyy);
    const [dateRange, setDateRange] = useState([null, null]);
    const [tempAvail, setTempAvail] = useState(null);
    useEffect(() => {
        // console.log(dateRange, 144444);
        if (mode === 'edit item') {
            if (dateRange[0] && dateRange[1]) {
                changeDates(dateRange);
            }
        } else if (mode === 'renter') {
            changeDates(dateRange);
        } else {
            changeDates(dateRange);
        }
    }, [dateRange]);

    useEffect(() => {
        setDateRange(range ? [range[0], range[range.length - 1]] : [null, null]);
    }, [range]);

    useEffect(() => {
        if (clearRange) {
            clearRange.current = () => setDateRange([null, null]);
        }
    }, []);
    useEffect(() => {
        if(!dateRange[0]){
            setTempAvail(null)
        }
        if(dateRange[0] && dateRange[1]){
            setTempAvail(null)
        }
    }, [dateRange]);

    const checkDisabled = () => {
        if (!dateRange[0]) return true;
    }

    const calculateInstances = (date) => {
        let index = 0;
        let begin = false;
        let instances = {};
        let finished = true;
        let longest = {count: 0}
        for(let item of avail.mapList){
            const keysForInstances = Object.keys(instances);
            if(item.date_available === date.toISOString().replace(/(T\d{2})/, 'T00') || begin === true){//dates are sorted by date from db
                if(begin === false){//begin building instances OBJ
                    for(const instanceID of item.instances){
                        instances[instanceID] = {end: false, count: 1, dates: [item.date_available], instance: instanceID}
                    }
                } else {//continue building
                    for(const instanceID of item.instances){//check instances associated with curr Date
                        if(keysForInstances.includes(String(instanceID)) && !instances[instanceID].end){//if Item is part of the original selection and the dates are ongoing
                            instances[instanceID].count++
                            instances[instanceID].dates.push(item.date_available);
                        }
                    }
                    for(const keyValue of keysForInstances){
                        if(!item.instances.includes(Number(keyValue))){
                            instances[keyValue].end = true;
                        }
                        if(instances[keyValue].count > longest.count){
                            longest = JSON.parse(JSON.stringify(instances[keyValue]));//deep copy
                        }
                        if(instances[keyValue].end === false){
                            finished = false;
                        }
                    }
                    if(finished){
                        return longest
                    } 
                }
                begin = true;
            }
            index++
        }
        return longest
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
                startText="Start Date"
                endText="End Date"
                disablePast
                value={dateRange}
                closeOnSelect={true}
                onOpen={() => onOpen(dateRange)}
                onChange={(newValue) => {
                    if(!newValue[1] && newValue[0]){
                        const inst = calculateInstances(newValue[0]);
                        dispatch({
                            type: 'SET_SELECTED_INSTANCE',
                            payload: inst
                        });
                        setTempAvail(inst.dates)
                    }
                    setDateRange(newValue)
                }}
                maxDate={maxDate}
                minDate={minDate}
                shouldDisableDate={dates => disableDates(dates, tempAvail ? tempAvail : avail.masterList, mode, excludeDates)}
                renderInput={(startProps, endProps) => {
                    endProps.inputProps.disabled = checkDisabled();
                    return (
                        <React.Fragment>
                            <TextField {...startProps} />
                            <Box sx={{mx: 2}}> to </Box>
                            <TextField {...endProps} />
                        </React.Fragment>
                    )
                }
                }
                componentsProps={{
                    actionBar: {
                        actions: ['clear'],
                    },
                }}
            />
        </LocalizationProvider>
    )
}

export default MultiDayCalendar;