import { combineReducers } from 'redux';

const bookableItemInitial = {
    title: '',
    tileTitle: '',
    summary: '',
    details: '',
    rate: '',
    categoryId: '', 
    duration: '', 
    leadTime: '',
    minDuration: '', 
    maxDuration: '', 
    prepTime: '',
    clientFeePercent: '',
    eazieBookFeePercent: '',
    availability: [],
    hourlyIncrement: '', 
    deposit: false, 
    damage_deposit: false, 
    deposit_amt: '', 
    damage_deposit_amt: '',
    /* 
    addonDescription, 
    addonPrice
    */
    addOnPhotos: [],
    tax: [],
    instances: [],
}

const clientBookedItemInitial = {
    isLoading: false,
    loaded: false,
    data: [],
    error: null,
    selected: {},
}

const addBookableItemInputs = (state = bookableItemInitial, action) => {
    switch (action.type) {
        case 'SET_ADD_FORM_INPUTS':
        if (action.payload.availability === null) {
            action.payload.availability = []
        }
            return action.payload;
        case 'ADD_FORM_ONCHANGE':
            return { ...state, [action.payload.property]: action.payload.value}
        case 'ADD_FORM_ONCHANGE_ADD_ON_PHOTOS':
            state.addOnPhotos[action.payload.index] = action.payload.value
            return { ...state}
        case 'CLEAR_ADD_FORM': 
            return bookableItemInitial;
        case ('SEND_NEW_ITEM_ID'):
            return {
                ...state,
                newItemId: action.payload
            }
        case ('ON_NEW_ITEM_ID'):
            return {
                ...state,
                onPostingSingleDate: true
            }
        case ('GOT_NEW_ITEM_ID'):
            return {
                ...state,
                onPostingSingleDate: false
            }
        default:
            return state;
    }
}

const bookableItemReducer = (state = [], action) => {
    switch(action.type) {
        case ('SET_BOOKABLE_ITEM_LIST'):
            return action.payload
        case ('SET_RENTER_FETCH_BOOKABLEITEM'):
            return action.payload
        case ('SET_ITEM_BY_CATEGORY'):
            return action.payload
    }
    return state;
}

const itemFromClientId = (state = [], action) => {
    switch(action.type) {
        case ('SET_ITEM_BY_CLIENT_ID'):
            return action.payload
    }
    return state;
}


const clientBookedItemReducer = (state = clientBookedItemInitial, action) => {
    switch(action.type) {
        case ('GET_CLIENT_BOOKED_ITEM_REQUEST'):
            return {
            ...state,
            isLoading: true,
            loaded: false,
            data: [],
            error: null
        };
        case 'CLIENT_BOOKED_ITEM_REQUEST_SUCCESS':
            return {
                ...state,
                isLoading: false,
                loaded: true,
                data: action.data,
                error: null
            };
        case 'CLIENT_BOOKED_ITEM_REQUEST_FAILURE':
            return {
                ...state,
                isLoading: false,
                loaded: true,
                data: [],
                error: action.data
            };
        case 'SET_SELECTED_BOOKING':
            return {
                ...state,
                selected: action.payload
            }
        default:
            return state;
    }
}

const bookableItem = combineReducers({
    bookableItemReducer,
    addBookableItemInputs,
    clientBookedItemReducer,
    itemFromClientId
})

export default bookableItem;
