import {Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import * as React from "react";
import Box from "@mui/material/Box";
import AddNewTaxModal from "./AddNewTaxModal";
import {useEffect, useReducer, useState} from "react";
import axios from 'axios';
import {useParams} from "react-router-dom";

const initialState = {
    title: '',
    description: '',
    taxRate: 0,
    effectiveDate: new Date(),
    expirationDate: null,
    active: true,
    dateCreated: null
};
function init(initState) {
    return {...initState};
}
function reducer(state, action) {
    if (action.type === 'reset') return {...initialState};
    state[action.type] = action.payload;
    return {...state};
}

function ManageTax() {
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [rows, setRows] = useState([]);
    const { id: clientId } = useParams();

    const getRows = () => {
        axios.get(`/api/tax/${clientId}`).then(res => setRows(() => res.data.map(d => ({
            id: d.id,
            title: d.title,
            description: d.description,
            taxRate: d.rate,
            effectiveDate: d['eff_date'],
            expirationDate: d['exp_date'],
            active: d.active,
            dateCreated: d.date_created
        }))));
    }
    const saveTax = () => {
        axios.post('/api/tax', {
            userId: clientId,
            ...state
        }).then(() => getRows()).catch(err => console.error(err));
        dispatch({type: 'reset'});
    }

    useEffect(() => {
        getRows();
    }, [])

    const columns = [
        {field: 'title', headerName: 'Title', width: 200},
        {field: 'description', headerName: 'Description', width: 500},
        {field: 'taxRate', headerName: 'Tax Rate', width: 120},
        {field: 'effectiveDate', headerName: 'Effective Date', width: 180},
        {field: 'expirationDate', headerName: 'Expiration Date', width: 180},
        {field: 'active', headerName: 'Active'},
        {field: 'dateCreated', headerName: 'Date Created', width: 180}
    ];

    return (
        <>
            <Typography align="center" variant="h4">
                Tax Management
            </Typography>
            <Box sx={{ height: 400, width: '100%', padding: 1 }}>
                <DataGrid
                    rows={rows?.map(r => ({
                        ...r,
                        expirationDate: r.expirationDate?.slice(0,10),
                        effectiveDate: r.effectiveDate?.slice(0,10),
                        dateCreated: r.dateCreated?.slice(0,10)
                    })) ?? []}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </Box>
            <AddNewTaxModal dispatch={dispatch} form={state} onSave={saveTax} />
        </>
    )
}

export default ManageTax;
