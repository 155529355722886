import React, { useState, useEffect } from 'react'
import { Dialog, Checkbox, FormControlLabel, TextField, FormControl, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Grid, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack } from '@mui/material'
import axios from 'axios'
import { styled } from '@mui/material/styles';
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { CarouselDisplay } from "../carousel/CarouselDisplay";

export default function ItemRequestDetail() {

    const BootstrapButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        borderColor: '#821E0D',
        color: '#821E0D',
        borderRadius: '25px',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#821E0D',
            borderColor: '#821E0D',
            boxShadow: 'none',
            color: 'white',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#821E0D',
            borderColor: '#821E0D',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(130,30,13,.5)',
        },
    });

    const dispatch = useDispatch();
    
    
    const user = useSelector(store => store.user)
    const params = useParams()
    const [photos, setPhotos] = useState([])
    const itemRequest = useSelector(store => store.itemRequest)
    const [open, setOpen] = useState(false);
    const history = useHistory()

    const handleChange = (evt, property) => {
        if (property === 'completed') {
            const checkVal = eval(evt.target.value)
            console.log('checkval is', checkVal);
            dispatch({
                type: 'REQUEST_FORM_ONCHANGE',
                payload: { property: property, value: !checkVal }
            });
        }
        else{
            dispatch({
                type: 'REQUEST_FORM_ONCHANGE', 
                payload:{property: property, value: evt.target.value}
            }) 
        }
    }

    const handleClose = () => {
        setOpen(false);
        if (user.authLevel === 'admin') {
            history.push('/admin/itemRequestTable')

        }
        else if (user.authLevel === 'client') {
            history.push('/client/requests/view')
  
        }
        window.location.reload();
    };

    useEffect(() => {
        const init = async () => {
            const answer = await axios.get(`/api/bookableItem/request/${params.id}`)
            console.log('answer ', answer);
            if (user.authLevel === 'admin') {
                const idArray = [answer.data[0].client_id, answer.data[0].id]
                const photoAnswer = await axios.get(`/api/photos/clientItemRequest/${idArray}`);
                console.log('photoAnswer is', photoAnswer.data);
                setPhotos(photoAnswer.data.map(photo => photo.url))
            };
            
            dispatch({
                type: 'SET_SELECTED_ITEM_REQUEST', 
                payload: answer.data[0]
            })

        }
        init()
    }, [])

    const limit = (str = '', limit = 0) => {
        if (str === null || str === undefined) {
            return
        } else {
            return str.substring(0, limit)
        }
    };
    console.log('photos', photos);

    const handleSubmit = async (e) => {
        e.preventDefault()
        axios.put(`/api/bookableItem/request/${params.id}`, {itemRequest})
        // console.log('put success, result is', result);
        setOpen(true)
    }

    // console.log('rows is', rows);

    return (
        <>
            <Typography variant='h3' padding='10px' align='center'>Item Request for {itemRequest?.title} </Typography>
            <Typography variant='h4' padding='7px' align='center'>created on {limit(itemRequest?.date_created, 10)}</Typography>

            {user.authLevel === 'admin' &&
            <div style={{ marginTop: 30, maxWidth: 670, marginLeft: 'auto', marginRight: 'auto' }}><CarouselDisplay pics={photos} /></div>
            }

            
            {/* <Box width='100%' display='flex' justifyContent='center'>
                <Button onClick={handleSubmit} variant="contained">Mark as Complete</Button>
            </Box> */}

            <Grid container maxWidth="md" mx="auto" direction="column" my={4} sx={{ padding: '20px' }}>
                <Box component='form' onSubmit={e => handleSubmit(e)}>
                    <Typography variant='h3' padding='10px' align='center'>Edit Your Item Request Here</Typography>
                    {user.authLevel === 'admin' && 
                        <Grid item >
                            <FormControl margin="normal" fullWidth>
                                <FormControlLabel control={<Checkbox value={itemRequest.completed} checked={itemRequest.completed === true} onChange={(e) => handleChange(e, 'completed')} />} label="Completed" />
                            </FormControl>
                        </Grid>
                    }
                    
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Title</Typography>
                        <Typography padding='5px' variant='h6'>Please enter the name of your item.</Typography>
                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Title'
                                multiline
                                rows={4}
                                value={itemRequest?.title}
                                onChange={(e) => handleChange(e, 'title')}
                            />
                        </FormControl>

                    </Grid>
                    
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Summary</Typography>
                        <Typography padding='5px' variant='h6'>Describe a brief summary.</Typography>
                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Summary'
                                multiline
                                rows={4}
                                value={itemRequest?.summary}
                                onChange={(e) => handleChange(e, 'summary')}

                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Detail</Typography>
                        <Typography padding='5px' variant='h6'>Describe your item in detail.</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Detail'
                                multiline
                                rows={4}
                                value={itemRequest?.detail}
                                onChange={(e) => handleChange(e, 'detail')}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Rate</Typography>
                        <Typography padding='5px' variant='h6'>What is the rate of your item? Example: $5 per hour, $60 per day.</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Rate'
                                multiline
                                rows={4}
                                value={itemRequest?.rate}
                                onChange={(e) => handleChange(e, 'rate')}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Duration Type</Typography>
                        <Typography padding='5px' variant='h6'>Will your item be daily, hourly, or other?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Duration Type'
                                multiline
                                rows={4}
                                value={itemRequest?.duration_type}
                                onChange={(e) => handleChange(e, 'duration_type')}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Min Lead Time</Typography>
                        <Typography padding='5px' variant='h6'>Booking or Reservation Lead Time is the period of time (most typically measured in calendar days) between when a guest makes the reservation and the actual check-in/arrival date.</Typography>
                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Min Lead Time'
                                multiline
                                rows={4}
                                value={itemRequest?.min_lead_time}
                                onChange={(e) => handleChange(e, 'min_lead_time')}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Duration</Typography>
                        <Typography padding='5px' variant='h6'>What is the duration type of your bookable item? Example: Blocks of two hours, blocks of one day.</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Duration'
                                multiline
                                rows={4}
                                value={itemRequest?.duration}
                                onChange={(e) => handleChange(e, 'duration')}
                            />
                        </FormControl>

                    </Grid>
                    {/* TODO: make a checkbox */}
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Deposit</Typography>
                        <Typography padding='5px' variant='h6'>Will you allow a partial upfront payment to be made at time of booking with the balance paid two days prior to reservation date? If so, what is the percentage of the total?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Deposit'
                                multiline
                                rows={4}
                                value={itemRequest?.deposit}
                                onChange={(e) => handleChange(e, 'deposit')}
                            />
                        </FormControl>

                    </Grid>
                    {/* TODO: make a checkbox */}
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Damage Deposit</Typography>
                        <Typography padding='5px' variant='h6'>Will your item require a damage deposit? If so, how much?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Damage Deposit'
                                multiline
                                rows={4}
                                value={itemRequest?.damage_deposit}
                                onChange={(e) => handleChange(e, 'damage_deposit')}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Capacity</Typography>
                        <Typography padding='5px' variant='h6'>What is the maximum capacity for your item? Example: two people max, ten people max.</Typography>
                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Capacity'
                                multiline
                                rows={4}
                                value={itemRequest?.capacity}
                                onChange={(e) => handleChange(e, 'capacity')}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Location</Typography>
                        <Typography padding='5px' variant='h6'>What is the address of your item's location?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Location'
                                multiline
                                rows={4}
                                value={itemRequest?.location}
                                onChange={(e) => handleChange(e, 'location')}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Integration (optional)</Typography>
                        <Typography padding='5px' variant='h6'>Where on your website would you like the book button for this item?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Integration'
                                multiline
                                rows={4}
                                value={itemRequest?.integration}
                                onChange={(e) => handleChange(e, 'integration')}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Quantity</Typography>
                        <Typography padding='5px' variant='h6'>How many of these items do you have in your inventory?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Quantity'
                                multiline
                                rows={4}
                                value={itemRequest?.quantity}
                                onChange={(e) => handleChange(e, 'quantity')}
                            />
                        </FormControl>

                    </Grid>

                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Add Ons</Typography>
                        <Typography padding='5px' variant='h6'>Will your item have add ons? If so, describe them</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Quantity'
                                multiline
                                rows={4}
                                value={itemRequest?.quantity}
                                onChange={(e) => handleChange(e, 'add_on')}
                            />
                        </FormControl>

                    </Grid>

                    {/* TODO: add photo carousel */}

                    <Box display="flex" justifyContent="right" margin="normal">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            style={{ margin: '20px' }}
                        >
                            Confirm Edit
                        </Button>
                    </Box>

                </Box>

            </Grid>

            <div>
                {/* TODO: add server side validation (only opens when you get a 201) */}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"changes have been saved to database"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Redirecting to Request Table
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <BootstrapButton onClick={handleClose} autoFocus>
                            Close
                        </BootstrapButton>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}