import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';



// import './LoginForm.css';
function LoginForm() {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const errors = useSelector(store => store.errors);


    const newPW = async (event) => {
        event.preventDefault();
        axios.post('/api/reset-password/request-token', {email});
        history.push('/login');
    }; // end login

    return (
        <>
        <div className="loginView">
        <form className="formPanel" onSubmit={newPW}>
        <Typography component="h2" variant="h4" align="center" mt={2}>
            Reset Password
        </Typography>
        <Typography component="p" variant="p" align="center" mt={2}>
            Enter the email provided to us. You will receive an email on how to reset your password.
        </Typography>
        {errors.loginMessage && (
            <h3 className="alert" role="alert">
            {errors.loginMessage}
            </h3>
        )}
        <Grid container maxWidth="md" mx="auto" direction="column" alignItems="center" my={4}>
            <Grid item>
            <FormControl fullWidth margin="normal">
                <TextField
                label="Email"
                type="text"
                name="username"
                value={email}
                required
                onChange={(event) => setEmail(event.target.value)}
                />
            </FormControl>
            </Grid>
        
            <Grid item>
            <FormControl margin="normal">
                <Button type="submit" variant="contained" size="large" sx={{ width: '75%' }} 
                    style={{margin: '0 auto', display: "flex"}}>
                RESET
                </Button>
            </FormControl>
            </Grid>
        </Grid>
        </form>
        
        </div>
        </>
    );
}

export default LoginForm;
