import React, {useState} from 'react'
import {Grid, FormControl, Typography, Button} from '@mui/material'
import {useDispatch} from "react-redux";
import MultiDayCalendar from "../Calendar/MultiDayCalendar";
import {getDates} from "../Utils/share-functions";

export default function BookableItemDates(props) {
    const {availability = [], mode = 'add item', datesBooked = []} = props;
    const dispatch = useDispatch();
    const [dateSlots, setDateSlots] = useState(new Map());
    const [pickerCount, setPickerCount] = useState(0);
    const [addMore, setAddMore] =useState(false);
    const changeDate = (range, index) => {
        setDateSlots(new Map(dateSlots.set(index, getDates(...range))));
        dispatch({
            type: 'ADD_FORM_ONCHANGE',
            payload: {
                property: 'availability',
                value: [...dateSlots.values()]
            },
        });
    };
    console.log(pickerCount);

    const pickerItem = i => (
        <Grid item alignSelf="center">
            <FormControl margin="normal">
                <MultiDayCalendar datesSelected={availability} mode={mode} excludeDates={datesBooked} changeDates={r => changeDate(r, i)}/>
            </FormControl>
        </Grid>
    );

    return (
        <Grid container maxWidth="md" alignContent='center' mx="auto" direction="column" mb={4}>
            <Grid item>
                <Typography component="h2" variant="h5">
                    Enter your item's availability
                </Typography>
            </Grid>
            {[...Array(pickerCount)].map((e, i) => pickerItem(i))}
            <Button variant="contained" size="small"
                style={{margin: '5px', marginTop: '15px'}}
                onClick={() => setPickerCount(pickerCount + 1)}
            >
                Add More 
            </Button> 
        </Grid>
    )
}
