import AcceptButton from './BookingManagement/AcceptButton';
import DeclineButton from './BookingManagement/DeclineButton';
import EditBookingForm from './BookingManagement/EditBookingForm';
import CancelBooking from './BookingManagement/CancelBooking';
import AddCharge from './AddCharge';
import RefundModal from './BookingManagement/RefundModal';
import  { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import dateFormatter from '../../formatters/date.format';
import moneyFormatter from '../../formatters/money.format';
import './styles.css';
import moment from "moment/moment";

// todo: refund: application fee error with fixed price
// todo: the cancel should be only one button
const BookedItemDetail = () => {
    const dispatch = useDispatch();

    const { id: selectedId } = useParams();
    const [status, setStatus] = useState(null);
    const [editMode, setEditMode] = useState(false);
    
    const [openCharge, setOpenCharge] = useState(false);
    const [openRefund, setOpenRefund] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);

    const selectedBooking = useSelector(store => store.selectedBooking);
    const { totals, statue, bookedDate } = selectedBooking;

    const init = async () => {
        const {data: booking} = await axios.get(`/api/bookings/selected/${selectedId}`);
        dispatch({
            type: 'SET_SELECTED_BOOKING',
            payload: booking,
        })
        setStatus(booking.status);
    }

    useEffect(() => {
        init().then(() => null);
    }, []);

    const handleClose = async (e) => {
        if(!e){
            await init();
            setOpenCharge(false);
            setOpenRefund(false);
            setOpenCancel(false);
        }
        if(e?.target?.classList.contains('booking-detail-modal-background')){
            await init();
            setOpenCharge(false);
            setOpenRefund(false);
            setOpenCancel(false);
        }
        setOpenCancel(false);
    };

    const displayTime = () => {
        if (!selectedBooking || !selectedBooking.start_time) {
            return (<p>Dates: from {dateFormatter(selectedBooking.start_date)} to {dateFormatter(selectedBooking.end_date)}</p>)
        }
        return (
            <>
                <p>Date: {dateFormatter(selectedBooking.start_date)}</p>
                <p>Time: {moment(selectedBooking.start_time, ['hh:mm:ss']).format('hh:mm A')}</p>
            </>
        )
    }

    if(selectedBooking)return (<>
        <div className='booked-item-detail-container'>
            <div className='booked-item-container'>
                <h1>Booking for {selectedBooking.title}</h1>
            </div>
            <div className='booked-item-summary'>
                <div className='booking-item-container'>
                    {editMode ? <EditBookingForm booking={selectedBooking} />
                        : <div className='booked-item-renter-container'>
                        <div className='booked-item-contact'>
                            <h2>Customer Info</h2>
                            <p>Name: {selectedBooking.first_name} {selectedBooking.last_name}</p>
                            <p>Phone Number: {selectedBooking?.phone ?  "(" + selectedBooking.phone.slice(0, 3) + ") " + selectedBooking.phone.slice(3, 6) + "-" + selectedBooking.phone.slice(6) : ''}</p>
                            <p>Email: {selectedBooking.email}</p>
                        </div>
                        <div className='booked-item-reservation'>
                            <h2>Booking Info</h2>
                            <p>Booking Created: {dateFormatter(bookedDate)}</p>
                            <p>Confirmation Number: {selectedBooking.confirmationNumber}</p>
                            {displayTime()}
                            <p>Status: {selectedBooking.status}</p>
                        </div>
                    </div> }

                    <div className='booked-item-financial-info-container'>
                        <h2>Total:</h2>
                        <h4>{moneyFormatter(Math.round(totals.total * 100))}</h4>
                    </div>

                    <div className='booked-item-financial-info-container'>
                        <h2 style={{marginLeft: 30}}>Order Summary:</h2>
                        <div style={{marginLeft: 50}}>
                            {selectedBooking.order.map(item =>
                                [<h4>{item.title}</h4>, ...(item.add_ons ?? []).map(x => <li>{x.title}</li>)])}
                        </div>
                    </div>
                </div>

                <div className='manage-booking-container'>
                    {statue === 'pending' && <>
                        <AcceptButton renterBookingID={selectedBooking.id} status={status} setStatus={setStatus}/>
                        <DeclineButton renterBookingID={selectedBooking.id} status={status} setStatus={setStatus}/>
                    </>}
                    {/* <Button variant="contained" color="primary" onClick={handleEdit}>Edit Booking</Button> */}
                    {/* <Button variant="contained" color="primary" onClick={() => setOpenCharge(true)}>Add Charge</Button> */}
                    {/* <Button variant="contained" color="primary" onClick={() => setOpenRefund(true)}>Refund</Button> */}
                    <Button variant="contained" color="primary" disabled={selectedBooking.status === 'canceled'} onClick={() => setOpenCancel(true)}>Cancel</Button>
                    {/* <ClaimButton damage_deposit={selectedBooking.damage_deposit} confirmationNumber={selectedBooking.confirmationNumber} status={status} setStatus={setStatus} id={selectedBooking.id}  /> */}
                </div>
            </div>
        </div>
        <div className='ledger-table'>
            <h2>Transaction History</h2>
            {selectedBooking?.payments?.map(payment => {
                return (
                    <div className='payment-card'>
                        <h3>Payment ID: {payment.payment_intent}<h3>
                        </h3> Total: {moneyFormatter(Math.round(payment.amount * 100))} </h3>
                        
                    </div>
                )
            })}
        </div>

        {/* <EditBookingForm booking={selectedBooking}/> */}
        {openCharge && <AddCharge handleClose={handleClose} selectedBooking={selectedBooking}/>}
        {openCancel && <CancelBooking handleClose={handleClose} open={openCancel}/>}
        {openRefund && <RefundModal handleClose={handleClose} selectedBooking={selectedBooking} payments={selectedBooking.payments}/>}
    </>);
    return(<></>)
}

export default BookedItemDetail;