const contact = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CONTACT' :
      return {
        loaded: false,
      }
    case 'FETCH_CONTACT_SUCCESS' :
      return {
        loaded: true,
        data: action.payload
      }
    default : 
      return state;
  }
} 

export default contact;