import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import Grid from '@mui/material/Grid';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {CalendarPicker} from "@mui/x-date-pickers/CalendarPicker";
import {PickersDay} from '@mui/x-date-pickers/PickersDay';
import {Typography} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import {Button} from "@material-ui/core";
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {StaticDateRangePicker} from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import {DateRangePickerDay as MuiDateRangePickerDay} from '@mui/x-date-pickers-pro/DateRangePickerDay';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {dateHelper, getDateType} from "../Utils/share-functions";
function SeasonalPricing() {
    const {id: bookableItemId} = useParams();
    const [availDates, setAvailDates] = useState([]);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [price, setPrice] = useState(0);
    const [nightly, setNightly] = useState([]);
    const [range, setRange] = useState([null, null]);
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('singleDay');
    const [currentDatePrice, setCurrentDatePrice] = useState(price);
    const checkDisable = date => !availDates?.some(dateHelper(date));
    const checkNightlyPrice = date => nightly?.map(p => getDateType(p.date))?.some(dateHelper(date));
    const handleDatesFromResp = (datesArr, field) => datesArr.map(item => getDateType(item[field]));

    const getPrice = async () => {
        const {
            nightlyPrice,
            allPrice: {rate},
            itemAvailDates
        } = (await axios.get(`/api/bookings/itemPricing/${bookableItemId}`))?.data;
        setAvailDates(handleDatesFromResp(itemAvailDates, 'date_available'));
        setPrice(rate);
        setNightly(nightlyPrice);
    }

    useEffect(() => {
        try {
            getPrice().then(null);
        } catch (e) {
            console.error(e);
        }
    }, []);

    const changeDatePrice = async () => {
        try {
            await axios.post('/api/bookings/itemPricing/', {
                id: bookableItemId,
                price: currentDatePrice,
                date: `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`
            })
            await getPrice();
            handleClose();
            setSnackOpen(true);
        } catch (e) {
            console.error(e);
        }
    }

    const dateRangeSavePrice = async () => {
        try {
            if (range.some(r => !r)) {
                throw new Error('range is empty');
            }
            await axios.post('/api/bookings/itemPricingMulti/', {
                id: bookableItemId,
                price: currentDatePrice,
                dateRange: range.map(d => `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`)
            })
            await getPrice();
            setRange([null, null]);
            setSnackOpen(true);
        } catch (e) {
            console.error(e);
        }
    }

    const changeDateColor = date => {
        if (checkNightlyPrice(date)) return {backgroundColor: 'rgb(255, 80, 80, 0.5)'};
        return null;
    }

    const DateRangePickerDay = styled(MuiDateRangePickerDay)(
        ({theme, isHighlighting, isStartOfHighlighting, isEndOfHighlighting, date}) => ({
            ...(isHighlighting && {
                borderRadius: 0,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.primary.dark,
                },
            }),
            ...(isStartOfHighlighting && {
                borderTopLeftRadius: '50%',
                borderBottomLeftRadius: '50%',
            }),
            ...(isEndOfHighlighting && {
                borderTopRightRadius: '50%',
                borderBottomRightRadius: '50%',
            }),
            ...(checkNightlyPrice(date) && {
                backgroundColor: 'rgb(255, 80, 80, 0.5)'
            })
        }),
    );

    const handleClose = () => {
        setSelectedDate(null);
        setRange([null, null]);
        setOpen(false);
    }

    const handleGetCurrentDatePrice = date => {
        if (checkNightlyPrice(date)) {
            for (const p of nightly) {
                const night = getDateType(p.date);
                if (dateHelper(date)(night)) return p.rate;
            }
        }
        return price;
    }

    const removeSeasonalPrice = async () => {
        try {
            await axios.post('/api/bookings/deleteItemPricing/', {
                id: bookableItemId,
                date: `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`
            })
            await getPrice();
            handleClose();
            setSnackOpen(true);
        } catch (e) {
            console.error(e);
        }
    }

    const removeSeasonalPriceMultiple = async () => {
        try {
            if (range.some(r => !r)) {
                throw new Error(' range is empty');
            }
            await axios.post('/api/bookings/deleteItemPricingMulti/', {
                id: bookableItemId,
                dateRange: range.map(d => `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`)
            })
            await getPrice();
            handleClose();
            setSnackOpen(true);
        } catch (e) {
            console.error(e);
        }
    }

    const handleSeasonalPriceChange = evt => {
        if (isNaN(evt.target.value)) return;
        setCurrentDatePrice(Number(evt.target.value));
    }

    const renderWeekPickerDay = (date, dateRangePickerDayProps) => (<DateRangePickerDay {...dateRangePickerDayProps} date={date}/>)

    const displayCalendar = () => mode === 'singleDay' ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CalendarPicker
                date={selectedDate}
                onChange={date => {
                    setOpen(true);
                    setSelectedDate(date);
                    setCurrentDatePrice(() => handleGetCurrentDatePrice(date));
                }}
                shouldDisableDate={dates => checkDisable(dates)}
                openTo={'day'}
                views={['day']}
                disablePast={true}
                renderDay={(date, selectedDates, pickersDayProps) => (
                    <PickersDay {...pickersDayProps} sx={
                        changeDateColor(date)
                    }/>
                )}
            />
        </LocalizationProvider>
    ) : snackOpen ? null : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                label="date range"
                value={range}
                onChange={newVal => setRange(newVal)}
                renderDay={renderWeekPickerDay}
                disablePast={true}
                shouldDisableDate={dates => checkDisable(dates)}
                renderInput={(startProps, endProps) => (
                    <React.Fragment>
                        <TextField {...startProps} />
                        <Box sx={{mx: 2}}> to </Box>
                        <TextField {...endProps} />
                    </React.Fragment>
                )}
            />
        </LocalizationProvider>
    );

    return (
        <>
            <div>
                <Grid container spacing={1} padding={5}>
                    <Grid item sm={4} xs={12}>
                        <Typography>Normal Price: {price}</Typography>
                        <br/>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Mode</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={mode}
                                onChange={e => setMode(e.target.value)}
                            >
                                <FormControlLabel value="singleDay" control={<Radio/>} label="Single Day"/>
                                <FormControlLabel value="dateRange" control={<Radio onClick={() => setCurrentDatePrice(null)}/>} label="Date Range"/>
                            </RadioGroup>
                        </FormControl>
                        {mode === 'dateRange' ? (
                            <>
                                <br/>
                                <TextField
                                    style={{margin: 5}}
                                    id="filled-number"
                                    label="Override Price"
                                    value={currentDatePrice}
                                    onChange={handleSeasonalPriceChange}
                                />
                                <Button onClick={dateRangeSavePrice} autoFocus>
                                    Save Price
                                </Button>
                                <Button onClick={removeSeasonalPriceMultiple}>Remove Override Price</Button>
                            </>
                            ) : null}
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <Typography style={{display: 'flex', width: 320, margin: '0 auto'}}>
                            Please select date to change pricing
                        </Typography>
                        {open ? null : displayCalendar()}
                        <Typography style={{display: 'flex', width: 320, margin: '0 auto'}}>
                            <span style={{color: 'rgb(255, 80, 80, 0.5)', marginRight: 5}}>●</span> Seasonal Pricing
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Please Confirm"}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        style={{margin: 5}}
                        id="filled-number"
                        label="Override Price"
                        value={currentDatePrice}
                        onChange={handleSeasonalPriceChange}
                    />
                </DialogContent>
                {selectedDate && checkNightlyPrice(selectedDate) ? (
                    <DialogActions>
                        <Button onClick={removeSeasonalPrice}>Remove Override Price</Button>
                    </DialogActions>
                ) : null}
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={changeDatePrice} autoFocus>
                        Save Price
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackOpen}
                autoHideDuration={1000}
                onClose={() => setSnackOpen(false)}
            >
                <Alert onClose={() => setSnackOpen(false)} severity="success" sx={{width: '100%'}}>
                    {snackOpen ? 'Changed Successfully' : null}
                </Alert>
            </Snackbar>
        </>
    )
}

export default SeasonalPricing;
