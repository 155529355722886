import React, { useState, useEffect } from "react"
import CouponModal from './CouponModal'
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Box, Button } from '@mui/material'
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios'


const columns = [
    { field: 'code', headerName: 'Code', minWidth: 80, flex: 0.6 },
    { field: 'description', headerName: 'Description', minWidth: 90, flex: 0.6 },
    { field: 'rate', headerName: 'Coupon Rate', minWidth: 90, flex: 0.6 },
    { field: 'effectiveDate', type: 'date', headerName: 'Effective Date', minWidth: 90, flex: 0.6 },
    { field: 'expirationDate', type: 'date', headerName: 'Expiration Date', minWidth: 90, flex: 0.6 },
    { field: 'active', headerName: 'Active' },
    { field: 'dateCreated', type: 'date', headerName: 'Date Created', minWidth: 90, flex: 0.6 }
];


export default function ManageCoupons() {

    const history = useHistory

    const dispatch = useDispatch()

    const limit = (str = '', limit = 0) => {
        if (str === null || str === undefined) {
            return
        } else {
            return str.substring(0, limit)
        }
    };

    const params = useParams();

    const [rows, setRows] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const handleCloseModal = () => setOpen(false);
    const handleOpenModal = () => setOpen(true);

    const clientId = params.id


    useEffect(() => {
        const init = async () => {
            const answer = await axios.get(`/api/coupons/${clientId}`);
            // console.log('answer.data is', answer.data);
           setRows(answer.data.map((row, index) => {
            return(
                {
                    id: row.id,
                    code: row.code, 
                    description: row.description, 
                    rate: row.rate,
                    effectiveDate: limit(row.eff_date, 10), 
                    expirationDate: limit(row.exp_date, 10),
                    active: row.active, 
                    dateCreated: limit(row.date_created, 10)
                }
            )
           }))
        // setRows(answer.data)
        }
        init()
    }, [open]);

    return (
        <>
            <Typography variant="h3" align="center" padding='10px'>Manage Coupons</Typography>

            <Box sx={{ height: 400, width: '100%', padding: 1 }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </Box>
            <Box width='100%' display='flex' justifyContent='center'>
                <Button variant="contained" onClick={handleOpenModal}>Add New</Button>
            </Box>
            <CouponModal open={open} handleCloseModal={handleCloseModal} />
        </>
    )
}