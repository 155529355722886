import axios from 'axios';
import { put, takeEvery } from 'redux-saga/effects';

function* fetchCategories(action) {
    try{
        const response = yield axios.get(`/categoryList/${action.clientId}`)
        
        yield put({
            type: 'CATEGORY_REQUEST_SUCCESS',
            data: response.data
        })
    }
    catch(error){
        console.error('ERROR getting data in fetchClients', error);
        yield put({
            type: 'CATEGORY_REQUEST_FAILURE',
            data: error
        })
    }
}

function* addCategory(action) {
    try{
        const { name, parentId, clientId, pic } = action.payload;
        const formData = new FormData();
        formData.append('pic', pic);
        formData.append('name', name);
        if (parentId) formData.append('parentId', parentId);
        formData.append('userId', clientId);
        yield axios.post('/categoryList', formData);
        yield put({ type: 'GET_CATEGORY_LIST_REQUEST', clientId });
    }
    catch(error){
        console.error('ERROR getting data in add category', error);
        yield put({
            type: 'CATEGORY_REQUEST_FAILURE',
            data: error
        })
    }
}

function* editCategory(action) {
    try{
        if (action.payload.pic) {
            const { name, id, pic, oldPic } = action.payload;
            const formData = new FormData();
            formData.append('pic', pic);
            formData.append('name', name);
            formData.append('categoryId', id);
            formData.append('oldPic', oldPic);
            yield axios.put('/categoryList/withPic', formData);
        } else {
            yield axios.put('/categoryList', action.payload);
        }
        yield put({ type: 'GET_CATEGORY_LIST_REQUEST', clientId: action.payload.clientId });
    }
    catch(error){
        console.error('ERROR getting data in edit category', error);
        yield put({
            type: 'CATEGORY_REQUEST_FAILURE',
            data: error
        })
    }
}

function* deleteCategory(action) {
    try{
        yield axios.delete(`/categoryList/${action.payload.id}`);
        yield put({ type: 'GET_CATEGORY_LIST_REQUEST', clientId: action.payload.clientId })
    }
    catch(error){
        yield put({
            type: 'CATEGORY_REQUEST_FAILURE',
            data: error
        })
        console.error('ERROR getting data in delete category', error);
    }
}

function* categorySaga(){
    yield takeEvery('ADD_CATEGORY', addCategory);
    yield takeEvery('EDIT_CATEGORY', editCategory);
    yield takeEvery('DELETE_CATEGORY', deleteCategory);
    yield takeEvery('GET_CATEGORY_LIST_REQUEST', fetchCategories);
}

export default categorySaga;