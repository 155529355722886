import React, { useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const ViewContact = () => {
  const dispatch = useDispatch();
  const contacts = useSelector( store => store.contact.data);
  const isLoaded = useSelector( store => store.contact.loaded);

  // Get client data on page load.
  useEffect(() => dispatch({type: 'FETCH_CONTACT'}), []);

  // If the list of contacts is not retrieved from DB, then show circular progerss bar.
  if (!isLoaded) {
    return <>
      <Container align="center">
        <Box marginTop={2} marginBottom={2}>
          <Typography variant='h5'>
            Loading ...
         </Typography>
        </Box>
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      </Container>
    </>;
  }

  // Only render the table if all the contact data has been retrieved from DB.
  return (
    <>
      <Container align="center">
        <Box marginTop={2} marginBottom={2}>
          <Typography variant='h5'>
            Contacts
          </Typography>
        </Box>
      </Container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">First Name</TableCell>
              <TableCell align="center">Last Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Phone</TableCell>
              <TableCell align="center">Organization</TableCell>
              <TableCell align="center">Comments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts.map((contact) => (
              <TableRow
                key={contact.id}
              >
                <TableCell align="center">{contact.date_created}</TableCell>
                <TableCell align="center">{contact.first_name}</TableCell>
                <TableCell align="center">{contact.last_name}</TableCell>
                <TableCell align="center">{contact.email}</TableCell>
                <TableCell align="center">{contact.phone}</TableCell>
                <TableCell align="center">{contact.organization_name}</TableCell>
                <TableCell align="center">{contact.comments}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


    </>
  )
}

export default ViewContact