import * as React from 'react';

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableContainer, Paper, Stack, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid';

import Typography from '@mui/material/Typography';

import DOMPurify from "dompurify";


// import './ViewBookableItem.css';

function ViewBookableItem() {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const selectedClientItem = useSelector((store) => store.selectedClientItem);
    const user = useSelector((store) => store.user);
    const { id: clientId } = useParams();
    const [rowsPerPage, setRowsPerPage] = useState(10)
    /*  const fetchBookableItem = () => {
         dispatch({ 
             type: 'FETCH_BOOKABLE_ITEM' 
         })
     }; */

    useEffect(() => {
        dispatch({
            type: "FETCH_CLIENT_BOOKABLE_ITEM",
            payload: params.id
        })
    }, [params.id]);
    useEffect(() => {
        if (clientId && clientId.length) {
            dispatch({ type: 'GET_CATEGORY_LIST_REQUEST', clientId });
        }
    }, [clientId]);

    // const goBack = () => {
    //     history.push('/user');
    // }

    /* const deleteItem = (item) => {
        // console.log('item id is', item);
        dispatch({
            type: "DELETE_SELECTED_ITEM",
            payload: {
                itemId: item, 
                clientId: params.id
            }
        })

    } */
    const columns = [
        {field: 'category', headerName: 'Category', minWidth: 90, flex: 0.6}, 
        {field: 'title', headerName: 'Title', minWidth: 90, flex: 0.6 }, 
        {field: 'rate', headerName: 'Rate ($)', minWidth: 90, flex: 0.6 }, 
    ]

    const rows = selectedClientItem.map((row, index) => {
        return (
            {
                id: index, 
                category: row.ordered_categories !== null ? row.ordered_categories.join(' > ') : 'No Category', 
                title: row.title, 
                rate: row.rate, 
                itemId: row.id
            }
        )
    })

    const [gridHeight, setGridHeight] = useState(8)
    const [size, setSize] = useState(20)

    // defined handleChange to set min and max. can remove if needed
    const handleChange = (e) => {
            if(e > 0) {
                setSize(e)
            }
            else if(e < 0 || e === 0) {
                return
            }
        }


    return (
        <>

            <Typography variant='h3' padding='10px' align='center'> Bookables {user.authLevel === 'client' && '(click to edit)'}</Typography>

            <Stack justifyContent="center" padding='10px' spacing={2} direction="row">
                <TextField variant='standard' onChange={(e) => { handleChange(e.target.value) }} type='number' min={6}  placeholder='# of rows' />
                {/* <Button variant="outlined" color="primary" onClick={goBack} >Back</Button> */}
                {/* <Button variant="outlined" color="primary" onClick={addItem}  >Add New Item</Button> */}
            </Stack>

                    <DataGrid
                        sx={{ m: 2, boxShadow: 2, }}
                        aria-label="a dense table"
                        rows={rows}
                        autoHeight={true}
                        columns={columns}
                        pageSize={size}
                        rowsPerPageOptions={[15]}
                        onRowClick={(e) => history.push(`/editBookableItemForm/${e.row.itemId}?clientId=${clientId}`)}
                        // `/editBookableItemForm/${e.row.itemId}?clientId=${clientId}`
                    />
                {/* </div> */}

            {/* </TableContainer> */}
            {/* <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Rate</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedClientItem.map((item, index) => (

                            <TableRow
                                key={index}
                                sx={{ border: 2, minWidth: 100 }}
                            >
                                <TableCell className="ViewBookableItemTableCell">{item.ordered_categories !== null ? item.ordered_categories.join(' > ') : 'No Category'}</TableCell>
                                <TableCell component="th" scope="row" className="ViewBookableItemTableCell" >{item.title}</TableCell>
                                <TableCell className="ViewBookableItemTableCell">${item.rate}</TableCell>
                                <TableCell >

                                    <Button variant='outlined'>
                                        <Link to={`/editBookableItemForm/${item.id}?clientId=${clientId}`} >
                                            Edit
                                        </Link>
                                    </Button>

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> */}
            
        </>
    )

}

export default ViewBookableItem;