import React from 'react'
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ListItemButton from '@mui/material/ListItemButton';
import './Nav.css';

const SuperAdminNav = () => {
    const dispatch = useDispatch();

    // Manage state, click, anchor, and open & close status for hamburger menu items (extra small display)
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    // Manage state, click, and open & close status for "Clients" submenu item in extra small display
    const [openXSClients, setOpenXSClients] = React.useState(false);
    const handleClickXSClients = () => {
      setOpenXSClients(!openXSClients);
    };

    // Manage state, click, and open & close status for "Reports" submenu item in extra small display
    const [openXSReports, setOpenXSReports] = React.useState(false);
    const handleClickXSReports = () => {
      setOpenXSReports(!openXSReports);
    };
  
    // Manage state, click, anchor, and open & close status for "Clients" menu items - in medium display
    const [anchorElClients, setAnchorElClients] = React.useState(null);
    const openClients = Boolean(anchorElClients);
    const handleClickClients = (event) => {
      setAnchorElClients(event.currentTarget);
    };
    const handleCloseClients = () => {
      setAnchorElClients(null);
    };

     // Manage state, click, anchor, and open & close status for "Customers" menu - in medium display
     const [anchorElCustomers, setAnchorElCustomers] = React.useState(null);
     const openCustomers = Boolean(anchorElCustomers); // Needed in case there will be submenu items under "Customers"
     const handleClickCustomers = (event) => {
       setAnchorElCustomers(event.currentTarget);
     };
     // handleCloseCustomers will be needed if there will be submenu items under "Customers"
     const handleCloseCustomers = () => {
       setAnchorElCustomers(null);
     };  
     
    // Manage state, click, anchor, and open & close status for "Reports" menu items - in medium display
    const [anchorElReports, setAnchorElReports] = React.useState(null);
    const openReports = Boolean(anchorElReports);
    const handleClickReports = (event) => {
      setAnchorElReports(event.currentTarget);
    };
    const handleCloseReports = () => {
      setAnchorElReports(null);
    };
    
    return (
        <AppBar position="static" sx={{ backgroundColor: 'white' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    {/* For medium display, the logo and "home" link appear in the middle */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <Link to="/home">
                            <img src="./assets/images/eazie-book-logo-horizontal.png" id="logo" />
                        </Link>
                    </Box>

                    {/* Menu for extra small display */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, color: 'black' }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <List
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                {/* "Clients" menu item */}
                                <ListItemButton sx={{ width: 300 }} onClick={handleClickXSClients}>
                                    <ListItemText primary="Clients" />
                                    {openXSClients ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItemButton>
                                <Collapse in={openXSClients} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/admin/invite" sx={{ pl: 4 }}>
                                            <ListItemText primary="Add New Client" />
                                        </ListItemButton>
                                        <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/admin/itemRequestTable" sx={{ pl: 4 }}>
                                            <ListItemText primary="View Item Requests" />
                                        </ListItemButton>
                                        <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/admin/viewContact" sx={{ pl: 4 }}>
                                            <ListItemText primary="View Contacts" />
                                        </ListItemButton>
                                    </List>
                                    {/* <List component="div" disablePadding>
                                        <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/addBookableItem/" sx={{ pl: 4 }}>
                                            <ListItemText primary="Add Bookable Item" />
                                        </ListItemButton>
                                    </List> */}
                                </Collapse>

                                {/* "Customers" menu item */}
                                <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/admin/customers">
                                    <ListItemText primary="Customers" />
                                    
                                </ListItemButton>                                

                                {/* "Reports" menu item */}
                                <ListItemButton sx={{ width: 300 }} onClick={handleClickXSReports}>
                                    <ListItemText primary="Reports" />
                                    {openXSReports ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItemButton>
                                <Collapse in={openXSReports} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/reports/financial" sx={{ pl: 4 }}>
                                            <ListItemText primary="Financial" />
                                        </ListItemButton>
                                    </List>
                                    {/* <List component="div" disablePadding>
                                        <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/admin/reports/ytd-financial" sx={{ pl: 4 }}>
                                            <ListItemText primary="YTD Financial" />
                                        </ListItemButton>
                                    </List> */}
                                </Collapse>                                

                            </List>
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, mr: 1 }}>
                        <Link to="/home">
                            <img src="./assets/images/eazie-book-logo-horizontal.png" id="logo" />
                        </Link>
                    </Box>

                    {/* Menu items for medium display */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                        {/* "Clients" menu item on nav bar */}
                        <Button
                            id="clients-button"
                            onClick={handleClickClients}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Clients
                        </Button>
                        <Menu
                            id="clients-menu"
                            anchorEl={anchorElClients}
                            open={openClients}
                            onClose={handleCloseClients}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {/* "Add New Client" and "Add Bookable Item" submenu items under "Clients" menu item */}
                            <MenuItem onClick={handleCloseClients} component={Link} to="/admin/invite">Add New Client</MenuItem>
                            <MenuItem onClick={handleCloseClients} component={Link} to="/admin/itemRequestTable">View Item Requests</MenuItem>
                            <MenuItem onClick={handleCloseClients} component={Link} to="/admin/viewContact">View Contacts</MenuItem>
                            {/* TODO: Clicking on "Add Bookable Item" MenuItem should link to a search page - to search for a client. */}
                            {/* <MenuItem onClick={handleCloseClients} component={Link} to="/addBookableItem/">Add Bookable Item</MenuItem> */}
                        </Menu>

                        {/* "Customers" menu on app bar */}
                        <Button
                            id="customers-button"
                            onClick={handleClickCustomers}
                            component={Link} to="/admin/customers"
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Customers
                        </Button>

                        {/* "Reports" menu item on nav bar */}
                        <Button
                            id="reports-button"
                            onClick={handleClickReports}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Reports
                        </Button>
                        <Menu
                            id="reports-menu"
                            anchorEl={anchorElReports}
                            open={openReports}
                            onClose={handleCloseReports}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleCloseReports} component={Link} to="/reports/financial">Financial</MenuItem>
                        </Menu> 
                        {/* <Button
                            id="reports-menu"
                            // onClick={handleClickCustomers}
                            component={Link} to="/reports/financial"
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Financial Reports
                        </Button> */}

                    </Box>

                    {/* "Log Out" menu in app bar */}
                    <Box sx={{ flexGrow: 0, color: 'black' }}>
                        <Button sx={{ color: 'black' }} id="signOut" onClick={() => dispatch({ type: 'LOGOUT' })}>
                            Log Out
                        </Button>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default SuperAdminNav