import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import React, {useState, useEffect} from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const BuildTreeView = props => {
    const {categoryList, handleSelect, selectedId, clientName, callFrom} = props;
    const [categoryTree, setCategoryTree] = useState({});

    const constructTree = (tree, category) => { // this function must run only once
        const {parentId} = category;
        if (!parentId) {
            tree.children = [...(tree.children || []), category];
        } else {
            const parentEle = categoryList.find(f => f.id === parentId);
            parentEle.children = [...(parentEle.children || []), category];
        }
        return tree;
    }

    useEffect(() => {
        const rootName = callFrom === 'category' ? `${clientName}'s Rental` : 'Please Select Category';
        const tree = categoryList.reduce(constructTree, {});
        const handleNoCategory = () =>({
            children: [{ id: -1, name: 'No Category'}, ...tree.children ? tree.children : []]
        })

        setCategoryTree(() => (
            {
                name: rootName,
                bookableItems: [],
                id: 0,
                parentId: null,
                ...(callFrom === 'category' ? tree : handleNoCategory())
            }
        ));
    }, [categoryList]);

    const displayTree = ({id, name, children}) => (
        <TreeItem nodeId={id.toString()} label={name} key={id}>
            {children && children.map(displayTree)}
        </TreeItem>
    )

    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            selected={selectedId}
            onNodeSelect={handleSelect}
        >
            {categoryTree.name && displayTree(categoryTree)}
        </TreeView>
    )
}

export default BuildTreeView;
