import React, {useReducer, useState} from "react";
import{useDispatch, useSelector} from 'react-redux';
import {disableDates} from "../Utils/share-functions";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {CalendarPicker} from '@mui/x-date-pickers/CalendarPicker';
import SingleDaySelectTimeSlot from "./SingleDaySelectTimeSlot";

const initialTimeState = {
    startTime: new Date(),
    endTime: new Date(),
    date: new Date()
};

const setInit = initial => initial;

const changeTimeState = (state, action) => {
    switch (action.type) {
        case 'change_date':
            return {
                ...state,
                date: action.payload
            };
        case 'change_start_time':
            return {
                ...state,
                startTime: action.payload
            };
        case 'change_end_time':
            return {
                ...state,
                endTime: action.payload
            };
        default:
            throw new Error();
    }
}


const SingleDayCalendar = ({initialTime = initialTimeState, hourlyIncrement, itemId, availableHours, maxHour, minHour, hideDuration}) => {
    const [state, setState] = useReducer(changeTimeState, initialTime, setInit);
    const [hoursAvail, setHoursAvail] = useState([]);
    const avail = useSelector(store => store.selectedBookableItem.availabilityyy);
    const dispatch = useDispatch();

    const handleChange = date => {
        setState({
            type: 'change_date', payload: date
        })
        if (hideDuration) return;
        dispatch({
            type: 'BOOKING_FORM_ONCHANGE',
            payload: {
                //format date mm-dd-yyyy
                value: date.toLocaleDateString().replace(/(\/)/g, '-'),
                property: 'date'
            },
        });
        setHoursAvail(avail.mapList.filter(item => item.date === date.toISOString().replace(/(T\d{2})/, 'T00')));
        dispatch({
            type: 'FETCH_AVAILABLE_HOURS',
            payload: {
                itemId,
                date
            }
        })
    }

    const handleHoursChange = (startHour, hours, endHours) => {
        // const instanceSelected = calculateInstances(startHour);
        dispatch({
            type: 'BOOKING_FORM_ONCHANGE',
            payload: {
                //format date mm-dd-yyyy
                value: startHour,
                property: 'startHour'
            },
        });
        dispatch({
            type: 'BOOKING_FORM_ONCHANGE',
            payload: {
                //format date mm-dd-yyyy
                value: hours,
                property: 'hours'
            },
        });
        dispatch({
            type: 'BOOKING_FORM_ONCHANGE',
            payload: {
                //format date mm-dd-yyyy
                value: endHours,
                property: 'endHour'
            },
        });
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CalendarPicker
                    disablePast={true}
                    date={state.date}
                    onChange={handleChange}
                    shouldDisableDate={dates => disableDates(dates, [...new Set(avail.masterList)])}
                    openTo={'day'}
                    views={['day']}
                />
            </LocalizationProvider>
            <SingleDaySelectTimeSlot
                selectedDate={state.date}
                minHour={minHour}
                maxHour={maxHour}
                hoursSelection={availableHours}
                hoursAvail={hoursAvail}
                hourlyIncrement={hourlyIncrement}
                onHoursChange={handleHoursChange}
                hideDuration={hideDuration}
            />
        </>
    );
}

export default SingleDayCalendar;
