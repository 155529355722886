const initialState = {
    isLoading: false,
    loaded: false,
    data: [],
    error: null
}

const categoryList = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CATEGORY_LIST_REQUEST':
            return {
                ...state,
                isLoading: true,
                loaded: false,
                data: [],
                error: null
            };
        case 'CATEGORY_REQUEST_SUCCESS':
            return {
                ...state,
                isLoading: false,
                loaded: true,
                data: action.data,
                error: null
            };
        case 'CATEGORY_REQUEST_FAILURE':
            return {
                ...state,
                isLoading: false,
                loaded: true,
                data: [],
                error: action.data
            };
        default:
            return state;
    }
}

export default categoryList;