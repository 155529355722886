import React, { useState, useEffect } from 'react'
import { TableContainer, Button, Paper, Typography, Box } from '@mui/material'
import axios from 'axios'
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

export default function ClientViewRequests() {

    useEffect(() => {
        const init = async () => {
            const answer = await axios.get(`/api/bookableItem/clientRequests/${clientId}`)
            console.log('answer ', answer);
            setRows(answer.data)
        }
        init()
    }, [])

    const columns = [
        { field: 'id', headerName: 'id', minWidth: 30, flex: 0.4 },
        { field: 'date_created', headerName: 'Request Date', minWidth: 90, flex: 0.5 },
        { field: 'title', headerName: 'Item Title', minWidth: 100, flex: 0.8 },
    ]

    const clientId = useSelector(store => store.user.id)

    const history = useHistory()

    const limit = (str = '', limit = 0) => {
        if (str === null || str === undefined) {
            return
        } else {
            return str.substring(0, limit)
        }
    };

    const [rows, setRows] = useState([])

    const formattedRows = rows.map((row, index) => {
        return (
            {
                id: row.id,
                date_created: limit(row.date_created, 10),
                title: row.title,
                index: index            
            }
        )
    })

    return (
        <>
            <Box sx={{ mt: 2 }}>
                <Typography variant="h4" align='center' gutterBottom='true'>
                    Bookable Item Requests
                </Typography>
                <Typography variant="h6" align='center' gutterBottom='true'>
                    Click to Edit
                </Typography>

            </Box>

            <TableContainer component={Paper}  >
                <div style={{ height: 600, width: '100%', alignContent: 'center' }}>
                    <DataGrid
                        sx={{ m: 2, boxShadow: 2 }}
                        aria-label="a dense table"
                        rows={formattedRows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[8]}
                        onRowClick={(e) => { history.push(`/admin/itemRequestTable/detail/${e.id}`)}}
                        onSelectionModelChange={id => setSelectedRows(id)}
                    />
                </div>
            </TableContainer>        
        </>
    )
}