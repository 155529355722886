import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios'

const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    color: 'black',
    transition: 'border .3s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


export default function DropZone({ isGallery }) {
    const clientId = useSelector(store => store.user.id)
    const dispatch = useDispatch()

    const photos = useSelector(store => store.photos)

    const [selectedImages, setSelectedImages] = useState([]);

    // console.log('selectedImages is', selectedImages);

    function deleteHandler(image) {
        setSelectedImages(selectedImages.filter((e) => e !== image));
        URL.revokeObjectURL(image);
    }

    const onDrop = useCallback(async (acceptedFiles) => {
        console.log('files is', acceptedFiles);

        const formData = new FormData();

        for (let acceptedFile of acceptedFiles) {
            formData.append('photos', acceptedFile)
        }

        // clientPhotos
        if (isGallery === true) {
            await axios.post(`/api/photos/client/${clientId}`, formData)

            const answer = await axios.get(`/api/photos/client/${clientId}`);
            console.log('answer.data is', answer.data);

            dispatch({
                type: 'SET_ITEM_PHOTOS',
                payload: answer.data
            })
        };

        if (isGallery === false) {
            
            let selectedFilesArray = Array.from(acceptedFiles);

            // setSelectedImages((previousImages) => previousImages.concat(selectedFilesArray));

            dispatch({
                type: 'SET_ITEM_REQUEST_PHOTOS',
                payload: selectedFilesArray
            })
        }



    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div>Drag and drop your images here.</div>
        </div>
    )
}