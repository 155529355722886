import React, {useEffect, useReducer} from 'react';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
    checkBrowserIsFirefox,
    checkDateValid,
    checkOverLap,
    mapDateToString,
    weekDayDict,
    setHours, handleTimeZoneOffset
} from "../Utils/share-functions";
import Grid from '@mui/material/Grid';
import {Button} from "@mui/material";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
// todo: startDate type will change
const findSlot = (slots, targetSD) => slots.find(a => (new Date(a.startDate)).getTime() === (new Date(targetSD)).getTime())

const initialState = {
    timeSlotArr: [
        {
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
            weekDaySelect: []
        }
    ]
};

function init(initState) {
    return {...initState};
}

const reducer = (state, action) => {
    if (action.type === 'reset') return {...initialState};
    switch (action.type) {
        case 'update from server':
            return {...state, timeSlotArr: action.payload}
        case 'add more': {
            const newTimeSlotArr = [...state.timeSlotArr, {
                startDate: null,
                startTime: null,
                endDate: null,
                endTime: null,
                weekDaySelect: []
            }];
            return {...state, timeSlotArr: newTimeSlotArr};
        }
        case 'change end time': {
            const {newValue, startDate} = action.payload;
            const slot = findSlot(state.timeSlotArr, startDate);
            slot.endTime = newValue;
            return {...state};
        }
        case 'change start time': {
            const {newValue, startDate} = action.payload;
            const slot = findSlot(state.timeSlotArr, startDate);
            slot.startTime = newValue;
            return {...state};
        }
        case 'change end date': {
            const {newValue, startDate} = action.payload;
            const slot = findSlot(state.timeSlotArr, startDate);
            slot.endDate = newValue;
            return {...state};
        }
        case 'change start date': {
            const {newValue, startDate} = action.payload;
            const slot = findSlot(state.timeSlotArr, startDate);
            slot.startDate = newValue;
            console.log(startDate, newValue, slot, {...state})
            return {...state};
        }
        case 'delete slot': {
            const {startDate} = action.payload;
            return {
                ...state,
                timeSlotArr: state.timeSlotArr.filter(s => (new Date(s.startDate)).getTime() !== (new Date(startDate)).getTime())
            };
        }
        case 'change week day': {
            const {startDate, weekDay} = action.payload;
            const {timeSlotArr} = state;
            const slot = findSlot(timeSlotArr, startDate);
            let {weekDaySelect} = slot;
            slot.weekDaySelect = weekDaySelect.includes(weekDay)
                ? weekDaySelect.filter(d => d !== weekDay) : [weekDay, ...weekDaySelect];
            return {
                ...state
            }
        }
    }
};

export default function BookableItemSingleDayHours(props) {
    const {itemId, adminFlag, onSubmitEdit, newItemIdForAdding, increment} = props;
    const [{timeSlotArr}, dispatch] = useReducer(reducer, initialState, init);
    const reduxDispatch = useDispatch();
    const history = useHistory();

    const onChangeWeekday = (weekDay, startDate, checked) => {
        dispatch({
            type: 'change week day',
            payload: {weekDay, startDate, checked}
        })
    }

    const onChangeDateTime = (type, newValue, startDate, slots) => {
        // validation
        if (type === 'change start date' || type === 'change start date') {
            if (!newValue || !checkDateValid(newValue)) {
                return;
            }
            //check if dates overlap
            if (type === 'change start date' && checkOverLap('start', newValue, slots, startDate)) {
                return;
            } else if (type === 'change end date' && checkOverLap('end', newValue, slots, startDate)) {
                return;
            }
        }

        dispatch({
            type,
            payload: {newValue, startDate}
        })
    }

    const constructPayload = arr => arr.map(s => ({
        startDateStr: `${s.startDate.getFullYear()}-${s.startDate.getMonth() + 1}-${s.startDate.getDate()}`,
        endDateStr: `${s.endDate.getFullYear()}-${s.endDate.getMonth() + 1}-${s.endDate.getDate()}`,
        startTimeStr: `${s.startTime.getHours()}`,
        endTimeStr: `${s.endTime.getHours()}`,
        weekDaySelect: s.weekDaySelect,
    }));

    const onError = (err, val) => {
        console.error(err, val, typeof val);
    }

    useEffect(() => {
        axios.post(`/api/singleDates/singleDateAvail`, {
            id: itemId, adminFlag
        }).then(({data: resArr}) => dispatch(
            {
                type: 'update from server', payload: resArr.map(item => ({
                    startDate: checkBrowserIsFirefox() ? handleTimeZoneOffset(new Date(item.startDate)) : mapDateToString(item.startDate),
                    endDate: checkBrowserIsFirefox() ? handleTimeZoneOffset(new Date(item.endDate))  :  mapDateToString(item.endDate),
                    startTime: setHours(item.timeSlots[0].time, item.startDate),
                    endTime: setHours(item.timeSlots[item.timeSlots.length - 1].time, item.startDate),
                    weekDaySelect: item.weekdays,
                    slots: item.timeSlots
                }))
            }
        )).catch(e => console.error(e));
    }, []);

    // todo: fix this, fix delete, delete only occur when user saved, handle delete when updating
    useEffect(async () => {
        if (!onSubmitEdit) return;
        if (timeSlotArr?.some(ts => ts.slots)) {
            for (const slotToDelete of timeSlotArr.filter(ts => ts.slots)) {
                const mapDateToStr = d => `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
                await axios.delete(`/api/singleDates/singleDateAvail/deleteBeforeSave`, {
                    data: {
                        startDate: typeof slotToDelete.startDate === 'string' ? slotToDelete.startDate : mapDateToStr(new Date(slotToDelete.startDate)),
                        endDate: typeof slotToDelete.endDate === 'string' ? slotToDelete.endDate : mapDateToStr(new Date(slotToDelete.endDate)),
                        itemId
                    }
                }).catch(e => console.error(e));
            }
        }
        const timeSlotMapper = arr => arr.map(ts => ({
            ...ts,
            startDate: typeof ts.startDate === 'string' ? new Date(ts.startDate) : ts.startDate,
            endDate: typeof ts.endDate === 'string' ? new Date(ts.endDate) : ts.endDate
        }))
        axios.post(`/api/singleDates/addSingleDates`, {
            dates: constructPayload(timeSlotMapper(timeSlotArr)),
            itemId,
            increment
        }).then(() => history.push(`/user`)).catch(e => console.error(e));
    }, [onSubmitEdit]);

    useEffect(() => {
        if (!newItemIdForAdding) return;
        axios.post(`/api/singleDates/addSingleDates`, {
            dates: constructPayload(timeSlotArr),
            itemId: newItemIdForAdding,
            increment
        }).then(() => reduxDispatch({ type: 'GOT_NEW_ITEM_ID' })).catch(e => console.error(e));
    }, [newItemIdForAdding]);

    // todo: delete has to be done after user saving
    const handleDeleteSlot = async startDate => {
        try {
            await axios.delete(`/api/singleDates/singleDateAvail`, {
                data: {
                    itemId,
                    dates: findSlot(timeSlotArr, startDate)
                }
            });
            dispatch({type: 'delete slot', payload: {startDate}});
        } catch (e) {
            console.error(e);
        }
    }

    const renderCheckBoxList = (weekDay, weekDaySelect, startDate) => (
        <FormControlLabel
            value={weekDay}
            key={weekDay}
            control={<Checkbox checked={weekDaySelect?.includes(weekDay) ?? false}/>}
            label={weekDay}
            onChange={(e, checked  ) => onChangeWeekday(weekDay, startDate, checked)}
            labelPlacement="start"
        />
    );

    const singleGrid = dateObj => (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} justifyContent="center" align="center">
                        <Button variant="contained" size="small"
                                style={{margin: '5px', marginTop: '15px'}}
                                onClick={() => handleDeleteSlot(dateObj.startDate)}>
                            Delete Slot
                        </Button>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <DatePicker
                            label="Start Date"
                            value={dateObj.startDate}
                            onChange={newValue =>
                                onChangeDateTime('change start date', newValue, dateObj.startDate, timeSlotArr)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            label="End Date"
                            value={dateObj.endDate}
                            onError={onError}
                            onChange={newValue =>
                                onChangeDateTime('change end date', newValue, dateObj.startDate, timeSlotArr)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid item xs={6} align="right">
                        <TimePicker
                            label="Start Time"
                            value={dateObj.startTime}
                            onChange={newValue => onChangeDateTime('change start time', newValue, dateObj.startDate)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimePicker
                            label="End Time"
                            value={dateObj.endTime}
                            onChange={newValue => onChangeDateTime('change end time', newValue, dateObj.startDate)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>

                </Grid>
            </LocalizationProvider>
            <Grid container spacing={2} justifyContent="center" marginTop={5}>
                <div>
                    <span>Repeating:</span>
                </div>
                <Grid item>
                    {Object.values(weekDayDict).map(wd => renderCheckBoxList(wd, dateObj.weekDaySelect, dateObj.startDate))}
                </Grid>
            </Grid>
        </div>
    );

    return (
        <>
            {timeSlotArr.map(singleGrid)}
            <Button variant="contained" size="small"
                    style={{margin: '5px', marginTop: '15px'}}
                    onClick={() => dispatch({type: 'add more'})}
            >
                Add More
            </Button>
        </>
    )
}
