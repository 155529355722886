import axios from 'axios';

// Send confirmation email to Renter on a multi-day bookable item.
export function multiDayEmailToRenter(
  renterFirstName,
  renterLastName,
  renterEmail,
  clientCompanyName,
  clientPhone,
  clientEmail,
  bookableItemTitle,
  startDate,
  endDate,
  confirmationNumber,
  total,
  payInFull, 
  dtt,
  dlt 
  ) {
  const phone = clientPhone ? clientPhone : '';
  // Put the parentheses and dash in the phone number.
  const formatedPhone = "(" + phone.slice(0, 3) + ") " + phone.slice(3, 6) + "-" + phone.slice(6);

  // Convert total charged amount to US currency format. The value of "total" is in "cents".
  // Therefore, need to divide by 100 before formatting.
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  let formatedTotal = USDollar.format(total / 100);

  let dueToday

  let dueLater

  if (dtt) { dueToday = USDollar.format(dtt / 100) }

  if (dlt) { dueLater = USDollar.format(dlt / 100) }

  if(payInFull === false) {
    const response = axios.post('/api/send-email', {
      to: renterEmail,
      bcc: null,
      subject: `${clientCompanyName} Booking Confirmation`,
      html: `
    <p>Hello ${renterFirstName}:</p>
    <p>Thank you for booking with ${clientCompanyName}!</p>
    <p style="font-weight: bold;text-decoration: underline;">Booking Information</p>
    <p><b>Confirmation Number:</b> ${confirmationNumber}</p>
    <p><b>Booked Item:</b> ${bookableItemTitle}</p>
    <p><b>Date(s):</b> ${startDate} to ${endDate}</p>
    <p><b>Amount Charged:</b> ${dueToday}</p>
    <p><b>Amount Due Later:</b> ${dueLater}</p>
    <p>If you have any questions, please contact ${clientCompanyName} at ${clientEmail} or ${formatedPhone}.</p>
    <p>Powered by EazieBook Inc.</p>
    `
    });
  }

  if (payInFull === true) {
    const response = axios.post('/api/send-email', {
      to: renterEmail,
      bcc: null,
      subject: `${clientCompanyName} Booking Confirmation`,
      html: `
    <p>Hello ${renterFirstName}:</p>
    <p>Thank you for booking with ${clientCompanyName}!</p>
    <p style="font-weight: bold;text-decoration: underline;">Booking Information</p>
    <p><b>Confirmation Number:</b> ${confirmationNumber}</p>
    <p><b>Booked Item:</b> ${bookableItemTitle}</p>
    <p><b>Date(s):</b> ${startDate} to ${endDate}</p>
    <p><b>Amount Charged:</b> ${formatedTotal}</p>
    <p>If you have any questions, please contact ${clientCompanyName} at ${clientEmail} or ${formatedPhone}.</p>
    <p>Powered by EazieBook Inc.</p>
    `
    });
  }
  

  
}

// Send confirmation email to Client on a multi-day bookable item.
export function multiDayEmailToClient(
  renterFirstName,
  renterLastName,
  renterPhone,
  renterEmail,
  clientEmail,
  bookableItemTitle,
  startDate,
  endDate,
  confirmationNumber,
  total,
  payInFull, 
  dtt,
  dlt) {
  const phone = renterPhone ? renterPhone : ''
  // Put the parentheses and dash in the phone number.
  const formatedPhone = "(" + phone.slice(0, 3) + ") " + phone.slice(3, 6) + "-" + phone.slice(6);

  // Convert total charged amount to US currency format. The value of "total" is in "cents".
  // Therefore, need to divide by 100 before formatting.
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  let formatedTotal = USDollar.format(total / 100);

  let dueToday

  let dueLater

  if (dtt) { dueToday = USDollar.format(dtt / 100) }

  if (dlt) { dueLater = USDollar.format(dlt / 100) }

  if (payInFull === true) {
    const response = axios.post('/api/send-email', {
      to: clientEmail,
      bcc: null,
      subject: `New Booking for ${bookableItemTitle} by ${renterFirstName} ${renterLastName}`,
      html: `
    <p>You have a new booking!</p>
    <p style="font-weight: bold;text-decoration: underline;">Booking Information</p>
    <p><b>Confirmation Number:</b> ${confirmationNumber}</p>
    <p><b>Booked Item:</b> ${bookableItemTitle}</p>
    <p><b>Date(s):</b> ${startDate} to ${endDate}</p>
    <p><b>Amount Charged:</b> ${formatedTotal}</p>
    <p style="font-weight: bold;text-decoration: underline;">Customer Information</p>
    <p><b>First Name:</b> ${renterFirstName}</p>
    <p><b>Last Name:</b> ${renterLastName}</p>
    <p><b>Phone:</b> ${formatedPhone}</p>
    <p><b>Email:</b> ${renterEmail}</p>
    `
    }); 
  }

  if (payInFull === false) {
    const response = axios.post('/api/send-email', {
      to: clientEmail,
      bcc: null,
      subject: `New Booking for ${bookableItemTitle} by ${renterFirstName} ${renterLastName}`,
      html: `
    <p>You have a new booking!</p>
    <p style="font-weight: bold;text-decoration: underline;">Booking Information</p>
    <p><b>Confirmation Number:</b> ${confirmationNumber}</p>
    <p><b>Booked Item:</b> ${bookableItemTitle}</p>
    <p><b>Date(s):</b> ${startDate} to ${endDate}</p>
    <p><b>Amount Charged:</b> ${dueToday}</p>
    <p><b>Amount Due Later:</b> ${dueLater}</p>
    <p style="font-weight: bold;text-decoration: underline;">Customer Information</p>
    <p><b>First Name:</b> ${renterFirstName}</p>
    <p><b>Last Name:</b> ${renterLastName}</p>
    <p><b>Phone:</b> ${formatedPhone}</p>
    <p><b>Email:</b> ${renterEmail}</p>
    `
    });
  }
  

  }

// Send confirmation email to Renter on an hourly bookable item.
export function hourlyEmailToRenter(
  renterFirstName,
  renterLastName,
  renterEmail,
  clientCompanyName,
  clientPhone,
  clientEmail,
  bookableItemTitle,
  date,
  startTime,
  endTime,
  confirmationNumber,
  total,
  payInFull, 
  dtt,
  dlt) {
  const phone = clientPhone ? clientPhone : '';
  // Put the parentheses and dash in the phone number.
  const formatedPhone = "(" + phone.slice(0, 3) + ") " + phone.slice(3, 6) + "-" + phone.slice(6);

  // Convert start and end times from 24-hour format to 12-hour format.
  // The date (month, day, year) does not matter for the calculations.
  const startDate = new Date("1970-01-01T" + startTime + "Z");
  const startTime12Hour = startDate.toLocaleTimeString("en-US", {timeZone: "UTC", hour12: true, hour: 'numeric', minute: 'numeric'});
  const endDate = new Date("1970-01-01T" + endTime + "Z");
  const endTime12Hour = endDate.toLocaleTimeString("en-US", {timeZone: "UTC", hour12: true, hour: 'numeric', minute: 'numeric'});

  // Convert total charged amount to US currency format. The value of "total" is in "cents".
  // Therefore, need to divide by 100 before formatting.
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  let formatedTotal = USDollar.format(total / 100);

  let dueToday

  let dueLater

  if (dtt) { dueToday = USDollar.format(dtt / 100) }

  if (dlt) { dueLater = USDollar.format(dlt / 100) }

  if (payInFull === true) {
    // Send the email.
    const response = axios.post('/api/send-email', {
      to: renterEmail,
      bcc: null,
      subject: `${clientCompanyName} Booking Confirmation`,
      html: `
    <p>Hello ${renterFirstName}:</p>
    <p>Thank you for booking with ${clientCompanyName}!</p>
    <p style="font-weight: bold;text-decoration: underline;">Booking Information</p>
    <p><b>Confirmation Number:</b> ${confirmationNumber}</p>
    <p><b>Booked Item:</b> ${bookableItemTitle}</p>
    <p><b>Date:</b> ${date}</p>
    <p><b>Time:</b> ${startTime12Hour} to ${endTime12Hour}</p>
    <p><b>Amount Charged:</b> ${formatedTotal}</p>
    <p>If you have any questions, please contact ${clientCompanyName} at ${clientEmail} or ${formatedPhone}.</p>
    <p>Powered by EazieBook Inc.</p>
    `
    });
  }

  if (payInFull === false) {
    // Send the email.
    const response = axios.post('/api/send-email', {
      to: renterEmail,
      bcc: null,
      subject: `${clientCompanyName} Booking Confirmation`,
      html: `
    <p>Hello ${renterFirstName}:</p>
    <p>Thank you for booking with ${clientCompanyName}!</p>
    <p style="font-weight: bold;text-decoration: underline;">Booking Information</p>
    <p><b>Confirmation Number:</b> ${confirmationNumber}</p>
    <p><b>Booked Item:</b> ${bookableItemTitle}</p>
    <p><b>Date:</b> ${date}</p>
    <p><b>Time:</b> ${startTime12Hour} to ${endTime12Hour}</p>
    <p><b>Amount Charged:</b> ${dueToday}</p>
    <p><b>Amount Due Later:</b> ${dueLater}</p>
    <p>If you have any questions, please contact ${clientCompanyName} at ${clientEmail} or ${formatedPhone}.</p>
    <p>Powered by EazieBook Inc.</p>
    `
    });
  }
  
}

// Send confirmation email to Client on an hourly bookable item.
export function hourlyEmailToClient(
  renterFirstName,
  renterLastName,
  renterPhone,
  renterEmail,
  clientEmail,
  bookableItemTitle,
  date,
  startTime,
  endTime,
  confirmationNumber,
  total,
  payInFull, 
  dtt,
  dlt) {

  const phone = renterPhone ? renterPhone : ''
  // Put the parentheses and dash in the phone number.
  const formatedPhone = "(" + phone.slice(0, 3) + ") " + phone.slice(3, 6) + "-" + phone.slice(6);

  // Convert start and end times from 24-hour format to 12-hour format.
  // The date (month, day, year) does not matter for the calculations.
  const startDate = new Date("1970-01-01T" + startTime + "Z");
  const startTime12Hour = startDate.toLocaleTimeString("en-US", {timeZone: "UTC", hour12: true, hour: 'numeric', minute: 'numeric'});
  const endDate = new Date("1970-01-01T" + endTime + "Z");
  const endTime12Hour = endDate.toLocaleTimeString("en-US", {timeZone: "UTC", hour12: true, hour: 'numeric', minute: 'numeric'});

  // Convert total charged amount to US currency format. The value of "total" is in "cents".
  // Therefore, need to divide by 100 before formatting.
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  let formatedTotal = USDollar.format(total / 100);

  let dueToday

  let dueLater

  if (dtt) { dueToday = USDollar.format(dtt / 100) }

  if (dlt) { dueLater = USDollar.format(dlt / 100) }

  if (payInFull === true) {
    const response = axios.post('/api/send-email', {
      to: clientEmail,
      bcc: null,
      subject: `New Booking for ${bookableItemTitle} by ${renterFirstName} ${renterLastName}`,
      html: `
    <p>You have a new booking!</p>
    <p style="font-weight: bold;text-decoration: underline;">Booking Information</p>
    <p><b>Confirmation Number:</b> ${confirmationNumber}</p>
    <p><b>Booked Item:</b> ${bookableItemTitle}</p>
    <p><b>Date:</b> ${date}</p>
    <p><b>Time:</b> ${startTime12Hour} to ${endTime12Hour}</p>
    <p><b>Amount Charged:</b> ${formatedTotal}</p>
    <p style="font-weight: bold;text-decoration: underline;">Customer Information</p>
    <p><b>First Name:</b> ${renterFirstName}</p>
    <p><b>Last Name:</b> ${renterLastName}</p>
    <p><b>Phone:</b> ${formatedPhone}</p>
    <p><b>Email:</b> ${renterEmail}</p>
    `
    }); 
  }

  if (payInFull === false) {
    const response = axios.post('/api/send-email', {
      to: clientEmail,
      bcc: null,
      subject: `New Booking for ${bookableItemTitle} by ${renterFirstName} ${renterLastName}`,
      html: `
    <p>You have a new booking!</p>
    <p style="font-weight: bold;text-decoration: underline;">Booking Information</p>
    <p><b>Confirmation Number:</b> ${confirmationNumber}</p>
    <p><b>Booked Item:</b> ${bookableItemTitle}</p>
    <p><b>Date:</b> ${date}</p>
    <p><b>Time:</b> ${startTime12Hour} to ${endTime12Hour}</p>
    <p><b>Amount Charged:</b> ${dueToday}</p>
    <p><b>Amount Due Later:</b> ${dueLater}</p>
    <p style="font-weight: bold;text-decoration: underline;">Customer Information</p>
    <p><b>First Name:</b> ${renterFirstName}</p>
    <p><b>Last Name:</b> ${renterLastName}</p>
    <p><b>Phone:</b> ${formatedPhone}</p>
    <p><b>Email:</b> ${renterEmail}</p>
    `
    }); 
  }
  

  }