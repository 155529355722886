const selectedBookableItem = (state = {
    availabilityyy: {masterList: [], mapList: []},
    tax: [],
    discount: [],
    add_ons: [],
    damage_deposit: false,
    itemLoading: true
}, action) => {
    switch (action.type) {
        case 'SET_SELECTED_BOOKABLE_ITEM':
            console.log(state);
            return {
                ...state,
                ...action.payload,
                itemLoading: false
            };
        case 'EDIT_FORM_ONCHANGE':
            return { ...state, [action.payload.property]: action.payload.value }
        case 'SET_AVAILABILITY_TO_ITEM':
            return {
                ...state,
                availability: action.payload
            }
        case 'SET_AVAILABLE_HOURS':
            return {
                ...state,
                availableHours: action.payload
            }
        case 'SET_RENTED_DATE_TO_ITEM':
            return {
                ...state,
                datesBooked: action.payload
            }
        case 'SET_REPEATING_WEEKDAY_TO_ITEM':
            return {
                ...state,
                repeatingWeekDay: action.payload
            }
        case 'SET_BLOCKED_TIME_TO_ITEM':
            return {
                ...state,
                blockedTime: action.payload
            }
        case 'TRIGGER_UPDATE_DATE':
            return {
                ...state,
                onSubmitEdit: true
            }
    }
    return state;
};

export default selectedBookableItem;
