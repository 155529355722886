import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

// worker Saga: will be fired on "ADD_CONTACT" actions
function* addContact(action) {
  try {
    // TODO: clear any existing error on the contact page
    // yield put({ type: 'CLEAR_ADD_CONTACT_ERROR' });

    // passes the data from the payload to the server
    yield axios.post('/api/contact', action.payload);

    // automatically log a user in after registration
    // yield put({ type: 'LOGIN', payload: action.payload });

    // TODO: redirect after submit ... yield put({ type: '.....' });
  } catch (error) {
    console.log('Error with submitting Contact Us form:', error);
    // TODO: yield put({ type: 'ADD_CONTACT_FAILED' });
  }
}

function* addSignUp(action) {
  try {
    yield axios.post('/api/contact/signUp', action.payload)

  } catch (error) {
    console.log('contactSaga error', error);
    
  }

}

function* addContactSaga() {
  yield takeLatest('ADD_CONTACT', addContact);
  yield takeLatest('ADD_SIGNUP', addSignUp)
}

export default addContactSaga;
