import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import moment from "moment/moment";
import {formatDate, getTimeFromDate} from "../Utils/share-functions";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ThankYou() {
  const query = useQuery();
  const dispatch = useDispatch();
  const item = query.get('item');
  useEffect(() => {
    dispatch({
      type: 'FETCH_SELECTED_BOOKABLE_ITEM',
      payload: item
    });
  }, [item]);

  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const date = query.get('date');
  const startHour = query.get('startHour');
  const endHour = query.get('endHour');
  const confirmation = query.get('confirmation-number');
  const bookableItem = useSelector((store) => store.selectedBookableItem);
  const custom = query.get('custom') || '',  customDuration = query.get('customDuration') || '',
      customComment = query.get('customComment') || '', customTimestamp = query.get('customDate') || '';

  const customDate = new Date(Number(customTimestamp));
  const customStartTime = moment(getTimeFromDate(customDate), ['hh:mm:ss']).format('hh:mm A');
  const customEndTime = moment(getTimeFromDate(
      new Date(Number(customTimestamp) + Number(customDuration || 0) * 60 * 60 * 1000)), ['hh:mm:ss'])
      .format('hh:mm A');

  const [formattedPhone, setFormattedPhone] = useState('');
  useEffect(() => {
    setFormattedPhone(bookableItem && bookableItem.phone_number_1 ? ' or ('
        + bookableItem.phone_number_1.slice(0, 3) + ') ' + bookableItem?.phone_number_1?.slice(3, 6) + '-'
        + bookableItem?.phone_number_1?.slice(6): '');
  }, [bookableItem?.phone_number_1])
  // Convert start and end times from 24-hour format to 12-hour format.
  // The date (month, day, year) does not matter for the following calculations.
  const hourlyStartDate = new Date("1970-01-01T" + startHour + "Z");
  const startTime12Hour = hourlyStartDate.toLocaleTimeString("en-US", { timeZone: "UTC", hour12: true, hour: 'numeric', minute: 'numeric' });
  const hourlyEndDate = new Date("1970-01-01T" + endHour + "Z");
  const endTime12Hour = hourlyEndDate.toLocaleTimeString("en-US", { timeZone: "UTC", hour12: true, hour: 'numeric', minute: 'numeric' });
  if (!bookableItem.company_name) return null;
  return <>
    <Container align="center" maxWidth="lg">
      <Box marginTop={2} marginBottom={2}>
        <Typography variant='h5'>
          Thank you for booking with {bookableItem.company_name}!
        </Typography>
      </Box>
    </Container>
    <Container maxWidth="sm">
      <Grid container>
        <Grid>
          <Typography>
            <Box marginBottom={2}>
              A confirmation email has been sent to you.
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            <b>Confirmation Number:</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            {confirmation}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            <b>Booked Item:</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            {bookableItem.title}
          </Typography>
        </Grid>
        {bookableItem.duration_type === 'singleDay' &&
          <>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Date:</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {custom === 'true' ?
                  <Typography>
                    {formatDate(customDate)}
                  </Typography>
                  :
                  <Typography>
                    {date}
                  </Typography>
              }

            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Time:</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {custom === 'true' ?
                  <Typography>
                    {customStartTime} to {customEndTime}
                  </Typography>
                  :
                  <Typography>
                    {startTime12Hour} to {endTime12Hour}
                  </Typography>
              }
            </Grid>
            {custom === 'true' ?
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <b>Comment:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      {customComment}
                    </Typography>
                  </Grid>
                </>
                : null}
          </>
        }
        {(bookableItem.duration_type === 'multiDay' || bookableItem.duration_type === 'multiNight') &&
          <>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Date(s):</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                {startDate} to {endDate}
              </Typography>
            </Grid>
          </>
        }
      </Grid>
    </Container>
    <Container maxWidth="sm">
      <Box marginTop={2}>
        <Typography>
          <Box sx={{ fontWeight: 'bold' }}>
            If you have any questions, please contact {bookableItem.company_name} at {bookableItem.email} {formattedPhone}.
          </Box>
        </Typography>
      </Box>
    </Container>
  </>
}