import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Select, MenuItem, InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './InviteClient.css';
import {timeZoneSelection} from "./TimeZone";
import passwordCheck from '../../helpers/password.helper';


function InviteClient() {
    const history = useHistory();
    const dispatch = useDispatch();
    const clientInvitation = useSelector(store => store.clientInvite);

    const [invitePending, setInvitePending] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber1, setPhoneNumber1] = useState('');
    const [phoneNumber2, setPhoneNumber2] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [companyAddress1, setCompanyAddress1] = useState('');
    const [companyAddress2, setCompanyAddress2] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [companyState, setCompanyState] = useState('');
    const [companyZipCode, setCompanyZipCode] = useState('');
    const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState('');
    const [cancellationPolicy, setCancellationPolicy] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankAddress1, setBankAddress1] = useState('');
    const [bankAddress2, setBankAddress2] = useState('');
    const [bankCity, setBankCity] = useState('');
    const [bankState, setBankState] = useState('');
    const [bankZipCode, setBankZipCode] = useState('');
    const [bankRoutingNumber, setBankRoutingNumber] = useState('');
    const [bankCheckingNumber, setBankCheckingNumber] = useState('');
    const [demo, setDemo] = useState(false);
    const [allowSms, setAllowSms] = useState(false);
    const [requireInquiry, setRequireInquiry] = useState(false);

    //TODO make use effect for invite saga to toggle invitePending
    useEffect(() => {
        setInvitePending(false)
    }, [clientInvitation])

    const inviteClient = async (event) => {
        event.preventDefault();
        console.log(requireInquiry);
        if(!passwordCheck(password)){
            alert('Password not strong enough. Must contain, one uppercase letter, one lowercase, one number, one special character, and at least 8 characters long.');
            return;
        }
        setInvitePending(true);
        /* data being sent to inviteClient.saga.js */
        dispatch({
            type: 'INVITE_CLIENT',
            payload: {
                username: username,
                password,
                demo,
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                email: email,
                phoneNumber1: phoneNumber1,
                phoneNumber2: phoneNumber2,
                companyName: companyName,
                companyAddress1: companyAddress1,
                companyAddress2: companyAddress2,
                companyCity: companyCity,
                companyState: companyState,
                companyZipCode: companyZipCode,
                companyWebsiteUrl: companyWebsiteUrl,
                cancellationPolicy: cancellationPolicy,
                bankName: bankName,
                bankAddress1: bankAddress1,
                bankAddress2: bankAddress2,
                bankCity: bankCity,
                bankState: bankState,
                bankZipCode: bankZipCode,
                bankRoutingNumber: bankRoutingNumber,
                bankCheckingNumber,
                timeZone,
                allowSms: allowSms,
                requireInquiry: requireInquiry,
            }
        });
        setInvitePending(false);
    };

    const goBack = () => {
        history.push("/user");
    };

    const goDashboard = () => {
        history.push('/');
        dispatch({
            type: 'SET_CLIENT_INVITE',
            payload: false,
        })
    };

    return (
        <>
            <FormGroup className="clientView">
                <form className="formPanel" onSubmit={inviteClient}>
                    <Typography component="h2" variant="h4" align="center" mt={2}>
                        Invite Client
                    </Typography>
                    <Grid container maxWidth="sm" mx="auto" direction="column" my={4}>
                        <Typography component="h3" variant="h5" align="left" mt={2}>
                            Account Information
                        </Typography>
                        <FormControlLabel control={<Checkbox onChange={() => setDemo(!demo)} />} label="Demo Client" />
                        <FormControlLabel control={<Checkbox onChange={() => setAllowSms(!allowSms)} />} label="Allow Text Messaging" />
                        <FormControlLabel control={<Checkbox onChange={() => setRequireInquiry(!requireInquiry)} />} label="Require Inquiry Before Booking" />
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Username"
                                    type="text"
                                    name="username"
                                    value={username}
                                    required
                                    onChange={(event) => setUsername(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Password"
                                    type="password"
                                    name="password"
                                    value={password}
                                    required
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Typography component="h3" variant="h5" align="left" mt={2}>
                            Personal Information
                        </Typography>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="First Name"
                                    type="text"
                                    name="firstName"
                                    value={firstName}
                                    required
                                    onChange={(event) => setFirstName(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Middle Name"
                                    type="text"
                                    name="middleName"
                                    value={middleName}
                                    onChange={(event) => setMiddleName(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Last Name"
                                    type="text"
                                    name="lastName"
                                    value={lastName}
                                    required
                                    onChange={(event) => setLastName(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Email"
                                    type="text"
                                    name="email"
                                    value={email}
                                    required
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Phone 1"
                                    type="text"
                                    name="phoneNumber"
                                    value={phoneNumber1}
                                    required
                                    onChange={(event) => setPhoneNumber1(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Phone 2"
                                    type="text"
                                    name="phoneNumber"
                                    value={phoneNumber2}
                                    onChange={(event) => setPhoneNumber2(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Typography component="h3" variant="h5" align="left" mt={2}>
                            Company Information
                        </Typography>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Company Name"
                                    type="text"
                                    name="companyName"
                                    value={companyName}
                                    required
                                    onChange={(event) => setCompanyName(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    name="companyAddress1"
                                    // variant="standard"
                                    label="Address Line 1"
                                    value={companyAddress1}
                                    required
                                    onChange={(event) => setCompanyAddress1(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl margin="normal" fullWidth>
                                <TextField
                                    name="companyAddress2"
                                    // variant="standard"
                                    label="Address Line 2"
                                    value={companyAddress2}

                                    onChange={(event) => setCompanyAddress2(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item container spacing={2}>
                            <Grid item sm={4}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField
                                        name="companyCity"
                                        // variant="standard"
                                        label="City"
                                        value={companyCity}
                                        required
                                        onChange={(event) => setCompanyCity(event.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={4}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField
                                        name="companyState"
                                        // variant="standard"
                                        label="State"
                                        value={companyState}
                                        required
                                        onChange={(event) => setCompanyState(event.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={4}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField
                                        name="companyZipCode"
                                        // variant="standard"
                                        label="Zip"
                                        value={companyZipCode}
                                        required
                                        onChange={(event) => setCompanyZipCode(event.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Website URL"
                                    type="text"
                                    name="companyWebsiteUrl"
                                    value={companyWebsiteUrl}
                                    onChange={(event) => setCompanyWebsiteUrl(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel >Cancellation Policy</InputLabel>
                                <Select
                                    label="cancellation policy"
                                    value={cancellationPolicy}
                                    onChange={(e) => setCancellationPolicy(e.target.value)}
                                    required
                                >
                                    <MenuItem value={'strict'}>Strict</MenuItem>
                                    <MenuItem value={'flexible'}>Flexible</MenuItem>
                                    <MenuItem value={'moderate'}>Moderate</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel >Time Zone</InputLabel>
                                <Select
                                    label="time zone"
                                    value={timeZone}
                                    onChange={(e) => setTimeZone(e.target.value)}
                                    required
                                >
                                    {Object.entries(timeZoneSelection).map(([key, val]) =>
                                        (<MenuItem value={key}>{val}</MenuItem>)
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item style={{ textAlign: "center" }}>
                            <FormControl sx={{ width: 0.75 }} margin="normal">
                                <Button disabled={invitePending} onClick={inviteClient} type="submit" variant="contained" size="large">
                                    {invitePending ?  'One Moment' : 'Submit'}
                                </Button>
                            </FormControl>
                        </Grid>

                        <Grid item style={{ textAlign: "center" }}>
                            <FormControl sx={{ width: 0.25 }} margin="normal">
                                <Button variant="outlined" onClick={goBack} size="large">Back</Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
                <Dialog open={clientInvitation} onClose={goDashboard}>
                    <DialogTitle>Invite Client</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Client invitation successful!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={goDashboard}>Dashboard</Button>
                    </DialogActions>
                </Dialog>

            </FormGroup>
        </>
    )
}

export default InviteClient;
