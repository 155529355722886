import axios from 'axios';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';

function* clientSaga() {
    yield takeEvery('DELETE_SELECTED_CLIENT', deleteClient);
    yield takeEvery('FETCH_CLIENTS', getClients);
    yield takeLatest('INVITE_CLIENT', setClientInvite);
    yield takeEvery('UPDATE_CLIENT', updateClient);
    yield takeEvery('FETCH_ACTIVE_CLIENT', fetchActiveClient)
}


function* fetchActiveClient(action) {
    const response = yield axios.get(`/clients/${action.payload}`);
    yield put({
        type: 'SELECTED_CLIENT', 
        payload: response.data
    })
}

function* updateClient(action) { 
    try {

        // TODO: turn these into one server call
        yield axios.put(`/clients/${action.payload.id}`, action.payload);
        yield put({
            type: "FETCH_CLIENTS"
        })
    }
    catch (error) {
        console.error('ERROR in updateClient.saga', error);
    }

}

function* getClients() {
    try {
        const response = yield axios.get('/clients')

        yield put({
            type: 'SET_CLIENTS',
            payload: response.data
        })
    }
    catch (error) {
        console.error('ERROR getting data in fetchClients', error);
    }
}

function* setClientInvite(action) {
    try {
        /* sending the payload to clientInvite.router */
        yield axios.post('/admin/invite', action.payload);
        // yield axios.post(`/admin/invite/cancellation/${response.data[0].id}`, action.payload);
        // yield axios.post(`/api/tax/${response.data[0].id}`, action.payload)
        /* sending the response of the router to inviteClient.reducer */
        yield put({ type: 'SET_CLIENT_INVITE', payload: true })
    }
    catch (error) {
        console.error('ERROR in posting client invite data', error);
        //TODO add error messages
        yield put({ type: 'SET_CLIENT_INVITE', payload: false })
    }
}

function* deleteClient(action) {
    try {
        yield axios.delete(`/clients/${action.payload.id}`);

        yield put({
            type: 'FETCH_DATA'
        })
    }
    catch (err) {
        console.error('DELETE client failed', err);
    }
}



export default clientSaga