import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";

// Worker Saga: fetch all contacts.
function* fetchContact() {
  const response = yield axios.get('/api/contact');
  yield put({type: 'FETCH_CONTACT_SUCCESS', payload: response.data});
}

// Watcher Saga: spawn a new fetchContact task on each FETCH_CONTACT.
function* watchFetchContact() {
  yield takeEvery('FETCH_CONTACT', fetchContact);
}

export default watchFetchContact;