import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {CalendarPicker} from "@mui/x-date-pickers/CalendarPicker";
import ListItemButton from "@mui/material/ListItemButton";
import {PickersDay} from '@mui/x-date-pickers/PickersDay';
import {Typography} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button} from "@material-ui/core";
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";
import {dateHelper, getDateType} from "../Utils/share-functions";

function BlockCalendar() {
    const {id: bookableItemId} = useParams();
    const [availDates, setAvailDates] = useState([]);
    const [rentedDates, setRentedDates] = useState([]);
    const [blockedDates, setBlockedDates] = useState([]);
    const [instances, setInstances] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [itemName, setItemName] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const checkRented = date => rentedDates?.some(dateHelper(date));
    const checkBlocked = date => blockedDates?.some(dateHelper(date));
    const checkDisable = date => !availDates?.some(dateHelper(date));
    const handleDatesFromResp = datesArr => datesArr.map(({date_available}) => getDateType(date_available));

    const warningText = () => {
        if (selectedDate && checkBlocked(selectedDate)) return 'Are you sure you want to unblock this date?'
        if (selectedDate && checkRented(selectedDate))
            return 'This date is already booked, please contact clients after Blocking. Are you sure you want to block this date from reserving?';
        return 'Are you sure you want to block this date from reserving?';
    }

    const successText = () => {
        if (!selectedDate) return 'Success';
        if (checkBlocked(selectedDate)) {
            let str = 'Block Successfully.'
            if (checkRented(selectedDate)) {
                return str + ' Please Contact Client to Cancel.'
            }
            return str;
        } else {
            return 'Unblock Successfully.';
        }
    }

    useEffect(async () => {
        await getAvailDates();
        getAllInstances();
    }, []);

    const getAvailDates = async () => {
        try {
            const {data: {itemAvailDates, itemRentedDates, itemBlockedDates} = {itemAvailDates: [], itemRentedDates: []}}
                = await axios.get(`/api/bookings/itemBlockDates/${bookableItemId}`);
            setAvailDates(handleDatesFromResp(itemAvailDates));
            setRentedDates(handleDatesFromResp(itemRentedDates));
            setBlockedDates(handleDatesFromResp(itemBlockedDates));
        } catch (e) {
            console.error(e);
        }
    }

    const getInstanceAvailDates = instanceId => {
        axios.get(`/api/bookings/instanceBlockDates/${instanceId}`)
            .then(resp => {
                if (!resp?.data) throw 'Cannot get response from Server';
                const {instanceAvail = [], instanceRented = [], instanceBlocked = []} = resp.data;
                setAvailDates(handleDatesFromResp(instanceAvail));
                setRentedDates(handleDatesFromResp(instanceRented));
                setBlockedDates(handleDatesFromResp(instanceBlocked));
            })
            .catch(err => console.error(err));
    }
    const getAllInstances = () => {
        axios.get(`/api/bookableItem/selected/${bookableItemId}`)
            .then(resp => {
                if (!resp?.data) throw 'Cannot get response from Server';
                const {instances, title} = resp.data
                if (instances) {
                    setInstances(instances);
                }
                if (title) {
                    setItemName(title);
                }
            })
            .catch(err => console.error(err));
    }

    const handleListItemClick = (index, instance) => () => {
        if (index === selectedIndex) return;
        setSelectedIndex(index);
        if (instance && instance.id) {
            getInstanceAvailDates(instance.id);
        } else {
            getAvailDates().then(() => null);
        }
    }

    const blockDate = () => {
        const payload = {
            isInstance: selectedIndex > 0,
            id: selectedIndex === 0 ? Number(bookableItemId) : instances[selectedIndex - 1].id,
            dateToBlock: `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`,
            blockFlag: !checkBlocked(selectedDate)
        };
        axios.post(`/api/bookings/blockDates`, payload)
            .then(async () => {
                setOpen(false);
                setSnackOpen(true);
                setTimeout(() => {
                    setSelectedDate(null);
                }, 1000);
                if (selectedIndex > 0) {
                    getInstanceAvailDates(instances[selectedIndex - 1].id)
                } else {
                    await getAvailDates();
                }

            })
            .catch(err => console.error(err));
    }

    const changeDateColor = date => {
        if (checkBlocked(date)) return {backgroundColor: 'rgb(230, 230, 0, 0.5)'};
        if (checkRented(date)) return {backgroundColor: 'rgb(255, 80, 80, 0.5)'};
        return null;
    }

    const handleCancel = () => {
        setSelectedDate(null);
        setOpen(false);
    }

    return (
        <>
            <div>
                <Grid container spacing={2} padding={5}>
                    <Grid item sm={3} xs={12}>
                        <List>
                            <ListItem>
                                <ListItemButton
                                    selected={selectedIndex === 0}
                                    onClick={handleListItemClick(0)}
                                >
                                    <ListItemText primary={`${itemName} - with all Instances`}/>
                                </ListItemButton>
                            </ListItem>
                            {instances.map((i, index) => (
                                <ListItem key={`${index}${i.name}`}>
                                    <ListItemButton
                                        selected={selectedIndex === index + 1}
                                        onClick={handleListItemClick(index + 1, i)}
                                    >
                                        <ListItemText primary={i.name}/>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                        <Typography style={{display: 'flex', width: 320, margin: '0 auto'}}>
                            Please select date to block
                        </Typography>
                        {open ? null :
                            (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <CalendarPicker
                                        date={selectedDate}
                                        onChange={date => {
                                            setOpen(true);
                                            setSelectedDate(date);
                                        }}
                                        shouldDisableDate={dates => checkDisable(dates)}
                                        openTo={'day'}
                                        views={['day']}
                                        disablePast={true}
                                        renderDay={(date, selectedDates, pickersDayProps) => (
                                            <PickersDay {...pickersDayProps} sx={
                                                changeDateColor(date)
                                            }/>
                                        )}
                                    />
                                </LocalizationProvider>
                            )
                        }
                        <Typography style={{display: 'flex', width: 320, margin: '0 auto'}}>
                            <span style={{color: 'rgb(255, 80, 80, 0.5)', marginRight: 5}}>●</span> rented
                            <span style={{color: 'rgb(230, 230, 0, 0.5)', marginRight: 5, marginLeft: 7}}>●</span> blocked
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Please Confirm"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {warningText()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={blockDate} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackOpen}
                autoHideDuration={1000}
                onClose={() => setSnackOpen(false)}
            >
                <Alert onClose={() => setSnackOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {snackOpen ? successText() : null}
                </Alert>
            </Snackbar>
        </>
    )
}

export default BlockCalendar;
