const itemRequest = (state = [], action) => {
    switch(action.type) {
        case 'SET_SELECTED_ITEM_REQUEST': 
        return action.payload
        case 'REQUEST_FORM_ONCHANGE':
        return { ...state, [action.payload.property]: action.payload.value }
    }
    return state
}

export default itemRequest;
