import React, {useState} from "react";
import {useDispatch} from 'react-redux'
import FormControl from "@mui/material/FormControl";
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const weekDay = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
}
const monthName = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
}

const checkCurrentDaySlot = (date, hours) => {
    const today = new Date();
    const getDate = d => d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate();
    const todayHour = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    if (getDate(date) === getDate(today)) {
        return hours.filter(h => Number(todayHour.slice(0, 2)) < Number(h.slice(0, 2)));
    }
    return hours;
}

const SingleDaySelectTimeSlot = ({selectedDate, hoursSelection = [], hourlyIncrement = 0.5, hoursAvail, minHour, maxHour, onHoursChange, hideDuration, onHourChangesCust, startHourCust}) => {
    const dispatch = useDispatch();
    const [hoursSelected, setHoursSelected] = useState(0);
    const [startHour, setStartHour] = useState(hideDuration ? startHourCust : null);
    const date = selectedDate.getDate(),
        year = selectedDate.getFullYear(),
        month = selectedDate.getMonth() + 1,
        day = selectedDate.getDay();

    // displaying logic
    let timeSelection = hoursSelection.map(({time}) => time);
    const getHourNumber = hourStr => Number(hourStr?.slice(0, 2) ?? '0');
    const lastHour = getHourNumber(timeSelection && timeSelection.length ? timeSelection[timeSelection.length - 1] : '00');
    const lastHourToDisplay = lastHour - minHour;
    const timeSlotDisplay = checkCurrentDaySlot(selectedDate, timeSelection.filter(time => getHourNumber(time) <= lastHourToDisplay));

    const calculateInstances = (hour) => {
        let index = 0;
        let begin = false;
        let instances = {};
        let finished = true;
        let longest = {count: 0}
        for (let item of hoursAvail) {
            const keysForInstances = Object.keys(instances);
            if (item.time === hour || begin === true) {
                if (begin === false) {//begin building instances OBJ
                    for (const instanceItem of item.instances) {
                        instances[instanceItem] = {end: false, count: 1, instance: instanceItem}
                    }
                } else {//continue building
                    for (const instanceItem of item.instances) {//check instances associated with curr Date
                        if (keysForInstances.includes(String(instanceItem)) && !instances[instanceItem].end) {//if Item is part of original selection and the dates are ongoing
                            instances[instanceItem].count++
                            // instances[instanceItem].dates.push(item.time);
                        }
                    }
                    for (const keyValue of keysForInstances) {
                        if (!item.instances.includes(Number(keyValue))) {
                            instances[keyValue].end = true;
                        }
                        if (instances[keyValue].count > longest.count) {
                            longest = JSON.parse(JSON.stringify(instances[keyValue]));//deep copy
                        }
                        if (instances[keyValue].end === false) {
                            finished = false;
                        }
                    }
                    if (finished) {
                        return longest
                    }
                }
                begin = true;
            }
            index++
        }
        return longest
    }

    const changeStartTime = evt => {
        if (hideDuration) {
            onHourChangesCust(evt.target.value);
            return;
        }
        const inst = calculateInstances(evt.target.value);
        dispatch({
            type: 'SET_SELECTED_INSTANCE',
            payload: inst
        });
        setStartHour(evt.target.value);
        setHoursSelected(0);
    }

    const handleHoursChange = evt => {
        const val = Number(evt.target.value);
        const hours = Math.floor(val);
        const startTimeHour = Number(startHour.replace(/^(\d{2}).*/, '$1')) % 24;
        const minutes = Math.round(val * 60) % 60;
        const startTimeMin = Number(startHour.replace(/^\d{2}:(\d{2}).*/, '$1')) % 60;
        let newMin = minutes + startTimeMin;
        let newHour = hours + startTimeHour;
        if (newMin >= 60) {
            newMin %= 60;
            newHour += Math.floor(newMin / 60);
        }
        if (newMin < 10) {
            newMin = `0${newMin}`;
        }
        if (newHour < 10) {
            newHour = `0${newHour}`;
        }
        const endHours = `${newHour}:${newMin}:00`;
        setHoursSelected(val);
        onHoursChange(startHour, val, endHours);
    }
    const checkHourSlotValid = (time, timeNum) => hoursSelection.some(h => getHourNumber(h.time) === Math.round(timeNum));

    const hoursList = time => {
        let curr = minHour;
        const arr = [];
        while (getHourNumber(time) && curr <= maxHour && getHourNumber(time) + curr <= lastHour && checkHourSlotValid(time, getHourNumber(time) + curr)) {
            arr.push(curr);
            curr += hourlyIncrement;
        }
        return [0, ...arr];
    }
    const timeDropdowns = timeSlotDisplay?.reduce((prev, curr) => prev.set(curr, hoursList(curr)), new Map());

    const incrementSelection = time => (
        <FormControl margin="normal" fullWidth>
            <InputLabel id="hours-select-label">Duration (Hours)</InputLabel>
            <Select
                labelId="hours-select-label"
                id="hours-select"
                value={hoursSelected}
                label="Duration (Hours)"
                onChange={handleHoursChange}
            >
                {time.map(hourI => <MenuItem value={hourI} key={hourI}>{hourI ? hourI : 'Select Duration'}</MenuItem>)}
            </Select>
        </FormControl>
    )
    const checkSlotValid = slot => timeDropdowns.has(slot) && timeDropdowns.get(slot).length > 1;

    return (
        <div>
            <span>
                {weekDay[day]}, {monthName[month]} {date} {year}
            </span>
            {timeSlotDisplay?.length  >= 1 ? (
                <Card>
                    <CardContent>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel id="hours-select-label">Start Time</InputLabel>
                            <Select
                                labelId="hours-select-label"
                                id="hours-select"
                                value={startHour}
                                label="Start Time"
                                onChange={changeStartTime}
                            >
                                {timeSlotDisplay.filter(checkSlotValid).map(hourI =>
                                    <MenuItem value={hourI} key={hourI}>
                                        {hourI ? moment(hourI, ['hh:mm:ss']).format('hh:mm A') : 'Select Start Time'}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        {startHour && !hideDuration ? incrementSelection(timeDropdowns.get(startHour)) : null}
                    </CardContent>
                </Card>
            ) : null}
        </div>
    );
}

export default SingleDaySelectTimeSlot;
