import React, { useState, useEffect } from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import LinearProgress from "@mui/material/LinearProgress";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {useDispatch, useSelector} from "react-redux";
import InputMask from 'react-input-mask';
import axios from 'axios';
import {
    TextField,
    Paper,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Checkbox,
    Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function DonationPayment() {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();
    const { id } = useParams();
    const amount = query.get('amount'), clientId = query.get('clientId');
    const { isLoading } = useSelector(store => store.loadingReducer);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const [agreement, setAgreement] = useState(false);
    const [message, setMessage] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [email, setEmail] = useState(null);
    const client = useSelector(store => store.activeClient), clientEmail = client.email,
    clientFirstName = client.first_name, clientPhone = client.phone_number_1;
    const itemInfo = useSelector(store => store.selectedBookableItem)

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "20px",
                "::placeholder": {
                    color: "#717172",
                    fontSize: "1rem",
                }
            },
            invalid: {
                fontFamily: 'Arial, sans-serif',
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const checkout = async (e) => {
        e.preventDefault();
        setPaymentInProgress(true);
        setMessage('');
        setPaymentSuccess(false);
        if (!stripe || !elements || !agreement) {
            setPaymentInProgress(false);
            setMessage('Please read agreement above');
            return;
        }
        try {
            dispatch({ type: 'START_LOADING' });
            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });
            console.log(paymentMethod, error)
            const data = await axios.post('/api/donation/create-donation', {
                item: id, paymentMethod, firstName, lastName, phoneNumber, email,
                bookableItemTitle: itemInfo.tileTitle, company_name: itemInfo.company_name,
                clientEmail, clientFirstName, clientPhone, payInFull: true, amount: amount * 100,
                paymentType: 'card'
            });
            if (data.status === 200) {
                setPaymentSuccess(true);
                history.push(`/donation-completed/?confirmation-number=${data.data}&item=${id}`);
            }
        } catch (e) {
            console.error(e);
            setMessage('Error Occurred');
            setPaymentInProgress(false);
            setPaymentSuccess(false);
            dispatch({ type: 'LOADING_FINISHED_FAILURE' });
        } finally {
            setPaymentInProgress(false);
            setPaymentSuccess(false);
            dispatch({ type: 'LOADING_FINISHED_SUCCESS' });
        }
    }

    const buttonSx = {
        ...(paymentSuccess && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const confirmButton = () => (
        <Box sx={{ m: 1, position: 'relative' }}>
            <Button type="submit" variant='contained' fullWidth sx={buttonSx}
                    disabled={paymentInProgress}>Confirm Order</Button>
            {paymentInProgress && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </Box>
    );

    useEffect(() => {
        dispatch({
            type: 'FETCH_SELECTED_BOOKABLE_ITEM',
            payload: id
        });
        console.log(1422222, clientId);
        dispatch({
            type: 'FETCH_ACTIVE_CLIENT',
            payload: clientId
        });
    }, []);

    return (
        <>
          <Container align="center" maxWidth="lg" sx={{marginTop: 2}}>
            {
                isLoading ?
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
                : null
            }
            <Box>
                <Typography variant='h4'>Checkout</Typography>
            </Box>
            <Grid container spacing={2} sx={{marginTop: 0.5}}>
                <Grid item xs={12} md={6} textAlign="left">
                    <Paper sx={{padding: 2}}>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="h5" gutterBottom>
                                Donation Amount:
                            </Typography>
                            <Typography variant="h5" gutterBottom sx={{textAlign: 'right'}}>
                                ${amount}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper sx={{padding: 2}}>
                        <form onSubmit={checkout}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField fullWidth required onChange={(e) => setFirstName(e.target.value)}
                                               name="firstName" label="First Name" variant="outlined"/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth required onChange={(e) => setLastName(e.target.value)}
                                               name="lastName" label="Last Name" variant="outlined"/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        disabled={false}
                                        maskChar=" "
                                        onChange={(event) => setPhoneNumber(event.target.value.replace(/\D/g, ''))}
                                    >
                                        {() => <TextField
                                            fullWidth
                                            required
                                            className="renter-information-input"
                                            type={'text'}
                                            name="phoneNumber"
                                            label="Phone Number"
                                            variant="outlined"
                                        />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth required type="email"
                                               onChange={(e) => setEmail(e.target.value)} name="email" label="E-Mail"
                                               variant="outlined"/>
                                </Grid>
                                <Grid item xs={12}>
                                    {<div style={{
                                        border: '1px solid #cbcbcb',
                                        borderRadius: '4px',
                                        paddingTop: '15px',
                                        paddingBottom: '15px',
                                        paddingLeft: '5px',
                                        fontSize: '1rem'
                                    }}>
                                        <CardElement options={cardStyle}/></div>}
                                </Grid>
                                <Grid item xs={12}>
                                    {stripe && confirmButton()}
                                </Grid>
                                <Grid item xs={12} textAlign="left">
                                    <FormControlLabel control={<Checkbox onChange={() => setAgreement(!agreement)}
                                                                         value={agreement} />}
                                                      label="I agree that my credit card will be charged the donation amount."/>
                                </Grid>
                            </Grid>
                            <Typography variant='h6' sx={{color: 'red'}}>{message}</Typography>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
          </Container>  
        </>
    )
}

export default DonationPayment;
