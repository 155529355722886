import React from 'react'
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import './Nav.css';

const CompanyNav = () => {
  const dispatch = useDispatch();

  // Manage state for hamburger menu
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  // Handle open and close of hamburger menu
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElManage, setAnchorElManage] = React.useState(null);
  const openManage = Boolean(anchorElManage);
  const handleClickManage = (event) => {
    setAnchorElManage(event.currentTarget);
  };
  const handleCloseManage = () => {
    setAnchorElManage(null);
  };

  const [anchorElViewList, setAnchorElViewList] = React.useState(null);
  const openViewList = Boolean(anchorElViewList);
  const handleClickViewList = (event) => {
    setAnchorElViewList(event.currentTarget);
  };
  const handleCloseViewList = () => {
    setAnchorElViewList(null);
  };

  // Manage state for hamburger submenu "View List"
  const [openXSManage, setOpenXSManage] = React.useState(false);

  // Handle click of hamburger submenu "View List"
  const handleClickXSManage = () => {
    setOpenXSManage(!openXSManage);
  };

  return (
      <AppBar position="static" sx={{ backgroundColor: 'white' }}>
          <Container maxWidth="xl">
              <Toolbar disableGutters>

                  {/* For medium display, logo and "home" link are on left. */}
                  <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                    <Link to="/home">
                      <img src="./assets/images/eazie-book-logo-horizontal.png" id="logo" />
                    </Link>
                  </Box>

                  {/* Hamburger menu for extra small display */}
                  {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, color: 'black' }}>
                      <IconButton
                          size="large"
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleOpenNavMenu}
                          color="inherit"
                      >
                          <MenuIcon />
                      </IconButton>
                      <Menu
                          id="menu-appbar"
                          anchorEl={anchorElNav}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                          }}
                          keepMounted
                          transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                          }}
                          open={Boolean(anchorElNav)}
                          onClose={handleCloseNavMenu}
                          sx={{
                              display: { xs: 'block', md: 'none' },
                          }}
                      >
                          <List
                              sx={{ width: '100%', bgcolor: 'background.paper' }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"

                          >
                              <ListItemButton sx={{ width: 300 }} onClick={handleClickXSManage}>
                                  <ListItemText primary="View List" />
                                  {openXSManage ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </ListItemButton>
                              <Collapse in={openXSManage} timeout="auto" unmountOnExit>
                                  <List component="div" disablePadding>
                                      <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/clientBookableItems" sx={{ pl: 4 }}>
                                          <ListItemText primary="Bookable Items" />
                                      </ListItemButton>
                                  </List>
                                  <List component="div" disablePadding>
                                      <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/renterInfo" sx={{ pl: 4 }}>
                                          <ListItemText primary="Renter Info" />
                                      </ListItemButton>
                                  </List>
                              </Collapse>
                          </List>
                      </Menu>
                  </Box> */}

                  {/* For extra small display, the logo and "home" link appear in the middle */}
                  <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, mr: 1 }}>
                      <Link to="/home">
                        <img src="./assets/images/eazie-book-logo-horizontal.png" id="logo" />
                      </Link>
                  </Box>

                  {/* Menu for medium display */}
                  <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {/* <Button
                          id="manage-button"
                          onClick={handleClickManage}
                          sx={{ my: 2, color: 'black', display: 'block' }}
                          component={Link} to="/home"
                      >
                          Our Platform
                      </Button>                      
                      <Button
                          id="manage-button"
                          onClick={handleClickManage}
                          sx={{ my: 2, color: 'black', display: 'block' }}
                          component={Link} to="/clientBookableItems"
                      >
                          Industries
                      </Button>
                      <Button
                          id="manage-button"
                          onClick={handleClickManage}
                          sx={{ my: 2, color: 'black', display: 'block' }}
                          component={Link} to="/renterInfo"
                      >
                          About Us
                      </Button>
                      <Button
                          id="manage-button"
                          onClick={handleClickManage}
                          sx={{ my: 2, color: 'black', display: 'block' }}
                          component={Link} to="/renterInfo"
                      >
                          Contact Us
                      </Button> */}
                      {/* <Menu
                          id="manage-menu"
                          anchorEl={anchorElManage}
                          open={openManage}
                          onClose={handleCloseManage}
                          MenuListProps={{
                              'aria-labelledby': 'basic-button',
                          }}
                      >
                          <MenuItem onClick={handleCloseManage} component={Link} to="/clientBookableItems">Bookable Items</MenuItem>
                          <MenuItem onClick={handleCloseManage} component={Link} to="/renterInfo">Renter Info</MenuItem>
                      </Menu> */}
                  </Box>

                  {/* "Log In" link is right justified in the App Bar */}
                  <Box sx={{ flexGrow: 0, color: 'black' }}>
                      <Button sx={{ color: 'black' }} component={Link} to="/login">
                          Log In
                      </Button>
                  </Box>

              </Toolbar>
          </Container>
      </AppBar>
  )
}

export default CompanyNav