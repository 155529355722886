import React, { Component, useEffect, useCallback, useState } from 'react'
import { Typography, Box , FormControl, Stack, Grid} from "@mui/material";
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux';
import DropZone from './DropZone'
import './ClientPhotos.css';

const ClientPhotos = () => {


    // TODO: add overlays and delete functionality
    const dispatch = useDispatch()
    const clientId = useSelector(store => store.user.id)
    const [state, setState] = useState({ uploading: false, images: [] });
    const photos = useSelector(store => store.photoReducer.photos)


    useEffect(() => {
        const init = async () => {
            const answer = await axios.get(`/api/photos/client/${clientId}`);
            console.log('answer.data is', answer.data);
            // setImageData(res => [...res, answer.data])
            // console.log('imageData is', imageData);
            dispatch({
                type: 'SET_ITEM_PHOTOS',
                payload: answer.data
            })
        }
        init()
    }, []);

    

    return (
        <>
            <Typography variant='h2' align='center' padding='8px'>View and Upload Your Photos Here</Typography>
            <Grid container maxWidth="md" mx="auto" direction="column" my={4} sx={{ padding: '20px' }}>
                <Grid item>
                <DropZone isGallery={true} />
                </Grid>
            </Grid>
                

            {/* <Grid container maxWidth="md" mx="auto" alignContent='center' direction="row" spacing={8}> */}
            <ul class="client-photos-container">
                {photos?.map(image => {
                    return (
                        <div key={image.id}>
                            {/* <Grid item sm={2}> */}
                                <img class='client-photo-card'
                                    src={`/categoryList/picKey/${image.url}`}
                                    style={{ width: 'auto', height: '400px', margin: '10px' }}
                                />
                            <div class="overlay"><span>image title</span></div>
                            {/* </Grid> */}
                        </div>
                    )
                })}
            </ul>
                

            {/*</Grid>*/}

        </>
    );
};

export default ClientPhotos;