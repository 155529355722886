import SingleDayCalendar from "../Calendar/SingleDayCalendar";
import MultiDayCalendar from "../Calendar/MultiDayCalendar";
import {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {dateToString, getDates, mapDateToString} from "../Utils/share-functions";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
//todo: exclude time logic in single day mode
const RenterSelectDate = ({item}) => {
    const {duration_type = 'multiDay', max_duration, min_duration, hourly_increment, id, availableHours} = item || {};
    const dispatch = useDispatch();
    const availability = useSelector(store => store.selectedBookableItem.availabilityyy?.masterList || [])
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [updateMinMax, setUpdateMinMax] = useState({
        toUpdate: false,
        mode: ''
    });
    const clearRangeRef = useRef(null);

    useEffect(() => {
        if (!item || !item.id) return;
        dispatch({
            type: 'FETCH_BOOKABLEITEM_AVAILABILITY',
            payload: { itemId: item.id }
        })
    }, [item.id]);

    const validateSetMinDate = (last, start) => {
        if (!start || !last) return false;
        if (start.getMonth() === last.getMonth() && start.getUTCFullYear() === last.getUTCFullYear()) {
            return last.getUTCDate() - Number(dateToString(start).slice(3, 5)) >= Math.ceil(min_duration / 24)
        } else {
            return true;
        }
    }

    const clearSelectionDispatch = () => {
        dispatch({
            type: 'BOOKING_FORM_ONCHANGE',
            payload: {
                property: 'date',
                value: []
            }
        })
    }

    const onChangeDates = range => {
        const [start, end] = range;
        const lastAvailableDate = new Date(availability[availability.length - 1]);
        const availabilitySrtArr = availability.map(date => mapDateToString(date));
        const a = moment(range[0],'M/D/YYYY');
        const b = moment(range[1],'M/D/YYYY');
        const diffHours = b.diff(a, 'hours');
        if (diffHours > max_duration) {
            setAlertText(`be more than ${Math.floor(max_duration / 24)} days`)
            setAlertOpen(true);
            setTimeout(() => {
                clearRangeRef.current();
                setMaxDate(null);
                setMinDate(null);
            }, 100);
            clearSelectionDispatch();
        } else if (diffHours < min_duration) {
            setAlertText(`be less than ${Math.floor(min_duration / 24)} days`)
            setAlertOpen(true);
            setTimeout(() => {
                clearRangeRef.current();
                setMaxDate(null);
                setMinDate(null);
            }, 100);
            clearSelectionDispatch();
        } else if (updateMinMax.toUpdate) { // when user only selects start date, it will calculate the max date & min date
            if (updateMinMax.mode === 'start') {
                if (validateSetMinDate(lastAvailableDate, start)) {
                    setMinDate(() => moment(start).clone().add((min_duration / 24), 'days').toDate());
                    setMaxDate(() => moment(start).clone().add((max_duration / 24), 'days').toDate());
                }
            }
        } else if (end) { // When User have both start date & end date selected
            if(!getDates(...range).every(date => availabilitySrtArr.includes(date))) {
                // when user selected dates include invalid dates
                setAlertText(`are not available`)
                setAlertOpen(true);
                setTimeout(() => {
                    clearRangeRef.current();
                    setMaxDate(null);
                    setMinDate(null);
                }, 100)
                clearSelectionDispatch();
            }
            else {
                dispatch({
                    type: 'BOOKING_FORM_ONCHANGE',
                    payload: {
                        property: 'date',
                        value: getDates(...range)
                    }
                })
                setMaxDate(null);
                setMinDate(null);
            }

        } else {
            // only calculate min date & max date when there is possible
            if (validateSetMinDate(lastAvailableDate, start)) {
                setMinDate(() => moment(start).clone().add((min_duration / 24), 'days').toDate());
                setMaxDate(() => moment(start).clone().add((max_duration / 24), 'days').toDate());
            }
        }
    }


    const onOpen = range => {
        const [start, end] = range;
        if (!start && !end && (!!minDate || !!maxDate)) {
            setMaxDate(null);
            setMinDate(null);
            clearSelectionDispatch();
        }
    }


    return (
        <div>
            {duration_type === 'multiDay' || duration_type === 'multiNight' ?
                <MultiDayCalendar changeDates={onChangeDates}
                    includeDates={availability.map(mapDateToString)}
                    maxDate={maxDate}
                    minDate={minDate}
                    mode={'renter'}
                    setUpdateMinMax={setUpdateMinMax}
                    clearRange={clearRangeRef}
                    onOpen={onOpen}
                />
                : <SingleDayCalendar
                    maxHour={max_duration}
                    minHour={min_duration}
                    includeDates={availability.map(mapDateToString)}
                    hourlyIncrement={hourly_increment}
                    availableHours={availableHours}
                    itemId={id}
                    hideDuration={false}
                />}
            <Dialog
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Selected Date Range Invalid"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sorry, but the date range cannot {alertText}, please select again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAlertOpen(false)} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

)
};

export default RenterSelectDate;
