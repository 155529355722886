import  { useEffect, useState } from "react";
import dateFormatter from '../../../formatters/date.format';

import { TextField } from '@mui/material';


const EditBookingForm = ({booking}) => {
    const { first_name, last_name, email, phone, startDate, endDate} = booking;
    const [form, setForm] = useState({
        first_name,
        last_name,
        email,
        phone,
        startDate,
        endDate
    });

    const handleForm = (e) => {
        e.preventDefault();
        console.log(e.target.name);
        setForm({...form, [e.target.name]: e.target.value})
    }

    return (<>
        <div id='booked-item-renter-container'>
            <form onChange={e => handleForm(e)}>
                <div id='booked-item-contact'>
                    <h2>Customer Info</h2>
                    <div className='edit-booking-inputs'>
                        <TextField className='edit-booking-input-field' name='first_name' label="First Name" value={form.first_name} variant="outlined" />
                        <TextField className='edit-booking-input-field' name='last_name' label="Last Name" value={form.last_name} variant="outlined" />
                        <TextField className='edit-booking-input-field' name='phone' label="Phone Number" value={form.phone} variant="outlined" />
                        <TextField className='edit-booking-input-field' name='email' label="Email" value={form.email} variant="outlined" />
                    </div>
                </div>
                <div id='booked-item-reservation'>
                    <h2>Booking Info</h2>
                    <p>Booking Created: {dateFormatter(form.bookedDate)}</p>
                    <p>Confirmation Number: {form.confirmationNumber}</p>
                    <p>Dates: from {dateFormatter(form.startDate)} to {dateFormatter(form.endDate)}</p>
                </div>
            </form>
        </div>
    </>)
}

export default EditBookingForm