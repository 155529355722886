import React, { useState } from 'react';
import { Paper, TextField, Button, Box, Typography } from '@mui/material';
import {useHistory} from 'react-router-dom';
function DonationForm({bookableItemId, clientId}) {
    const [amount, setAmount] = useState('');  
    const history = useHistory();
    const handleChange = event => {
        const value = event.target.value;
        setAmount(value === '' ? '' : Number(value));
      };

    const handleCheckout = () => {
        console.log(133333, `/${'checkout-donation'}/${bookableItemId}?amount=${amount}`)
        if(!amount || typeof amount === 'string') return;
        history.push(`/${'checkout-donation'}/${bookableItemId}?amount=${amount}&clientId=${clientId}`)
    }
    return (
        <Box sx={{ margin: 2 }}>
            <Paper elevation={3} sx={{ padding: 3, width: 'fit-content', margin: 'auto' }}>
                <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 2 }}>
                    Any amount is appreciated!
                </Typography>
                <TextField
                    label="Donation Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    margin="normal"
                    value={amount}
                    onChange={handleChange}
                />
                <Button variant="contained" color="primary" sx={{ width: '100%', marginTop: 2 }} onClick={handleCheckout}>
                    Donate Now
                </Button>
            </Paper>
        </Box>
    );
}

export default DonationForm;
