const initialState = {
    account_number: null,
    isDemo: true,
}

const accountWrapper = (state = initialState, action) => {
    switch(action.type) {
        case 'SET_ACCOUNT_WRAPPER':
            return { ...action.payload };
        default:
            return state;
    }
}

export default accountWrapper