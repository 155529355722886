import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";


const AdminCustomers = () => {

  function createData(last_name, first_name, email, phone, client_name) {
    return { last_name, first_name, email, phone, client_name };
  }

  const rows = useSelector(store => store.renterBooking.adminCustomers);

  const dispatch = useDispatch(); 

  const columns  = [
    { field: 'lastName', headerName: 'Last Name', minWidth: 130, flex: 0.8 },
    { field: 'firstName', headerName: 'First Name', minWidth: 120, flex: 0.8 },
    { field: 'email', headerName: 'email', minWidth: 220, flex: 1 },
    { field: 'phoneNumber', headerName: 'Phone', minWidth: 130, flex: 0.8 },
    { field: 'clientName', headerName: 'Client Name', minWidth: 130, flex: 0.8 },

  ]

  const dataGridRows = rows.map((row, index) => {
    return (
      {
        id: index, 
        lastName: row.last_name,
        firstName: row.first_name,
        email: row.email,
        phoneNumber: row.phone, 
        clientName: row.company_name
      }
    )
  })


  React.useEffect(() => {
    dispatch({
      // type: 'FETCH_CLIENTS'
      type: 'FETCH_ADMIN_CUSTOMERS'
    })
  }, [])

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5" align='center' gutterBottom='true'>
          Customers
        </Typography>
      </Box>

      <TableContainer component={Paper}  >
        <div style={{ height: 400, width: '100%', alignContent: 'center' }}>
          <DataGrid
            sx={{ m: 2, boxShadow: 2 }}
            aria-label="a dense table"
            rows={dataGridRows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </div>
      </TableContainer>
      {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Last Name</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Client Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row.last_name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.last_name}
                </TableCell>
                <TableCell align="left">{row.first_name}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
                <TableCell align="left">{row.company_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </>
  )
}

export default AdminCustomers