const defineUnits = (duration, totalUnits) => {
    totalUnits = Number(totalUnits);

    switch (duration) {
        case 'singleDay':
            return totalUnits === 1 ? 'hour' : 'hours';
        case 'multiDay':
            return totalUnits === 1 ? 'day' : 'days';
        case 'multiNight':
            return totalUnits === 1 ? 'night' : 'nights';
        default:
            // Default case could either be a fallback or a specific handling for other durations
            return totalUnits === 1 ? 'hour' : 'hours'; // Adjust this based on what other durations should display
    }
};

export default defineUnits;