import React from 'react';
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack } from '@mui/material'

function RenterInfo() {
    const moment = require('moment-timezone');
    const info = useSelector(store => store.renter.renterInfo)
    console.log('info is ', info);
    useEffect(() => {
        dispatch({
            type: 'FETCH_RENTER_INFO'
        });
    }, []);

    const params = useParams()
    //caveman way of formatting dates 
    const limit = (str = '', limit = 0) => {
        if(str === null || str === undefined) {
            return
        }
        else {
            return str.substring(0, limit)

        }
    }

    //used flex to autosize the rows
    // datagrid pro (mui x) allows resizing by dragging columns
    const columns = [
        // { field: 'id', headerName: 'id', width: 70 },
        { field: 'lastName', headerName: 'Last Name', minWidth: 90, flex: 0.6 },
        { field: 'firstName', headerName: 'First Name', minWidth: 90, flex: 0.6 },
        { field: 'email', headerName: 'Email', minWidth: 200, flex: 1 },
        { field: 'phoneNumber', headerName: 'Phone', minWidth: 90, flex: 0.5 },
        { field: 'confirmationNumber', headerName: 'Confirmation #', minWidth: 120, flex: 0.7 },
        { field: 'startDate', headerName: 'Start Date', minWidth: 110, flex: 0.8 },
        { field: 'endDate', headerName: 'End Date', minWidth: 110, flex: 0.8 },
        { field: 'itemName', headerName: 'Item Name', minWidth: 110, flex: 1 },
    ]

    const rows = info.map((row, index) => {
        return (
            {   
                id: index,
                lastName: row.last_name,
                firstName: row.first_name,
                email: row.email,
                phoneNumber: row.phone,
                confirmationNumber: row.confirmationNumber,
                startDate: limit(row.startDate, 10),
                endDate: limit(row.endDate, 10),
                itemName: row.tileTitle
            }
        )
    })



    const dispatch = useDispatch();
    return (
        <>
            <Typography variant='h3' align='center' padding='10px'>Customers</Typography>
            <Stack justifyContent="center" padding='10px' spacing={2} direction="row">
                {/* <Button variant="outlined" color="primary" onClick={goBack} >Back</Button> */}
                {/* <Button variant="outlined" color="primary" onClick={addItem}  >Add New Item</Button> */}
            </Stack>
            {/* data grid */}
            <Grid container spacing={2} >

            </Grid>
            <TableContainer component={Paper}  >
                <div style={{ height: 400, width: '100%', alignContent: 'center'}}>
                    <DataGrid
                        sx={{ m: 2, boxShadow: 2 }}
                        aria-label="a dense table"
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[8]}
                    />
                </div>
            </TableContainer>
            {/* end data grid  */}

            {/* old table */}
           {/*  <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell align="center" className="clientListTableHeadings"> <strong>Last Name</strong></TableCell>
                            <TableCell align="center" className="clientListTableHeadings"> <strong>First Name</strong></TableCell>
                            <TableCell align="center" className="clientListTableHeadings"> <strong>Email</strong></TableCell>
                            <TableCell align="center" className="clientListTableHeadings"> <strong>Phone Number</strong></TableCell>
                            <TableCell align="center" className="clientListTableHeadings"> <strong>Confirmation #</strong></TableCell>
                            <TableCell align="center" className="clientListTableHeadings"><strong>Start Date</strong></TableCell>
                            <TableCell align="center" className="clientListTableHeadings"> <strong>End Date</strong></TableCell>
                            <TableCell align="center" className="clientListTableHeadings"> <strong>Item Name</strong></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {info.map((row, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    sx={{ border: 2, minWidth: 100 }}
                                >
                                    <TableCell component="th" scope='row'>
                                        {row.last_name}
                                    </TableCell>
                                    <TableCell align='center'>{row.first_name}</TableCell>
                                    <TableCell align='center'>{row.email}</TableCell>
                                    <TableCell align='center'>{row.phone}</TableCell>
                                    <TableCell align='center'>{row.confirmationNumber}</TableCell>
                                    <TableCell align='center'>{limit(row.startDate, 10)}</TableCell>
                                    <TableCell align='center'>{limit(row.endDate, 10)}</TableCell>
                                    <TableCell align='center'>{row.tileTitle}</TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>


                </Table>

            </TableContainer> */}
            {/* end old table */}

        </>
    )
}

export default RenterInfo;