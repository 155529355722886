import * as React from 'react';
import { useSelector } from 'react-redux';
import SuperAdminNav from './SuperAdminNav';
import ClientNav from './ClientNav';

const ResponsiveAppBar = () => {
  const user = useSelector((store) => store.user);

  // If user is admin, then render nav bar for the admin.
  // If user is client, then redner nav bar for the client.
  if (user.authLevel === 'admin') {
    return <SuperAdminNav />;
  } else if (user.authLevel === 'client') {
    return <ClientNav />;
  }

  return (
    <></>
  );

}

export default ResponsiveAppBar;