import React from "react";
import { Stack, TextField, Box } from "@mui/material";
import { Button } from "@material-ui/core";


const DiscountRow = ({setDiscounts, discount, index, handleDeleteDiscount}) => (
    <>
        <Box key={`${discount.minUnits}-${discount.discountRate}-${index}`}>
            <Stack justifyContent="center" padding='15px' spacing={3} direction="row">
                <TextField
                    variant='standard'
                    type='number'
                    min={6}
                    value={discount.minUnits}
                    placeholder='Min Units'
                    onChange={event => {
                        const newMinUnits = event.target.value;
                        setDiscounts(prevDiscounts => {
                            return prevDiscounts.map((item, idx) => idx === index ? { ...item, minUnits: newMinUnits } : item);
                        });
                    }}
                />
                <TextField 
                variant='standard' 
                type='number' 
                min={0} 
                value={discount?.discountRate}
                max={100} 
                placeholder='Rate %' 
                    onChange={event => {
                        const newRate = event.target.value;
                        setDiscounts(prevDiscounts => {
                            return prevDiscounts.map((item, idx) => idx === index ? { ...item, discountRate: newRate } : item);
                        });
                    }}
                />
                <Button variant="contained" onClick={(e) => handleDeleteDiscount(e, index)}>Delete</Button>

            </Stack>
        </Box>
    </>
);


export default DiscountRow;