const selectedBookingInitial = {
    totals: { total: 0, amount: 0, tax: 0, eaziebook_fee: 0, client_fee: 0 },
    order: [],
}


const selectedBooking = (state = selectedBookingInitial, action) => {
    switch(action.type){
        case 'SET_SELECTED_BOOKING' :
            return action.payload;
        default:
            return state;
    }
}

export default selectedBooking;