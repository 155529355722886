import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { TextField, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, FormControl, FormControlLabel, Checkbox, Button, PersonAddIcon, Input, InputLabel, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";



export default function DepositClaim() {
    const dispatch = useDispatch()
    const history = useHistory();
    const limit = (str = '', limit = 0) => {
        if (str === null || str === undefined) {
            return
        }else {
            return str.substring(0, limit)
        }
    };
    const params = useParams();
    const [open, setOpen] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [status, setStatus] = useState(null);
    const [claimAmount, setClaimAmount] = useState(0);
    const [claimReason, setClaimReason] = useState('');
    const [clientId, setClientId] = useState(0)
    const companyName = useSelector(store => store.user.company_name)
    const companyPhone = useSelector(store => store.user.phone_number_1)
    const companyEmail = useSelector(store => store.user.email)
    useEffect(() => {
        const init = async () => {
            const { data } = await axios.get(`/api/bookings/selected/${selectedId}`);
            console.log(data);
            setStatus(data[0].status)
            setSelectedBooking(data[0]);
        }
        init();
    }, [])

    const startDate = limit(selectedBooking?.startDate, 10)
    const endDate = limit(selectedBooking?.endDate, 10)
    const renterFullName = selectedBooking?.first_name + ' ' + selectedBooking?.last_name
    const renterEmail = selectedBooking?.email
    const bookableItemTitle = selectedBooking?.title
    

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('submit button pressed');
        if (claimAmount > selectedBooking.damage_deposit_amt) {
            alert('You cannot claim higher than the deposit amount. Please enter a valid input');
            return
        }
        const response = await axios.post(`/api/payment/depositClaim/${params.id}`,
            {
                claimAmount: claimAmount,
                claimReason: claimReason,
                startDate: startDate, 
                endDate: endDate, 
                renterFullName: renterFullName, 
                renterEmail: renterEmail, 
                bookableItemTitle: bookableItemTitle, 
                companyName: companyName, 
                companyPhone: companyPhone, 
                companyEmail: companyEmail
            }
        );
        setOpen(true);
    }

    const { id: selectedId } = useParams();
    const handleClose = () => {
        setOpen(false);
        history.push('/user')
        window.location.reload();
    };

    const BootstrapButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        borderColor: '#821E0D',
        color: '#821E0D',
        borderRadius: '25px',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#821E0D',
            borderColor: '#821E0D',
            boxShadow: 'none',
            color: 'white',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#821E0D',
            borderColor: '#821E0D',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(130,30,13,.5)',
        },
    });


    return (
        <>
            <Container component='main'>
                <Grid container maxWidth="md" mx="auto" direction="column" my={4} >
                    <Box component='form' onSubmit={handleSubmit}>
                        <Grid item my={4} spacing={2}>
                            <FormControl margin="normal" fullWidth>
                                <Typography  variant='h3'>Claim Details</Typography>
                            </FormControl>
                        </Grid>
                        <Grid item my={4} spacing={2}>
                            <FormControl margin="normal" fullWidth>
                                <Typography variant='h5'>Damage Deposit Amount: ${selectedBooking?.damage_deposit_amt}</Typography>
                            </FormControl>
                        </Grid>
                        <Grid item my={4} spacing={3}>
                            <FormControl margin="normal" >
                                <TextField  
                                type='number' 
                                variant='standard' 
                                required 
                                label='Claim Amount ($)'
                                onChange={(e) => setClaimAmount(Number(e.target.value))}
                                //TODO: set max to damage deposit amt
                                />
                            </FormControl>
                        </Grid>

                        <Grid item my={4} spacing={3}>
                            <FormControl margin="normal" fullWidth >
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Please state the reason for your claim"
                                    multiline
                                    maxRows={15}
                                    onChange={(e) => setClaimReason(e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* TODO: add photo upload for damages */}

                        <Grid item>
                            {/* <BootstrapButton type='submit' variant='outlined'>
                                Submit Claim
                            </BootstrapButton> */}
                            <Button type='submit' variant='outlined'>
                                Submit Claim
                            </Button>
                        </Grid>

                    </Box>
                </Grid>
            </Container>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Your claim has been submitted"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please contact the renter for any additional questions
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <BootstrapButton onClick={handleClose} autoFocus>
                            Close
                        </BootstrapButton>
                    </DialogActions>
                </Dialog>
            </div>

        </>
    )
}