import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import swal from 'sweetalert';
// import './ClientTable.css';
function ClientTable() {


    const dispatch = useDispatch();
    const history = useHistory();
    const bookableItemList = useSelector(store => store.bookableItem.bookableItemReducer)

    const [newFirstName, setFirstName] = useState('');
    const [newLastName, setLastName] = useState('');
    const [newEmail, setEmail] = useState('');
    const [newPhoneNumber, setPhoneNumber] = useState('');
    const [newCompanyName, setCompanyName] = useState('');
    const [newAddress, setAddress] = useState('');
    const [newCity, setCity] = useState('');
    const [newState, setState] = useState('');
    const [newZipCode, setZipCode] = useState('');
    const [newWebsiteUrl, setWebsiteUrl] = useState('');
    const [clientId, setClientId] = useState('');


    const [btnStatus, setBtnStatus] = useState(false);

    const [canDelete, setCanDelete] = useState(false)

    const deleteCheck = (clientIdToCheck) => {
        const count = bookableItemList.filter(item => item.clientId === clientIdToCheck)
        console.log('clientIdToChek is', clientIdToCheck);
        console.log('count is', count);
        if (count.length > 0 ){
            return true
        }
        else{
            return false 
        }
    }


    

    useEffect(() => {
        dispatch({
            type: 'FETCH_CLIENTS'
        })
        dispatch({
            type: 'FETCH_BOOKABLE_ITEM'
        })
    }, [])

    const clients = useSelector(store => store.clients);

    const deleteClient = (id) => {
        console.log('selected id is', id);
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this row of information",
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if(willDelete){
                swal("POOF! The row has been deleted!"), {
                    icon: "success",
                }
                dispatch({
                    type: 'DELETE_SELECTED_CLIENT',
                    payload: { id: id }
                });

                history.push('/user');
            } else {
                swal("The row of information is safe!")
            }
        })
        /* still need sweet alerts */
       


        console.log('delete button pressed');
    }

    const editClient = (client) => {
        console.log('Edit button clicked');

        //setBtnStatus(true);

        history.push(`/editClient/${client.id}`)
    }

    const handleSave = () => {
        console.log('Save button pressed!');

        setBtnStatus(false);

        console.log('save btnStatus is:', btnStatus);

        dispatch({
            type: 'UPDATE_CLIENT',
            payload: {
                id: clientId,
                firstName: newFirstName,
                lastName: newLastName,
                email: newEmail,
                phoneNumber: newPhoneNumber,
                companyName: newCompanyName,
                address: newAddress,
                city: newCity,
                state: newState,
                zipCode: newZipCode,
                websiteUrl: newWebsiteUrl
            }
        })
    }

    const cancelChanges = () => {
        setBtnStatus(false);
    }

    const manageCategories = client => {
        history.push(`/manageCategory/${client.id}`)
    }

    const manageTax = client => history.push(`/manageTax/${client.id}`);

    const manageCoupons = client => history.push(`/manageCoupons/${client.id}`)

    const viewBookableItems = (client) => {

        dispatch({
            type: 'FETCH_CLIENT_BOOKABLE_ITEM',
            payload: client.id
        })

        history.push(`/viewBookableItem/${client.id}`)
    }

    const viewBookedItems = client => {
        history.push(`/viewBookedItem/${client.id}`);
    }

    const addBookableItem = (client) => {
        history.push(`/addBookableItem/${client.id}`)
    }

    return (
        <>
            {/* <br/>
            <br/>
            <br/> */}
            <div className="clientTable">
                <TableContainer component={Paper}>
                    <Table>
                        {/* <TableHead>
                            <TableRow >
                                <TableCell align="center" className="clientListTableHeadingsFirstName"> <strong>First Name </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadingsLastName"> <strong>Last Name  </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadings"> <strong>Username  </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadings"> <strong>Email </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadings"> <strong>Phone Number  </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadings"> <strong>Company Name </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadings"> <strong>Address </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadings"> <strong>City </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadings"> <strong>State </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadings"> <strong>Zip Code </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadings"> <strong>Website URL </strong></TableCell>
                                <TableCell align="center" className="clientListTableHeadings"> <strong>Bookable Items </strong></TableCell>
                            </TableRow>
                        </TableHead> */}

                        {
                                <>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="clientListTableHeadings"><strong>First Name</strong></TableCell>
                                            <TableCell align="center" className="clientListTableHeadings"><strong>Last Name</strong></TableCell>
                                            <TableCell align="center" className="clientListTableHeadings"><strong>Username</strong></TableCell>
                                            <TableCell align="center" className="clientListTableHeadings"><strong>Email</strong></TableCell>
                                            <TableCell align="center" className="clientListTableHeadings"><strong>Phone Number</strong></TableCell>
                                            <TableCell align="center" className="clientListTableHeadings"><strong>Company Name</strong></TableCell>
                                            <TableCell align="center" className="clientListTableHeadings"><strong>Website URL</strong></TableCell>
                                            {/* manage tab (categories, tax and coupon codes) */}
                                            <TableCell align="center" className="clientListTableHeadings"><strong>Manage</strong></TableCell>
                                            {/* <TableCell align="center" className="clientListTableHeadings"><strong>Bookable Items</strong></TableCell> */}
                                            <TableCell align="center" className="clientListTableHeadings"><strong></strong></TableCell>
                                        <TableCell align="center"><strong>Bookable Items</strong></TableCell>
                                        <TableCell align="center"><strong>Edit</strong></TableCell>
                                        </TableRow>
                                    </TableHead> 
                                    <TableBody className="clientTableBody">
                                        {clients.map(client => (
                                            <TableRow key={client.id} id={client.id}>
                                                <TableCell>{client.first_name}</TableCell>
                                                <TableCell align="center" className="clientTableBodyLastName">{client.last_name}</TableCell>
                                                <TableCell align="center">{client.username}</TableCell>
                                                <TableCell align="center">{client.email}</TableCell>
                                                <TableCell align="center" className="clientTableBodyAddress">{client.phone_number_1}</TableCell>
                                                <TableCell align="center">{client.company_name}</TableCell>
                                                <TableCell align="center">{client.website_url}</TableCell>
                                                <TableCell align="center" >
                                                    <button className="clientTableOpenButtons" onClick={(event) => manageCategories(client)}>Manage Categories</button>
                                                    <button className="clientTableOpenButtons" onClick={(event) => manageTax(client)}>Manage tax</button>
                                                    <button className="clientTableOpenButtons" onClick={(event) => manageCoupons(client)}>Manage Coupons</button>
                                                    </TableCell>
                                                <TableCell align="center" ></TableCell>
                                                <TableCell align="center" >
                                                    <button className="clientTableOpenButtons" onClick={(event) => viewBookableItems(client)}>View Bookable Items</button>
                                                    <button className="clientTableOpenButtons" onClick={() => viewBookedItems(client)}>View Booked Items</button>
                                                    <button className="clientTableOpenButtons" onClick={(event) => addBookableItem(client)}>Add Bookable Item</button>
                                                </TableCell>
                                                <TableCell align="center" >
                                                    <button className="clientTableEditButtons" onClick={event => editClient(client)}>Edit</button>
                                                    <button disabled={deleteCheck(client.id)} className="clientTableDeleteButtons" onClick={event => deleteClient(client.id)}>Delete</button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </>
                        }

                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default ClientTable;