const loadingStateInitial = {
    isLoading: false,
    loaded: false,
    error: null
}

const loadingReducer = (state = loadingStateInitial, action) => {
    switch(action.type) {
        case 'START_LOADING':
            return {
                isLoading: true,
                loaded: false,
                error: null
            };
        case 'LOADING_FINISHED_SUCCESS':
            return {
                isLoading: false,
                loaded: true,
                error: null
            };
        case 'LOADING_FINISHED_FAILURE':
            return {
                isLoading: false,
                loaded: true,
                error: action.data
            };
        default:
            return {
                isLoading: false,
                loaded: false,
                error: null
            };
    }
}

export default loadingReducer;
