import {Modal, Checkbox, FormControlLabel, Grid, Typography, TextField } from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Button} from "@material-ui/core";
import * as React from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function AddNewTaxModal({ form, dispatch, onSave }) {
    const [open, setOpen] = React.useState(false);
    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);
    const saveAddNewTax = () => {
        onSave();
        handleCloseModal();
    }
    return (
        <>
            <Typography align='center'>
                <Button  variant="contained" onClick={handleOpenModal}>Add New</Button>
            </Typography>
            <Modal
                open={open}
                onClose={handleCloseModal}
            >
                <Grid container sx={style} spacing={2}>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h5">
                            Add New Tax
                        </Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <TextField
                            required
                            value={form.title}
                            fullWidth
                            onChange={e => dispatch({type: 'title', payload: e.target.value})}
                            id="title"
                            label="Title"
                        />
                    </Grid>

                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            value={form.description}
                            onChange={e => dispatch({type: 'description', payload: e.target.value})}
                            id="description"
                            label="Description"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            required
                            value={form.taxRate}
                            onChange={e => dispatch({type: 'taxRate', payload: e.target.value})}
                            id="taxRate"
                            type="number"
                            label="Tax Rate"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <FormControlLabel style={{padding: 10}} control={<Checkbox
                            value={form.active}
                            onChange={e => dispatch({type: 'active', payload: e.target.value})}
                            defaultChecked />
                        } label="Active" />
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Effective Date"
                                value={form.effectiveDate}
                                onChange={(newValue) => {
                                    dispatch({type: 'effectiveDate', payload: newValue})
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={8}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Expiration Date"
                                value={form.expirationDate}
                                onChange={(newValue) => {
                                    dispatch({type: 'expirationDate', payload: newValue})
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align='center'>
                            <Button  variant="contained" onClick={saveAddNewTax}>Save</Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Modal>
        </>

    )
}

export default AddNewTaxModal;
