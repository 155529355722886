import axios from 'axios';
import { TextField, Button } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import {useSelector} from 'react-redux';

import moneyFormatter from '../../../formatters/money.format';
import { CarouselDisplay } from '../../carousel/CarouselDisplay'

export default function AddOnModal ({addOn, handleClose}){
    console.log(addOn);
    const closeModal = (e) => {
        if(e.target.classList.contains('modal-background')){
            handleClose()
        }
    }
    const backGroundStyle = {
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.352)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 99999
    }
    const contentStyle = {
        marginTop: '15px',
        padding: '25px',
    }
    const buttonStyle ={
        position: 'absolute',
        top: '15px',
        right: '15px',
        zIndex: 999999
    }
    return (
        <div onClick={e => closeModal(e)} className='modal-background' style={backGroundStyle}>
            <div className='booking-detail-modal'>
                <div style={contentStyle} >
                    <h1>{addOn.title}</h1>
                    <p>{addOn.description}</p>
                    <CarouselDisplay pics={addOn.photos} />
                </div>
                <Button onClick={handleClose} variant='contained' color='error' style={buttonStyle}>Close</Button>
            </div>
        </div>
    )
}