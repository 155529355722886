import {combineReducers} from "redux";

const photos = (state = [], action) => {
    switch(action.type){
        case 'SET_ITEM_PHOTOS' :
            return action.payload;
        case 'SET_ITEM_REQUEST_PHOTOS':
        return [...state, ...action.payload]
        case 'DELETE_REQUEST_PHOTO':
        return [
            ...state.slice(0, action.payload),
            ...state.slice(action.payload + 1)]
        case 'CLEAR_PHOTOS':
            return []
        default :
            return state;
    }
}

const prevPhotos = (state = [], action) => {
    switch(action.type){
        case 'SET_ITEM_PREV_PHOTOS' :
            return action.payload;
        default :
            return state;
    }
}


const photoReducer = combineReducers({
    photos,
    prevPhotos
})

export default photoReducer;
