import axios from 'axios';
import { put, takeEvery } from 'redux-saga/effects';


function* bookingItem() {
    yield takeEvery("BOOKING_CONFIRM", renterConfirm);
    yield takeEvery("FETCH_BOOKING_CONFIRM", renterFetchBookingConfirm);
    yield takeEvery('BOOK_ITEM', bookItem)
    yield takeEvery('FETCH_ADMIN_CUSTOMERS', fetchAdminCustomers)
}

function* fetchAdminCustomers() {
    console.log('made it to fetchAdminCustomers saga');
    
    try {
        const response = yield axios.get(`/api/renter/adminCustomers`)
        yield put({
            type: `SET_ADMIN_CUSTOMERS`,
            payload: response.data
        })
    } catch (error) {
        console.error('error fetching admin customers', error)
    }
}


function* bookItem(action){
    const { itemId, date, daysBooked } = action.payload;
    try {
        yield axios.put(
            `/api/bookableItem/availability?itemId=${itemId}&date=${date}&daysBooked=${daysBooked}`, action.payload
        )
    } catch (err) {
        console.error('error booking item', err)
    }
}
function* renterConfirm(action) {
    try{
        yield axios.post('/api/renter', action.payload)
    }
    catch (err) {
        console.error('renter booking item failed', err)
    }
}

function* renterFetchBookingConfirm (action) {
    try{
        const result = yield axios.get(`/api/renter/confirm/${action.payload}`)

        yield put({
            type: "SET_BOOKING_CONFIRMATION",
            payload: result.data
        })

    }
    catch (err) {
        console.error('renter fetching booking failed', err)
    }
}


export default bookingItem;