import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
function DonationCompleted() {
    const query = useQuery();
    const dispatch = useDispatch();
    const item = query.get('item');
    const confirmation = query.get('confirmation-number');
    const bookableItem = useSelector((store) => store.selectedBookableItem);
    const [formattedPhone, setFormattedPhone] = useState('');
    useEffect(() => {
      setFormattedPhone(bookableItem && bookableItem.phone_number_1 ? ' or ('
          + bookableItem.phone_number_1.slice(0, 3) + ') ' + bookableItem?.phone_number_1?.slice(3, 6) + '-'
          + bookableItem?.phone_number_1?.slice(6): '');
    }, [bookableItem?.phone_number_1])
    useEffect(() => {
      dispatch({
        type: 'FETCH_SELECTED_BOOKABLE_ITEM',
        payload: item
      });
    }, [item]);

    return <>
        <Container align="center" maxWidth="lg">
            <Box marginTop={2} marginBottom={2}>
                <Typography variant='h5'>
                Thank you for your donation!
                </Typography>
            </Box>
        </Container>
        <Container maxWidth="sm">
            <Grid container>
                <Grid>
                <Typography>
                    <Box marginBottom={2}>
                    A confirmation email has been sent to you.
                    </Box>
                </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography>
                    <b>Confirmation Number:</b>
                </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography>
                    {confirmation}
                </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography>
                    <b>Booked Item:</b>
                </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography>
                    {bookableItem.title}
                </Typography>
                </Grid>
            </Grid>
        </Container>
        <Container maxWidth="sm">
            <Box marginTop={2}>
                <Typography>
                <Box sx={{ fontWeight: 'bold' }}>
                    If you have any questions, please contact us at deanwadelman@gmail.com.
                </Box>
                </Typography>
            </Box>
        </Container>
    </>
}

export default DonationCompleted
