import {useDispatch} from "react-redux";
import React, {useState} from "react";
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {Button} from "@material-ui/core";
import SelectTax from "../ManageTax/SelectTax";

const AddonInputRow = ({ addon, index, setAddons, updateAddOn, handleDelete, isEdit = false }) => {
    const dispatch = useDispatch();
    const [description, setDescription] = useState(addon?.description ?? '') //addon.description
    const [price, setPrice] = useState(addon?.price ?? '') //addon.price
    const [title, setTitle] = useState(addon?.title ?? '') //addon.title
    const [available, setAvailable] = useState(addon?.available ?? true) //addon.available

    const changeFiles = (e) => {
        const fileList = [];
        for(let file of e.target.files){
            fileList.push(file)
        }
        dispatch({
            type: 'ADD_FORM_ONCHANGE_ADD_ON_PHOTOS',
            payload: { property: 'addOnPhotos', value: fileList.map(file => {
                file.addOn = true;
                file.index = index;
                return file;
            }),
            index: index,
        }
        });
    }

    return (
        <Box justifyContent='center' padding={3} key={index}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        placeholder='Title'
                        value={title}
                        onChange={event => {
                            setTitle(event.target.value);
                            setAddons(a => {
                                a[index].title = event.target.value;
                                if(updateAddOn)updateAddOn(a, 'addOns');
                                return a;
                            });
                        }} />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        placeholder='Description'
                        value={description}
                        multiline
                        maxRows={5}
                        onChange={event => {
                            setDescription(event.target.value);
                            setAddons(a => {
                                a[index].description = event.target.value;
                                if(updateAddOn)updateAddOn(a, 'addOns');
                                return a;
                            });
                        }} />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        placeholder='Price'
                        value={price}
                        type='number'
                        onChange={event => {
                            setPrice(event.target.value)
                            setAddons(a => {
                                a[index].price = event.target.value;
                                if(updateAddOn)updateAddOn(a, 'addOns');
                                return a;
                            });
                        }} />
                </Grid>
                <Grid item xs={8}>
                    <FormControl fullWidth>
                        <SelectTax currentTaxSelection={addon.tax} editMode={isEdit} selectionWidth={300} onTaxChange={val => {
                            setAddons(a => {
                                a[index].tax = val;
                                if(updateAddOn)updateAddOn(a, 'addOnTax');
                                return a;
                            })
                        }} />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container margin={3}>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={<Checkbox checked={available} onChange={event => {
                            setAvailable(prev => !prev);
                            setAddons(a => {
                                a[index].price = event.target.value;
                                if(updateAddOn)updateAddOn(a, 'addOns');
                                return a;
                            });
                        }} />}
                        label="Available"
                        labelPlacement="start"
                    />
                </Grid>
                <Grid item xs={4}>
                    <input type="file" accept="image/png, image/jpeg" multiple onChange={e => changeFiles(e)}/>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" onClick={() => handleDelete(index)}>Delete</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AddonInputRow;
