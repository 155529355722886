import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import BuildTreeView from "./BuildTreeView";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from "@mui/lab";
import {Snackbar} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import Container from '@mui/material/Container';
import {Grid} from "@material-ui/core";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import UploadPhotos from "../UploadPhotos/UploadPhotos";

const textFieldStyle = {
    style: {
        textAlign: 'left'
    }
}

const initialState = {
    selectedInitial: {
        name: '',
        id: null,
        parentId: null,
        picKey: ''
    },
    addOrEditInitial: {
        add: true,
        edit: true
    },
    nextCategoryInitial: {
        name: '',
        pic: null
    }
}

//todo: notification
//todo: error-handle
function ManageCategory() {
    const dispatch = useDispatch();
    const history = useHistory();

    const {data: categoryList} = useSelector(({categoryList: list}) => list);
    const [categoryToAdd, setCategoryToAdd] = useState(initialState.nextCategoryInitial);
    const [categoryToEdit, setCategoryToEdit] = useState(initialState.nextCategoryInitial);
    const [selected, setSelected] = useState(initialState.selectedInitial);
    const [editOrAddDisabled, setEditOrAddDisabled] = useState(initialState.addOrEditInitial);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const { id: clientId } = useParams();
    const clientName = useSelector(({clients}) => clients).find(c => c.id === Number(clientId))?.username;

    const handleDropFiles = files => {
        while (files.length > 1) {
            files.pop();
        }
        if (!editOrAddDisabled.add) {
            setCategoryToAdd({
                ...categoryToAdd,
                pic: files[0]
            })
        }

        if (!editOrAddDisabled.edit) {
            setCategoryToEdit({
                ...categoryToEdit,
                pic: files[0]
            })
        }

    }

    useEffect(() => {
        dispatch({type: 'GET_CATEGORY_LIST_REQUEST', clientId });
    }, [dispatch]);

    useEffect(() => {
        if (!clientName) { // it must come from clients page
            history.push('/user');
        }
    }, [clientName])

    const handleSelect = (event, nodeIds) => {
        if (!nodeIds) {
            setSelected(initialState.selectedInitial);
        }
        const nextId = Number(nodeIds);
        setSelected(() => {
            const newSelected = categoryList.find(c => c.id === nextId);
            setCategoryToAdd(initialState.nextCategoryInitial);
            if (!newSelected) {
                return deselect();
            }
            setCategoryToEdit({
                ...categoryToEdit,
                name: newSelected.name
            });
            return newSelected;
        });
        setEditOrAddDisabled(initialState.addOrEditInitial);
    };

    const handleSave = () => {
        if (!editOrAddDisabled.add) {
            if (categoryToAdd.name.length && categoryToAdd.pic) {
                dispatch({
                    type: 'ADD_CATEGORY',
                    payload: {
                        name: categoryToAdd.name,
                        parentId: selected?.id || null,
                        clientId,
                        pic: categoryToAdd.pic
                    }
                });
            }
        }
        if (!editOrAddDisabled.edit) {
            dispatch({type: 'EDIT_CATEGORY', payload: {
                    name: categoryToEdit.name,
                    id: selected.id,
                    clientId,
                    pic: categoryToEdit.pic,
                    oldPic: selected.picKey
            }});
        }
    };

    const deselect = () => {
        setCategoryToEdit(initialState.nextCategoryInitial);
        return initialState.selectedInitial;
    }

    const handleAddOrEdit = callFrom => {
        if (callFrom === 'edit' && !selected.id) return;
        setEditOrAddDisabled({
            add: callFrom !== 'add',
            edit: callFrom !== 'edit'
        })
    }

    const handleDialogClose = flag => {
        setDialogOpen(false);
        if (flag) {
            handleDeleteCategory();
        }
    }

    const handleDeleteCategory = () => {
        const checkBookableItemExists = ({bookableItems, children}) =>
            bookableItems?.length ? true : (children && children.some(checkBookableItemExists));

        if (checkBookableItemExists(selected)) { // cannot delete if there is bookable item
            return;
        }

        dispatch({type: 'DELETE_CATEGORY', payload: {id: selected.id, clientId} });
    };

    const previewCategory = () => (
        <Card sx={{maxWidth: 345}}>
            {selected.picKey?.length ? (
                <CardMedia
                    component="img"
                    height="400"
                    image={`/categoryList/picKey/${selected.picKey}`}
                    alt={selected.name}
                />
            ) : null}
            <CardContent>
                {selected.name}
            </CardContent>
        </Card>
    )

    const categoryDetails = () => (
        <div>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    This is a success message!
                </Alert>
            </Snackbar>
            {!editOrAddDisabled.edit && (
                <TextField
                    id="selected"
                    label="Category Name"
                    value={categoryToEdit.name}
                    inputProps={textFieldStyle}
                    onChange={event => setCategoryToEdit({
                        ...categoryToEdit,
                        name: event.target.value
                    })}
                />
            )}
            {!editOrAddDisabled.add && (
                <TextField
                    id="newCategory"
                    label={selected.id ? 'Subcategory to Add' : 'Category to Add'}
                    value={categoryToAdd.name}
                    inputProps={textFieldStyle}
                    onChange={event => setCategoryToAdd({
                        ...categoryToAdd,
                        name: event.target.value
                    })}
                />
            )}
            {displayFilesHandler()}
            <Button disabled={editOrAddDisabled.add && editOrAddDisabled.edit}
                    onClick={() => handleSave()}>Save</Button>
            <ButtonGroup variant="contained" aria-label="outlined primary button group"  style={{ margin: '10px' }}>
                <Button onClick={() => handleAddOrEdit('edit')} disabled={!selected.id}>
                    Edit Category
                </Button>
                <Button onClick={() => handleAddOrEdit('add')}>
                    {selected.id ? 'Add Subcategory' : 'Add Category'}
                </Button>
                <Button onClick={() => setDialogOpen(true)} disabled={!selected.id}>
                    Delete Category
                </Button>
            </ButtonGroup>
        </div>
    )

    const displayFilesHandler = () => (
        <Grid container maxWidth='md' mx='auto' direction='column' spacing={2}>
            <Grid item>
                <UploadPhotos
                    disableFlag={editOrAddDisabled.add && editOrAddDisabled.edit}
                    handleDropFiles={handleDropFiles}
                    maxFiles={1}
                />
            </Grid>
        </Grid>
    )

    return (
        <Container>
            <Dialog
                open={dialogOpen}
                onClose={() => handleDialogClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to delete category and all subcategories underneath? This cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
                    <Button onClick={() => handleDialogClose(true)} >
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ margin: '10px' }}>
                <BuildTreeView
                    categoryList={categoryList}
                    handleSelect={handleSelect}
                    selectId={selected.id}
                    clientName={clientName}
                    callFrom={'category'}
                />
            </div>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {categoryDetails()}
                    </Grid>
                    <Grid item xs={6}>
                        {selected.id ? previewCategory() : null}
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}

export default ManageCategory;
