import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import passwordCheck from '../../helpers/password.helper'

const PasswordResetForm = () => {
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [message, setMessage] = useState(null);
  const { id, token } = useParams();
  const errors = useSelector(store => store.errors);

  const resetPassword = async (e) => {
    e.preventDefault();
    setMessage(null)
    if (password !== verifyPassword) {
      setMessage('Password do not match.');
      return
    }
    if (!passwordCheck(password)) {
      setMessage('Needs Strong Password');
      return
    }
    const newPW = await axios.post(`/api/reset-password/new-password`, { id, token, password });
    setMessage(newPW.data);
    console.log(newPW);
  }

  return (
    <>
      <>
        <div className="loginView">
          <form className="formPanel" onSubmit={resetPassword}>
            <Typography component="h2" variant="h4" align="center" mt={2}>
              Reset Password
            </Typography>

            {message && (
              <h3 className="alert" role="alert">
                {message}
              </h3>
            )}
            <Grid container maxWidth="md" mx="auto" direction="column" alignItems="center" my={4}>
              {/* <Grid item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Password"
                    type="password"
                    name="password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </FormControl>
              </Grid> */}

              <Grid item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Password"
                    type="password"
                    name="password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label=" Confirm Password"
                    type="password"
                    name="password"
                    value={verifyPassword}
                    required
                    onChange={(event) => setVerifyPassword(event.target.value)}
                  />

                  <Typography component="p" variant="p" align="center" mt={2}>
                    Requirements
                  </Typography>
                  <Typography component="li" variant="li" align="center" mt={2}>
                    At least one uppercase letter
                  </Typography>
                  <Typography component="li" variant="li" align="center" mt={2}>
                    At least one lowercase letter.
                  </Typography>
                  <Typography component="li" variant="li" align="center" mt={2}>
                    At least one special character.
                  </Typography>
                  <Typography component="li" variant="li" align="center" mt={2}>
                    At least eight characters long.
                  </Typography>

                </FormControl>
              </Grid>


              <Grid item>
                <FormControl margin="normal">

                  <Button type="submit" variant="contained" size="large" sx={{ width: '100%' }}
                    style={{ margin: '0 auto', display: "flex" }} >
                    Reset Password
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </form>

        </div>
      </>
    </>
  );

}

export default PasswordResetForm;