export const timeZoneSelection = {
    est: 'Eastern Standard Time (EST)',
    pst: 'Pacific Standard Time (PST)',
    cst: 'Central Standard Time (CST)',
    mst: 'Mountain Standard Time (MST)',
    ast: 'Atlantic Standard Time (AST)',
    akst: 'Alaskan Standard Time (AKST)',
    hst: 'Hawaii-Aleutian Standard Time (HST)',
    sst: 'Samoa standard time (UTC-11)',
    chamorrost: 'Chamorro Standard Time (UTC+10)'
}