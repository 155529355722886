import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Nav.css';

const ClientNav = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((store) => store.user);

    // Manage state for nav menu items that do not have submenu items
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    // Handle open and close for nav menu items that do not have submenu items
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    // Manage state, click, anchor, and open & close status for "Reports" menu items - in medium display
    const [anchorElReports, setAnchorElReports] = React.useState(null);
    const [anchorElRequests, setAnchorElRequests] = React.useState(null)

    const openReports = Boolean(anchorElReports);
    const openRequests = Boolean(anchorElRequests)
    const handleClickReports = (event) => {
      setAnchorElReports(event.currentTarget);
    };
    const handleCloseReports = () => {
      setAnchorElReports(null);
    };

    const handleClickRequests = (event) => {
        setAnchorElRequests(event.currentTarget);
    };
    const handleCloseRequests = () => {
        setAnchorElRequests(null);
    };



    // Manage state, click, and open & close status for "Reports" submenu item in extra small display
    const [openXSReports, setOpenXSReports] = React.useState(false);
    const [openXSRequests, setOpenXSRequests] = React.useState(false);
    const handleClickXSReports = () => {
      setOpenXSReports(!openXSReports);
    };

    const handleClickXSRequests = () => {
        setOpenXSRequests(!openXSRequests);
    };

    const logout = () => {
        history.push('/')
        dispatch({ type: 'LOGOUT' });
    }

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    {/* For medium display, logo and "home" link are on left. */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <Link to="/home">
                            <img src="./assets/images/eazie-book-logo-horizontal.png" id="logo" />
                        </Link>
                    </Box>

                    {/* Hamburger menu for extra small display */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, color: 'black' }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <List
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton sx={{ width: 300 }} onClick={handleCloseNavMenu} component={Link} to="/home">
                                    <ListItemText primary="Bookings" />
                                </ListItemButton>
                                <ListItemButton sx={{ width: 300 }} onClick={handleCloseNavMenu} component={Link} to={`/viewBookableItem/${user.id}`}>
                                    <ListItemText primary="Bookables" />
                                </ListItemButton>
                                <ListItemButton sx={{ width: 300 }} onClick={handleCloseNavMenu} component={Link} to="/renterInfo">
                                    <ListItemText primary="Customers" />
                                </ListItemButton>
                                

                                {/* "Reports" menu item */}
                                <ListItemButton sx={{ width: 300 }} onClick={handleClickXSReports}>
                                    <ListItemText primary="Reports" />
                                    {openXSReports ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItemButton>
                                <Collapse in={openXSReports} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/reports/financial" sx={{ pl: 4 }}>
                                            <ListItemText primary="Financial" />
                                        </ListItemButton>
                                    </List>
                                    {/* <List component="div" disablePadding>
                                        <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/client/reports/ytd-financial" sx={{ pl: 4 }}>
                                            <ListItemText primary="YTD Financial" />
                                        </ListItemButton>
                                    </List> */}
                                </Collapse> 
                                {/* <ListItemButton sx={{ width: 300 }} onClick={handleCloseNavMenu} component={Link} to="/clientPhotos">
                                    <ListItemText primary="Photos" />
                                </ListItemButton> */}

                                <ListItemButton sx={{ width: 300 }} onClick={handleClickXSRequests}>
                                    <ListItemText primary="Requests" />
                                    {openXSRequests ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItemButton>
                                <Collapse in={openXSRequests} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/client/requests/bookableItem" sx={{ pl: 4 }}>
                                            <ListItemText primary="Submit item request" />
                                        </ListItemButton>
                                    </List>
                                    <List component="div" disablePadding>
                                        <ListItemButton onClick={handleCloseNavMenu} component={Link} to="/client/requests/view" sx={{ pl: 4 }}>
                                            <ListItemText primary="View Item Requests" />
                                        </ListItemButton>
                                    </List>
                                </Collapse> 
                                <ListItemButton sx={{ width: 300 }} onClick={handleCloseNavMenu} component={Link} to={`/editClient/${user.id}`}>
                                    <ListItemText primary="Profile" />
                                </ListItemButton>


                            </List>
                        </Menu>
                    </Box>

                    {/* For extra small display, the logo and "home" link appear in the middle */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, mr: 1 }}>
                        <Link to="/home">
                            <img src="./assets/images/eazie-book-logo-horizontal.png" id="logo" />
                        </Link>
                    </Box>

                    {/* Menu for medium display */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                      <Button
                            id="bookings-button"
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                            component={Link} to="/home"
                        >
                            Bookings
                        </Button>                      
                        <Button
                            id="bookables-button"
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                            component={Link} to={`/viewBookableItem/${user.id}`}
                        >
                            Bookables
                        </Button>
                        <Button
                            id="customers-button"
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                            component={Link} to="/renterInfo"
                        >
                            Customers
                        </Button>

                        {/* "Reports" menu item on nav bar */}
                        <Button
                            id="reports-button"
                            onClick={handleClickReports}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Reports
                        </Button>
                        <Menu
                            id="reports-menu"
                            anchorEl={anchorElReports}
                            open={openReports}
                            onClose={handleCloseReports}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleCloseReports} component={Link} to="/reports/financial">Financial</MenuItem>
                        </Menu> 
                        {/* <Button
                            id="reports-menu"
                            // onClick={handleClickCustomers}
                            component={Link} to="/reports/financial"
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Financial Reports
                        </Button> */}
                        {/* <Button
                            id="photos-button"
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                            component={Link} to="/clientPhotos"
                        >
                            Photos
                        </Button> */}
                        <Button
                            id="requests-button"
                            onClick={handleClickRequests}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Requests
                        </Button>
                        <Menu
                            id="request-menu"
                            anchorEl={anchorElRequests}
                            open={openRequests}
                            onClose={handleCloseRequests}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleCloseRequests} component={Link} to="/client/requests/bookableItem">Submit Item Request</MenuItem>
                            <MenuItem onClick={handleCloseRequests} component={Link} to="/client/requests/view">View Item Requests</MenuItem>

                        </Menu> 
                        <Button
                            id="profile-button"
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                            component={Link} to= {`/editClient/${user.id}`}
                        >
                            Profile
                        </Button>
                        

                    </Box>

                    {/* "Log Out" link is right justified in the App Bar */}
                    <Box sx={{ flexGrow: 0, color: 'black' }}>
                        <Button sx={{ color: 'black' }} id="signOut" onClick={logout}>
                            Log Out
                        </Button>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default ClientNav