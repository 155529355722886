import React, { useEffect, useState } from 'react';
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js/pure";
import ManageCoupons from '../ManageCoupons/ManageCoupons'
import ClientPhotos from '../ClientPhotos/ClientPhotos'
import Payment from '../Payment/Payment';
import CompletedPayment from '../Payment/CompletedPayment';
import RenterViewBookableItem from '../Payment/RenterViewBookableItem';
import LoadingPage from '../Payment/Loading'
import SignUp from '../SignUp/SignUp'
import Footer from '../Footer/Footer';
import RenterFooter from '../Footer/RenterFooter';
import RenterHistory from '../RenterHistory/RenterHistory'
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import DepositClaim from '../DepositClaim/DepositClaim'
import UserPage from '../UserPage/UserPage';
import LoginPage from '../LoginPage/LoginPage';
import LandingPage from '../LandingPage/LandingPage';
import RegisterPage from '../RegisterPage/RegisterPage';
import InviteClient from '../InviteClient/InviteClient';
import EditClient from '../EditClient/EditClient';
import AddBookableItem from '../AddBookableItem/AddBookableItem';
import ManageCategory from "../ManageCategory/ManageCategory";
import EditBookableItemForm from '../EditBookableItemForm/EditBookableItemForm'
import ViewBookableItem from '../ViewBookableItem/ViewBookableItem';
import ViewBookedItems from '../ClientBookedItems/ViewBookedItems';
import ClientTable from '../ClientTable/ClientTable';
import ClientBookableItems from '../ClientBookableItems/ClientBookableItems';
import ClientDetailItem from '../ClientDetailItem/ClientDetailItem';
import RenterReviewPage from '../RenterReviewPage/RenterReviewPage';
import RenterReservation from "../RenterReservation/RenterReservation";
import ClientBookedItems from "../ClientBookedItems/ClientBookedItems";
import ThankYou from '../ThankYou/ThankYou';
import RenterInfo from '../RenterInfo/RenterInfo';
import AddPhoto from '../AddPhoto/AddPhoto';
import CompanyNav from '../Nav/CompanyNav';
import ResponsiveAppBar from '../Nav/ResponsiveAppBar';
import AdminCustomers from '../AdminCustomers/AdminCustomers';
import FinancialTable from '../Reports/FinancialTable'
import PasswordReset from '../PasswordReset/PasswordReset';
import PasswordResetForm from '../PasswordReset/PasswordResetForm';
import BookedItemDetail from '../ClientBookedItems/BookedItemDetail';
import BookableItemRequest from '../BookableItemRequest/BookableItemRequest'
import ItemRequestTable from '../BookableItemRequest/ItemRequestTable'
import ItemRequestDetail from '../BookableItemRequest/ItemRequestDetail'
import ClientViewRequests from '../ClientViewRequests/ClientViewRequests'
import ViewContact from '../ViewContact/ViewContact';
import './App.css';
import { LicenseInfo } from '@mui/x-license-pro';
import ContactForm from '../Contact/ContactForm';
import ManageTax from "../ManageTax/ManageTax";
import BlockCalendar from "../BlockCalendar/BlockCalendar";
import SeasonalPricing from "../SeasonalPricing/SeasonalPricing";
import BookableItemCustom from "../BookableItemCustom/BookableItemCustom";
import DonationPayment from "../Payment/Donation/DonationPayment";
import DonationCompleted from '../Payment/Donation/DonationCompleted';

LicenseInfo.setLicenseKey('f4eda861c3f5bf1bbad59c0a40174a52Tz03MTM4NyxFPTE3MjE4NzUzOTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export default function App() {
  const dispatch = useDispatch();
  const user = useSelector(store => store.user);
  const {authLevel} = user;
  const {account_number, demo: isDemo} = useSelector(store => store.accountWrapper);
  const [stripeObject, setStripeObject] = useState(null);

  useEffect(() => {
    dispatch({ type: 'FETCH_USER' });
    console.log('STRIPE', stripeObject);
  }, [dispatch]);

  useEffect(() => {
    const fetchStripeObject = async () => {
      console.log('###APP ACCT NO', account_number);
      if (account_number) {
        const stripePK = isDemo ? process.env.REACT_APP_STRIPE_TEST_PK : process.env.REACT_APP_STRIPE_PK;
        const stripePromise = await loadStripe(stripePK, {
          stripeAccount: account_number
        });
        setStripeObject(stripePromise)
      }
    }
    fetchStripeObject();
  }, [account_number, isDemo]);
  
  return (
  <>
    <Router>
      {/*
      If user is authorized (i.e., the user is either a client or admin),
      then show the ResponsiveAppBar component.
      The ResponsiveAppBar component will render either the ClientNav or
      the SuperAdminNav component, depending on whether the client or the
      admin is logged in.
      If the user is not authorized, then do not show the ResponsiveAppBar
      component. In other words, if the user is not authoried, then it is a
      public user visiting our landing page (our corporate website page).
      */}
      {authLevel && <ResponsiveAppBar />}
        <Switch>

          {/*  If the user is logged in, then redirect to the /user page.
              If user is not logged in, then render the login page. 
              The "LoginPage" component includes the CompanyNav component.
              So, the Login Page always shows the CompanyNav. */}
          <Route exact path="/login" >
            {!user.id ? <LoginPage /> : <Redirect to="/user" />}
          </Route>
          
          {/* If user is logged in, then redirect to /user page.
          If use is not logged in, then render the nav bar for the company and render Landing page */}
          <Route exact path="/home">
            { <LandingPage user={user} />}
          </Route>


          {/* If the user is already logged in, redirect them to the /user
               page Otherwise, show the registration page */}
          <Route exact path="/registration">
            {user.id ? <Redirect to="/user" /> : <RegisterPage />}
          </Route>
          <Route exact path="/checkout/:id">
            {stripeObject ?
              <Elements stripe={stripeObject}>
                <Payment />
                <RenterFooter />
              </Elements>
              : <LoadingPage />
            }
          </Route>
          <Route exact path="/checkout-donation/:id">
            {stripeObject ?
              <Elements stripe={stripeObject}>
                <DonationPayment />
                <RenterFooter />
              </Elements>
              : <LoadingPage />
            }
          </Route>

          <Route exact path='/donation-completed/'>
            <DonationCompleted />
            <RenterFooter />
          </Route>

          <Route exact path='/checkout-completed/'>
            <CompletedPayment />
            <RenterFooter />
          </Route>

          <Route exact path='/book-item/:id'>
            <RenterViewBookableItem />
            <RenterFooter />
          </Route>

          <Route exact path='/block-calendar/:id'>
            {authLevel === 'admin' || authLevel ==='client'
                ? <BlockCalendar />
                : <Redirect to='/user' />
            }
          </Route>
          <Route exact path='/seasonal-pricing/:id'>
            <SeasonalPricing />
          </Route>

          <Route exact path='/renterReservation/:id'>
            <RenterReservation />
            <RenterFooter />
          </Route>

          <Route path='/book-item-custom/:id'>
            <BookableItemCustom />
            <RenterFooter />
          </Route>

          {/* Visiting localhost:3000 will redirect to localhost:3000/home */}
          <Redirect exact from="/" to="/home" />

          <ProtectedRoute exact path="/renterInfo">
            {authLevel === 'client'
              ? <RenterInfo />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          <ProtectedRoute exact path='/client/requests/view'>
            {authLevel === 'client'
              ? <ClientViewRequests />
              : <Redirect to='/user' />

            }
          </ProtectedRoute>

          <ProtectedRoute exact path="/clientPhotos">
            {authLevel === 'client'
              ? <ClientPhotos />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          
          <ProtectedRoute exact path="/client/requests/bookableItem">
            {authLevel === 'client'
              ? <BookableItemRequest />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          {/* ROUTES FOR ADMIN */}


          {/* logged in shows UserPage else shows LoginPage */}
          <ProtectedRoute exact path="/user">
            <UserPage />
          </ProtectedRoute>

          
          {/* ROUTE for client invite page */}
          <ProtectedRoute exact path='/admin/invite'>

            {authLevel === 'admin'
              ? <InviteClient />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          <ProtectedRoute exact path='/admin/itemRequestTable'>
            {authLevel === 'admin'
              ? <ItemRequestTable />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          {/* ROUTE for admin to view contacts. */}
          <ProtectedRoute exact path='/admin/viewContact'>
            {authLevel === 'admin'
              ? <ViewContact />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          <ProtectedRoute exact path="/admin/itemRequestTable/detail/:id">
            {authLevel === 'admin' || authLevel === 'client'
              ? <ItemRequestDetail />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          <ProtectedRoute exact path="/manageCoupons/:id">
            {authLevel === 'admin'
              ? <ManageCoupons />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          {/* ROUTE for client table */}
          <ProtectedRoute exact path="/clients">
            {authLevel === 'admin'
              ? <ClientTable />
              : <Redirect to='/user' />
            }
            <ClientTable />
          </ProtectedRoute>

          <ProtectedRoute exact path="/editClient/:id">
            {authLevel === 'admin' || authLevel === 'client'
              ? <EditClient />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          {/* ROUTE for bookableItem page */}
          <ProtectedRoute exact path="/viewBookableItem/:id">
            <ViewBookableItem />
          </ProtectedRoute>

          {/* ROUTE for super admin checking clients' booked items page */}
          <ProtectedRoute exact path="/viewBookedItem/:id">
            {authLevel === 'admin'
              ? <ViewBookedItems />
              : <Redirect to='/user' />}
          </ProtectedRoute>

          {/* ROUTE for add bookable item */}
          <ProtectedRoute exact path="/addBookableItem/:id">
            {authLevel === 'admin'
              ? <AddBookableItem />
              : <Redirect to='/user' />}
          </ProtectedRoute>

          {/* ROUTE for managing category */}
          <ProtectedRoute exact path="/manageCategory/:id">
            {authLevel === 'admin'
              ? <ManageCategory />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          <ProtectedRoute exact path="/manageTax/:id">
            {authLevel === 'admin'
                ? <ManageTax />
                : <Redirect to='/user' />
            }
          </ProtectedRoute>

          {/* ROUTE for editing an item */}
          <ProtectedRoute exact path="/editBookableItemForm/:id">
            {authLevel === 'admin' || authLevel ==='client'
              ? <EditBookableItemForm />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

            

          <Route exact path='/renterReservation/:id'>
            <RenterReservation />
          </Route>

          <ProtectedRoute exact path='/clientBookedItems/:id'>
            {authLevel === 'client'
              ? <ClientBookedItems cancelButtonFlag={false} />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>
          <ProtectedRoute exact path='/claimDamageDeposit/:id'>
            {authLevel === 'client' || authLevel === 'admin'
              ? <DepositClaim />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>
          

          <ProtectedRoute exact path="/admin/categoryInput">
            {/* <CategoryInput /> */}
          </ProtectedRoute>

          <ProtectedRoute exact path="/addPhotos/:id">
            <AddPhoto />
          </ProtectedRoute>

          {/* logged in shows InfoPage else shows LoginPage */}
          <ProtectedRoute exact path="/info">
            <Route>
              <EditBookableItemForm />
            </Route>
          </ProtectedRoute>

          {/* Route for list of customers */}
          <ProtectedRoute exact path="/admin/customers">
            {authLevel === 'admin' ?
              <AdminCustomers />
              :
              <Redirect to='/user' />
            }
          </ProtectedRoute>

          {/* ROUTES FOR CLIENT */}
          <ProtectedRoute exact path="/clientBookableItems">
            {authLevel === 'client'
              ? <ClientBookableItems />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          {/* ROUTE for specific client bookable items */}
          <ProtectedRoute exact path="/clientBookableItems/:id">
            {authLevel === 'client'
              ? <ClientDetailItem />
              : <Redirect to='/user' />
            }
          </ProtectedRoute>

          <ProtectedRoute exact path="/booked-item/detail/:id">
            <BookedItemDetail />
          </ProtectedRoute>

          {/* SHARED ROUTES, NOT PUBLIC */}

          {/* Route for Financial report */}
          <ProtectedRoute exact path="/reports/financial">
            {authLevel === 'client' || authLevel === 'admin' ?
              <FinancialTable />
              :
              <Redirect to='/user' />
            }
          </ProtectedRoute>

          {/* ROUTES FOR RENTER */}

          <Route exact path='/renterHistory'>
            <RenterHistory />
          </Route>

          <ProtectedRoute exact path='/renterHistory'>
            <RenterHistory />
          </ProtectedRoute>

          <Route exact path='/reset-password'>
            <CompanyNav />
            <PasswordReset />
          </Route>
          <Route exact path='/new-password/:id/:token'>
            <CompanyNav />
            <PasswordResetForm />
          </Route>

          <Route exact path="/renterReviewPage/:id">
            <RenterReviewPage />
          </Route>

          <Route exact path="/thankyou/:id">
            <ThankYou />
          </Route>

          {/* Route for the EazieBook demo website */}
          <Route exact path="/contact">
            <ContactForm />
          </Route>

          <Route exact path="/signUp">
            <SignUp />
          </Route>

          {/* If none of the other routes matched, we will show a 404. */}
          <Route>
            <h1>404</h1>
          </Route>
        </Switch>
      </Router>

      {/* Only show footer if user is logged in.
      TODO: Create separate footer for company web pages. */}
      {authLevel ? <Footer /> : null}
  </>
  );
}
