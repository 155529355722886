import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ClientTable from '../ClientTable/ClientTable';
import LandingPage from '../LandingPage/LandingPage';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import './UserPage.css';
import ClientBookedItems from "../ClientBookedItems/ClientBookedItems";
import StripeOnboardButton from '../InviteClient/StripeOnboardButton';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function UserPage() {
    const user = useSelector(store => store.user);
    const {isLoading} = useSelector(store => store.loadingReducer);
    const [onboardComplete, setOnboardComplete] = useState(true);
    const history = useHistory();
    const { authLevel, id } = user;

    useEffect(() => {
        const init = async () => {
            const onboardCheck = await axios.get('/stripe/account-number')
            setOnboardComplete(onboardCheck.data)
        }
        init();
    }, []);

    const createBooking = () => {
        history.push(`/renterReservation/${id}`);
    }

    return (
        <>
            {isLoading ?
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
                :
                <div>
                    <div>
                        {authLevel === 'client' && !onboardComplete && <StripeOnboardButton />}
                    </div>
                    <div className="containerBtn">
                        <Typography padding='4px' variant='h3' align='center' >Booked Items</Typography>
                        {authLevel === 'admin' && (
                            <>
                                <div className='adminTableContainer'>
                                    <div className="table-responsive">
                                        <ClientTable />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {authLevel === 'client' && (
                            <>
                                <div className='clientTableContainer'>
                                    <div id='booking-toolbar'>
                                        <Button sx={{alignSelf: 'center'}} variant="contained" onClick={createBooking}>Create New Booking</Button>
                                    </div>
                                    <div className="table-responsive">
                                        <ClientBookedItems cancelButtonFlag={true} userId={id} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {authLevel === 'renter' && (
                            <>
                                <div className='renterLandingPage'>
                                    <LandingPage/>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            }
        </>
    );
}

// this allows us to use <App /> in index.js
export default UserPage;
