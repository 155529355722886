// *money* needs to be formatted in pennies
//if not, just pass true to the mult parameter to handle multiplication
export default function formatMoney(money, mult){
    let negative = false
    if(!money || money === 0)return '$0.00';
    if(money < 0){
        negative = true;
        money = Math.round(money * -1);
    }
    mult ? money = String(Math.round(100 * money)) : money = String(money);
    if(money < 10)return negative ? `-$0.0${money}` : `$0.0${money}`;
    let formattedMoney = money.replace(/^(\d{0,2})((?:\d{3})*)(\d{2})$/g, '$1');
    const middle = money.replace(/^(\d{0,2})((?:\d{3})*)(\d{2})$/g, '$2');
    const end = money.replace(/^(\d{0,2})((?:\d{3})*)(\d{2})$/g, '$3');
    if(middle && formattedMoney)formattedMoney += ',';
    for(let i = 0; i < middle.length; i++){
        if((i + 1) % 3 === 0 && i !== middle.length - 1){
            formattedMoney += `${middle[i]},`;
        } else {
            formattedMoney += middle[i];
        }
    }
    if(formattedMoney){
        return negative ? `-$${formattedMoney}.${end}` : `$${formattedMoney}.${end}`;
    } else {
        return negative ? `-$0.${end}` : `$0.${end}`;
    }
}

export const intlNumFormat = (number) => {
    //stolen from Stack
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    }).format(number);;;
}