import * as React from 'react';
import {useHistory} from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from "@mui/material/CardMedia";

const CardViewGrid = props => {
    const history = useHistory();
    const {updateScreen, categoryOnScreen} = props;
    const list = categoryOnScreen.map(element =>
        element.categoryFlag ? element : ({
            ...element,
            name: element.title
        })
    );
    const buttonText = item => item.categoryFlag ? 'View' : 'Book';

    const handleOnClick = item => {
        if (item.categoryFlag) {
            updateScreen(item);
        } else {
            //TODO fix id to itemId if 
            history.push(`/book-item/${item.id}`)
        }
    }

    const card = item => (
        <Grid item xs={12} sm={6} key={`${item.id}${item.name}`} sx={{width: 460, height: 560, marginTop: 2}}>
            <Card>
                {item.picKey?.length ? (
                    <CardMedia
                        component="img"
                        height="460"
                        image={`/categoryList/picKey/${typeof item.picKey ==='string' ? item.picKey : item.picKey[0]}`}
                        alt={item.name}
                    />
                ) : null}
                <CardContent>
                    {item.name}
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={() => handleOnClick(item)}>{buttonText(item)}</Button>
                </CardActions>
            </Card>
        </Grid>
    );

    return (
        <Box>
            <Grid container spacing={2}>
                {list.map(card)}
            </Grid>
        </Box>
    )
}

export default CardViewGrid;