const activeClient = (state= {}, action) => {
    switch(action.type){
        case 'SELECTED_CLIENT' :
            return action.payload;
        case 'EDIT_CLIENT_ONCHANGE':
            return {...state, [action.payload.property]: action.payload.value}
    }
    return state;
}

export default activeClient;