import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { render } from "react-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import ContactForm from '../Contact/ContactForm';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import './LandingPage.css';
import stripe from './images/stripe.png';
import checkmark from './images/checkmark-200x216.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReactPlayer from 'react-player/file';



function LandingPage(props) {

  const history = useHistory();
  const dispatch = useDispatch();

  // Workaround for "Log In" link not work. "Menu" component, in hamburger, has "keepMounted".
  // When "Log In" is clicked, React is not able to unmount children. However, we need
  // "keepMounted" to be true because the "scroll to" logic in the hamburger depends on the 
  // hamburger menu components to be mounted. A refresh reloads the allows "Log In" to work.
  function handleLoginClick() {
    let path = `/login`;
    history.push(path);
    window.location.reload();
  }
  useEffect(() => {
    if (props.user?.id) {
      history.push('/user')
      window.location.reload()
    }
  }, [props, props.user]);
  function handleSignUpClick() {
    // let path = `/signup`;
    // history.push(path);
    // window.location.reload();
    history.push('/signUp')
    window.location.reload()
  }

  // This function is used to create an object that contains the card title and the image location..
  // The object is returned from this function.
  function createData(cardTitle, imageLocation) {
    return { cardTitle, imageLocation };
  }

  // Array of objects. Each object contains the card title and the image location.
  const cards = [
    createData('Hospitality', 'hospitality.jpeg'),
    createData('Cabin Rentals', 'cabin-rentals.jpeg'),
    createData('Jet Skis', 'jet-skis.jpeg'),
    createData('Boats', 'boats.jpeg'),
    createData('Motorhomes', 'motorhomes.jpeg'),
    createData('Kayaks', 'kayaks.jpeg'),
    createData('Massages', 'massages.jpeg'),
    createData('Spas', 'spas.jpeg'),
    createData('Dog Walkers', 'dog-walkers.jpeg'),
    createData('Bicycles', 'bicycles.jpeg')

  ];

  // This function is used to create an object that contains the QA Question and QA Answer.
  // The object is returned from this function.
  function createQAData(question, answer) {
    return { question, answer };
  }

  // Array of objects. Each object contains a QA question and a QA answer.
  const qaItems = [
    createQAData(
      'Can I use my credit card processor?',
      'Yes. We can work with your payment system you currently have or we have credit card processing available at a low rate.'
    ),
    createQAData(
      'Does EazieBook allow customers to pay some amount at the time of booking and pay the balance when they show up?',
      'Yes, if you desire, EazieBook allows you to specify some amount to charge customers when they book and then automatically charge the balance later. You may also choose to require customers to pay the entire amount at the time of booking.'
    ),
    createQAData(
      'Do you support damage deposits?',
      'Yes, EazieBook allows you to specify any amount of damage deposit to charge the customer.'
    ),
    createQAData(
      'What do I need to do for the implementation process?',
      'We have a couple of lines of software code that you put into your website. \
      You can either do it yourself or one of technical support specialists can do \
      it for you. If we do it for you, we will need access to your website.'
    ),
    createQAData(
      'How much does this cost me?',
      'You pay nothing for each booking. The customer is charged a small processing fee.'
    ),
    createQAData(
      'Will the funds be sent to me ASAP?  Or will there be a delay?',
      'The funds are sent to you directly from our credit card processor. The funds do not pass through us. Therefore, you are paid immediately after the credit card transaction is settled.'
    ),
    createQAData(
      'What if I do not have a website or my website needs updating?',
      'No problem! We will build you a professional-looking website or enhance your existing website.'
    ),
    createQAData(
      'Will you help with ongoing updates?',
      'Yes, after the initial installation, all the ongoing updates are free of charge.'
    )
  ];

  // For the list of items under "EazieBook Plugs into Your Website"
  const eaziebookYourWebsite = [
    'Built-in calendaring system',
    'Credit card checkout',
    'Easy booking experience for your customers',
    'Less hassle for you'
  ];

  // For the bullet list of "Why Eaziebook"
  const reasonsWhy = [
    'Get booking overnight when office is not open or your away from your computer',
    'Built-in calendar',
    'Built-in credit card processor',
    'Eliminates back-and-forth emails and calls (never miss another booking)',
    'Provides your customer and you with convenience',
    'Customers can book without contacting you'
  ];

  // For the bullet list of "Pricing"
  const pricing = [
    'We charge a 8% processing fee to your customers at booking time and in return we give 2% back to you',
    'One time set up fee $300 to $600 depending how many items to update',
    'There is no contract',
    'We can use your credit processors or ours'
  ];


  // Manage state for nav menu items that do not have submenu items
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  // Handle open and close for nav menu items that do not have submenu items
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    console.log("anchorElNav: " + anchorElNav);
  };
  const handleCloseNavMenu = () => {
    console.log("handleCloseNavMenu before setAnchorElNav: anchorElNav: " + anchorElNav);
    setAnchorElNav(null);
    console.log("handleCloseNavMenu after setAnchorElNav: anchorElNav: " + anchorElNav);
  };

  const faqRef = useRef();
  const pricingRef = useRef();
  const contactRef = useRef();
  const whyRef = useRef();

  // References for menu items in hamburger menu
  const contactButtonRef = useRef();
  const faqButtonRef = useRef();
  const whyButtonRef = useRef();
  const pricingButtonRef = useRef();

  // Listen for click event on Contact button in hamburger menu. Then close the hamburger menu
  // while "scroll to" is occuring. Dependent state of anchorElNav (dependent on hamburger menu
  // being open)
  useEffect(() => {
    const handleClick = event => {
      console.log('Hamburger Contact menu clicked');
      handleCloseNavMenu();
    };

    const element = contactButtonRef.current;

    if (element) {
      element.addEventListener('click', handleClick);
      return () => {
        element.removeEventListener('click', handleClick);
      };
    }

  }, [anchorElNav]);

  // Listen for click event on FAQ button in hamburger menu. Then close the hamburger menu
  // while "scroll to" is occuring. Dependent state of anchorElNav (dependent on hamburger menu
  // being open)
  useEffect(() => {
    const handleClick = event => {
      console.log('FAQ hamburger menu clicked');
      handleCloseNavMenu();
    };

    const element = faqButtonRef.current;

    if (element) {
      element.addEventListener('click', handleClick);
      return () => {
        element.removeEventListener('click', handleClick);
      };
    }

  }, [anchorElNav]);

  // Listen for click event on Why Eaziebook button in hamburger menu. Then close the hamburger menu
  // while "scroll to" is occuring. Dependent state of anchorElNav (dependent on hamburger menu
  // being open)
  useEffect(() => {
    const handleClick = event => {
      console.log('Why Eaziebook hamburger menu clicked');
      handleCloseNavMenu();
    };

    const element = whyButtonRef.current;

    if (element) {
      element.addEventListener('click', handleClick);
      return () => {
        element.removeEventListener('click', handleClick);
      };
    }

  }, [anchorElNav]);

  // Listen for click event on Pricing button in hamburger menu. Then close the hamburger menu
  // while "scroll to" is occuring. Dependent state of anchorElNav (dependent on hamburger menu
  // being open)
  useEffect(() => {
    const handleClick = event => {
      console.log('Pricing hamburger menu clicked');
      handleCloseNavMenu();
    };

    const element = pricingButtonRef.current;

    if (element) {
      element.addEventListener('click', handleClick);
      return () => {
        element.removeEventListener('click', handleClick);
      };
    }

  }, [anchorElNav]);

  function handleFAQClick() {
    faqRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  function handlePricingClick() {
    pricingRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  function handleContactClick() {
    contactRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  function handleWhyClick() {
    whyRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  function handleContactClickFromHamburger() {
    setAnchorElNav(null);
    const section = document.querySelector('#contact-form');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }



  const [openContact, setOpenContact] = React.useState(false);
  const handleClickOpenContact = () => {
    setOpenContact(true);
  };

  const handleCloseOpenContact = () => {
    setOpenContact(false);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://eaziebook-app.herokuapp.com/accessible-popup.4.1.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  // Settings for React Slick carousel/slider
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      {/* Navigation (Menus) */}
      <AppBar position="static" sx={{ backgroundColor: 'white' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>

            {/* For medium display, logo and "home" link are on left. */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
              <Link to="/home">
                <img src="./assets/images/eazie-book-logo-horizontal.png" id="logo" />
              </Link>
            </Box>

            {/* Menu for medium display */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                id="how-it-works-button"
                onClick={handleWhyClick}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                How it Works
              </Button>

              <Button
                id="faq-button"
                onClick={handleFAQClick}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                FAQ
              </Button>

              <Button
                id="contact-button"
                onClick={handlePricingClick}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Pricing
              </Button>

              <Button
                id="contact-button"
                onClick={handleContactClick}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Contact
              </Button>

            </Box>

            {/* Hamburger menu for extra small display */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, color: 'black' }}>
              <IconButton
                size="large"
                aria-label="hamburger menu for extra small display"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <List
                  sx={{ width: '100%', bgcolor: 'background.paper' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton ref={whyButtonRef} sx={{ width: 300 }} onClick={handleWhyClick} >
                    <ListItemText primary="How it Works" />
                  </ListItemButton>
                  <ListItemButton ref={faqButtonRef} sx={{ width: 300 }} onClick={handleFAQClick} >
                    <ListItemText primary="FAQ" />
                  </ListItemButton>
                  <ListItemButton ref={pricingButtonRef} sx={{ width: 300 }} onClick={handlePricingClick} >
                    <ListItemText primary="Pricing" />
                  </ListItemButton>
                  <ListItemButton ref={contactButtonRef} sx={{ width: 300 }} onClick={handleContactClick} >
                    <ListItemText primary="Contact" />
                  </ListItemButton>
                </List>
              </Menu>
            </Box>

            {/* For extra small display, the logo and "home" link appear in the middle */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, mr: 1 }}>
              <Link to="/home">
                <img src="./assets/images/eazie-book-logo-horizontal.png" id="logo" />
              </Link>
            </Box>

            {/* "Log In" link is right justified in the App Bar */}
            <Box sx={{ flexGrow: 0, color: 'black' }}>
              <Button sx={{ color: 'black' }} onClick={handleSignUpClick}>
                Sign Up
              </Button>
              <Button sx={{ color: 'black' }} onClick={handleLoginClick}>
                Log In
              </Button>
              {/* <Button onClick={handleLoginClick}>Log In</Button> */}
            </Box>

          </Toolbar>
        </Container>
      </AppBar>

      <div id="loader-wrapper">
        <div id="loading">
          <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
        </div>
      </div>
      {/* PAGE CONTENT
		============================================= */}
      <div id="page" className="page">
        {/* HEADER
			============================================= */}

        {/* TOP IMAGE & TAGLINE
			============================================= */}

        <Box sx={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: "url('assets/images/background.jpg')", height: 450 }}>
          {/* On meduium screen, display tagline horizontally with bullets */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'left', pl: 5, pr: 15 }}>
            <Stack sx={{ maxWidth: 800, mt: 8, color: 'black', background: 'white', opacity: 0.82, padding: 1.5 }}>
              <Typography variant="h3" align='center' gutterBottom={false}>
                The most reliable and secure online booking software for your website.
              </Typography>
            </Stack>
          </Box>
          {/* On x-small screen, display tagline vertially with no bullets */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'left', pl: 5 }}>
            <Stack sx={{ maxWidth: 400, mt: 8, color: 'black', background: 'white', opacity: 0.82, padding: 1.5 }}>
              <Typography variant="h4" align='left' gutterBottom={false}>
                The most reliable and secure online booking software for your website.
              </Typography>
            </Stack>
          </Box>
        </Box>

        {/* WORLD-CLASS ONLINE BOOKING
			============================================= */}

        <section id="content-2" className="wide-50 content-section division">
          <div className="container">
            <div className="row d-flex align-items-center">
              {/* IMAGE BLOCK */}
              <div className="col-md-5 col-lg-6">
                <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                  <img className="img-fluid" src="assets/images/eaziebook-calendar.png" alt="content-image" />
                </div>
              </div>
              {/* TEXT BLOCK */}
              <div className="col-md-7 col-lg-6">
                <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                  {/* Title */}
                  <h2 className="h2-md">Online Booking Made Eazie</h2>
                  <h5 className="h5-sm" style={{color: '#821E0D'}}><b>Easy for you to set up.</b></h5>
                  <h5 className="h5-sm" style={{color: '#821E0D'}}><b>Easy for your customers to navigate.</b></h5>

                  {/* Text */}
                  <p className="p-lg">
                    EazieBook is the most reliable and secure online booking system designed for any type of business that involves booking a service, activity, or rental product. Whether it's a boat rental, cabin rental, hair salon, or pet boarding business, EazieBook adds a professional and modern online booking capability to your website. EazieBook seamlessly plugs into your website, giving your customers the ability to make reservations directly on your website.
                    EazieBook provides an intuitive and effortless customer booking experience, tracks availability in real-time, sends real-time booking notifications, and includes comprehensive custom reports.
                  </p>
                  {/* Text */}
                  <p className="p-lg">
                    Grow your business by accepting bookings from anywhere. Do it all with EazieBook, along with unlimited, 24/7 support from our customer service team.
                  </p>
                  {/* Button */}
                  <a href="https://eaziebookdemo.com/" className="btn white-color btn-tra-red red-hover">See Live Demos
                  </a>
                </div>
              </div>	{/* END TEXT BLOCK */}
            </div>	   {/* End row */}
          </div>	   {/* End container */}
        </section>	{/* END CONTENT-2 */}

        {/* Carousel for "EazieBook Works in Any Industry"
			============================================= */}
        <div id="industries" className="bg_whitesmoke brands-section division">
          <div className="section-title text-center mb-70" style={{ paddingTop: 30 }}>
            {/* Title */}
            <h2 className="h2-md">EazieBook Works in Any Industry</h2>
          </div>
          <Slider {...settings}>
            <div className='text-center'>
              <img src="boats.jpeg" style={{ margin: 'auto' }} />
              <p style={{ fontSize: '2rem' }}>Boats</p>
            </div>
            <div className='text-center'>
              <img src="jet-skis.jpeg" style={{ margin: 'auto' }} />
              <p style={{ fontSize: '2rem' }}>Jet Skis</p>
            </div>
            <div className='text-center'>
              <img src="cabin-rentals.jpeg" style={{ margin: 'auto' }} />
              <p style={{ fontSize: '2rem' }}>Cabins</p>
            </div>
            <div className='text-center'>
              <img src="dog-hotel.jpg" style={{ margin: 'auto' }} />
              <p style={{ fontSize: '2rem' }}>Dog Hotels</p>
            </div>
            <div className='text-center'>
              <img src="hospitality.jpeg" style={{ margin: 'auto' }} />
              <p style={{ fontSize: '2rem' }}>Hospitality</p>
            </div>
            <div className='text-center'>
              <img src="motorhomes.jpeg" style={{ margin: 'auto' }} />
              <p style={{ fontSize: '2rem' }}>RVs</p>
            </div>
            <div className='text-center'>
              <img src="spas.jpeg" style={{ margin: 'auto' }} />
              <p style={{ fontSize: '2rem' }}>Spas</p>
            </div>
            <div className='text-center'>
              <img src="bicycles.jpeg" style={{ margin: 'auto' }} />
              <p style={{ fontSize: '2rem' }}>Bicycles</p>
            </div>
            <div className='text-center'>
              <img src="tours.jpeg" style={{ margin: 'auto' }} />
              <p style={{ fontSize: '2rem' }}>Tours</p>
            </div>
          </Slider>
        </div>

        {/* HOW IT WORKS SECTION
			============================================= */}
        <section ref={whyRef} id="content-2" className="wide-50 content-section division">
          <div className="container">
            {/* SECTION TITLE */}
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="section-title text-center mb-70">
                  {/* Title */}
                  <h2 className="h2-md">HOW IT <b>WORKS</b></h2>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              {/* IMAGE BLOCK */}
              <div className="col-md-5 col-lg-6">
                <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                  <img className="img-ipad" src="assets/images/ipad-mockup.png" alt="content-image" />
                </div>
              </div>
              {/* TEXT BLOCK */}
              <div className="col-md-7 col-lg-6">
                <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                  {/* Title */}
                  <h2 className="h2-md" style={{ marginBottom: 0 }}>
                    Add our&nbsp;</h2>
                  <a style={{ marginTop: 0, fontSize: '1.5rem' }} href="#" className="btn white-color btn-tra-red red-hover">Book Now
                  </a>
                  <h2 className="h2-md"> buttons to your website. </h2>
                </div>

                {/* <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{fontSize: '4rem'}}>Add our</span>
                    <a style={{marginLeft: 30, marginRight: 30}} href="#" className="btn white-color btn-tra-red red-hover">Book Now
                    </a>
                    <span>buttons</span>
                  </div> */}


              </div>	{/* END TEXT BLOCK */}
            </div>	   {/* End row */}
          </div>	   {/* End container */}
        </section>	{/* END CONTENT-2 */}
        {/* CUSTOMERS BOOK ON YOUR WEBSITE
			============================================= */}
        <section id="features-13" className="rel whitesmoke_shape mt-inverse-50 features-section division">
          <div className="container">
            <div className="row d-flex align-items-center">
              {/* TEXT BLOCK */}
              <div className="col-md-10 col-lg-5">
                <div className="txt-block pc-15 wow fadeInUp" data-wow-delay="0.4s">
                  {/* Title */}
                  <h2 className="h2-md text-right">Customers book on  your website.</h2>
                </div>
              </div>	{/* END TEXT BLOCK */}
              {/* FEATURES-13 WRAPPER */}
              <div className="col-lg-7">
                <div className="fbox-13-wrapper pl-35">
                  <div className="row">
                    <div className="col-md-6">
                      {/* FEATURE BOX #1 */}
                      <div id="fb-13-1" className="fbox-13 mt-50 mb-30 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Icon */}
                        <div className="fbox-13-ico ico-75 maroon"><span className="flaticon-calendar" /></div>
                        {/* Title */}
                        <h5 className="h5-sm">Customers See Photos, Descriptions, and Calendar of Availability</h5>
                        {/* Text */}
                        <p className="p-lg">Customers select dates and times to book.  Photos, descriptions, and the calendar are hosted on the secure EazieBook platform but customers feel like they are still on your website.</p>
                      </div>
                      {/* FEATURE BOX #2 */}
                      <div id="fb-13-2" className="fbox-13 wow fadeInUp" data-wow-delay="0.6s">
                        {/* Icon */}
                        <div className="fbox-13-ico ico-75 maroon"><span className="flaticon-roundabout" /></div>
                        {/* Title */}
                        <h5 className="h5-sm">Customers Check Out</h5>
                        {/* Text */}
                        <p className="p-lg">Customers pay with credit card. There is no booking fee for you, the business owner. EazieBook charges the customer a small processing fee.</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* FEATURE BOX #3 */}
                      <div id="fb-13-3" className="fbox-13 mb-30 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Icon */}
                        <div className="fbox-13-ico ico-75 maroon"><img src={stripe} alt="stripe-image" />
                        </div>
                        {/* Title */}
                        <h5 className="h5-sm">We Use Stripe</h5>
                        {/* Text */}
                        <p className="p-lg">We work with Stripe for our credit card processing.</p>
                      </div>
                      {/* FEATURE BOX #4 */}
                      <div id="fb-13-4" className="fbox-13 wow fadeInUp" data-wow-delay="1s">
                        {/* Icon */}
                        <div className="fbox-13-ico ico-75 maroon"><span className="flaticon-credit-card-1" /></div>
                        {/* Title */}
                        <h5 className="h5-sm">Credit Card Processing</h5>
                        {/* Text */}
                        <p className="p-lg">Alternatively, EazieBook works with your credit card processing system.</p>
                      </div>
                    </div>
                  </div>
                </div>	{/* End row */}
              </div>	{/* END FEATURES-13 WRAPPER */}
            </div>	 {/* End row */}
          </div>	  {/* End container */}
        </section>	{/* END FEATURES-13 */}

        {/* WATCH VIDEO TO SEE HOW IT WORKS
			============================================= */}
        <section className="wide-60 content-section division">
          <div className="container">
            <div className="section-title text-center mb-70">
              <h2 className="h2-md">Watch Video to See How it Works</h2>
            </div>
            <div className="row d-flex align-items-center" style={{borderStyle: 'solid', boxShadow: '20px 10px 20px grey'}}>
              <ReactPlayer
                url='eaziebook-how-it-works.mov'
                controls={true}
                width='100%'
                height='100%'
              />
            </div>

            
          </div>
        </section>

        {/* TWO CONTENT BOXES: 1) CUSTOMERS CLICK BOOK NOW; 2) GET BOOKINGS 24/7
			============================================= */}
        <section id="content-3" className="wide-60 content-section division bg_whitesmoke division">
          <div className="container">
            {/* CONTENT BOX-1 */}
            <div id="cb-1-2" className="cbox-1">
              <div className="row d-flex align-items-center m-row">
                {/* TEXT BLOCK */}
                <div className="col-md-7 col-lg-6 m-bottom">
                  <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    {/* CONTENT BOX #1 */}
                    <div className="cbox mb-30">
                      {/* Icon */}
                      <div className="cbox-ico ico-65 maroon-color"><span className="flaticon-button" /></div>
                      {/* Text */}
                      <div className="cbox-txt">
                        <h5 className="h5-sm">Customers Click <b>Book Now</b></h5>
                        <p className="p-lg">We will help you add Book Now buttons anywhere on your webiste. No coding required by you.
                        </p>
                      </div>
                    </div>
                    {/* CONTENT BOX #2 */}
                    <div className="cbox mb-30">
                      {/* Icon */}
                      <div className="cbox-ico ico-65 maroon-color"><span className="flaticon-calendar-1" /></div>
                      {/* Text */}
                      <div className="cbox-txt">
                        <h5 className="h5-sm">Customers Select Dates to Book</h5>
                        <p className="p-lg">Your customers see photos and descriptions of your bookable items. Your customers also select dates to book.
                        </p>
                      </div>
                    </div>
                    {/* CONTENT BOX #3 */}
                    <div className="cbox">
                      {/* Icon */}
                      <div className="cbox-ico ico-65 maroon-color"><span className="flaticon-drop-down-menu" /></div>
                      {/* Text */}
                      <div className="cbox-txt">
                        <h5 className="h5-sm">Customers Check Out</h5>
                        <p className="p-lg">Your customers check out. The funds are deposited directly into your bank account.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>	{/* END TEXT BLOCK */}
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6 m-top">
                  <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img-17.png" alt="content-image" />
                  </div>
                </div>
              </div>  {/* End row */}
            </div>	{/* END CONTENT BOX-1 */}
            {/* CONTENT BOX-2 */}
            <div id="cb-1-1" className="cbox-1 pb-25">
              <div className="row d-flex align-items-center">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6">
                  <div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img-18.png" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}
                <div className="col-md-7 col-lg-6">
                  <div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Title */}
                    <h2 className="h2-md">Get bookings 24/7.</h2>
                    {/* Text */}
                    <h5 className="h5-sm" style={{color: '#821E0D'}}><b>40% of online reservations are made outside of business hours.</b></h5>
                    <h5 className="h5-sm" style={{color: '#821E0D'}}><b>Over 90% of people will likely abandon a website not offering online booking and turn to one that does.</b></h5>
                    <p className="p-lg">These are startling statistics. Why turn away so much business? With EazieBook, maximize your revenue potential by receiving bookings around the clock.
                    </p>
                    <a href="https://eaziebookdemo.com/" className="btn white-color btn-tra-red red-hover">See Live Demos
                    </a>
                  </div>
                </div>	{/* END TEXT BLOCK */}
              </div>	  {/* End row */}
            </div>	{/* END CONTENT BOX-2 */}
          </div>     {/* End container */}
        </section>	{/* END CONTENT-3 */}

        {/* SECURITY SECTION ============================================ */}
        <section id="content-2" className="wide-50 content-section division">
          <div className="container">
            <div className="row d-flex align-items-center">
              {/* IMAGE BLOCK */}
              <div className="col-md-5 col-lg-6">
                <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                  <img className="img-fluid" src="assets/images/secure-payments.png" alt="secure payment" />
                </div>
              </div>
              {/* TEXT BLOCK */}
              <div className="col-md-7 col-lg-6">
                <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                  {/* Title */}
                  <h2 className="h2-md">Secure Access and Payments</h2>
                  {/* Text */}
                  <p className="p-lg">EazieBook runs on the same security system as Airbnb, Expedia, and many large hotels chains.
                    EazieBook uses secure connections to all web pages. All customer credit card payments are through secure systems.
                  </p>
                </div>
              </div>	{/* END TEXT BLOCK */}
            </div>	   {/* End row */}
          </div>	   {/* End container */}
        </section>	{/* END CONTENT-2 */}

        {/* FACTS ABOUT ONLINE BOOKING
			============================================= */}
        <section id="features-5" className="wide-50 features-section division">

          <svg style={{ display: 'none' }}>
            <defs>
              <symbol viewBox="0 0 38 38" id="icon-cross">
                <path
                  d="M19 17.6l5.3-5.4 1.4 1.4-5.3 5.4 5.3 5.3-1.4 1.4-5.3-5.3-5.3 5.3-1.4-1.4 5.2-5.3-5.2-5.3 1.4-1.4 5.3 5.3z" />
              </symbol>
              <symbol viewBox='0 0 150 130' id="icon-loading">
                <title>Loading</title>
                <path
                  d='M81.5 33l30.8-32.8c0.3-0.3 0.5-0.2 0.3 0.3 -1.8 5.2-1.7 15.3-1.7 15.3 -0.1 6.8-0.8 11.7-6.6 17.9L74.8 65.1c-0.2 0.2-0.4 0-0.3-0.2 1.5-5.1 1.2-15.1 1.2-15.1C75.4 45.6 76.4 38.4 81.5 33M105.9 54.8l43.8 10.3c0.4 0.1 0.4 0.4-0.2 0.4 -5.4 1-14.1 6.1-14.1 6.1 -6 3.3-10.5 5.2-18.8 3.2l-41.9-9.9c-0.3-0.1-0.2-0.3 0-0.4 5.2-1.3 13.7-6.5 13.7-6.5C92 55.9 98.7 53.1 105.9 54.8M99.4 86.3l13 43.2c0.1 0.4-0.1 0.5-0.4 0.1 -3.6-4.2-12.4-9.2-12.4-9.2 -5.8-3.5-9.7-6.5-12.2-14.6L75 64.5c-0.1-0.3 0.2-0.4 0.3-0.2 3.7 3.9 12.5 8.6 12.5 8.6C91.5 74.8 97.3 79.2 99.4 86.3M68.7 97l-30.8 32.8c-0.3 0.3-0.5 0.2-0.3-0.3 1.8-5.2 1.7-15.3 1.7-15.3 0.1-6.8 0.8-11.7 6.6-17.9l29.5-31.4c0.2-0.2 0.4 0 0.3 0.2 -1.5 5.1-1.2 15.1-1.2 15.1C74.8 84.4 73.8 91.6 68.7 97M44.1 75.8L0.3 65.4C-0.1 65.3-0.1 65 0.5 65c5.4-1 14.1-6.1 14.1-6.1 6-3.3 10.5-5.2 18.8-3.2l41.9 9.9c0.3 0.1 0.2 0.3 0 0.4 -5.2 1.3-13.7 6.5-13.7 6.5C58.1 74.7 51.3 77.5 44.1 75.8M50.2 43.8l-13-43.2c-0.1-0.4 0.1-0.5 0.4-0.1C41.2 4.7 50 9.7 50 9.7c5.8 3.5 9.7 6.5 12.2 14.6l12.4 41.3c0.1 0.3-0.2 0.4-0.3 0.2 -3.7-3.9-12.5-8.6-12.5-8.6C58.1 55.4 52.4 50.9 50.2 43.8' />
              </symbol>
            </defs>
          </svg>

          <div className="container">
            {/* SECTION TITLE */}
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="section-title text-center mb-70">
                  {/* Title */}
                  <h2 className="h2-md">Facts About Online Booking</h2>
                  {/* Text */}
                  {/* <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                    tempus, blandit and cursus varius magna tempus a dolor
                  </p> */}
                </div>
              </div>
            </div>
            {/* FEATURES-5 WRAPPER */}
            <div className="fbox-5-wrapper pc-30">
              <div className="row">
                {/* FEATURE BOX #1 */}
                <div id="fb-5-1" className="col-md-6">
                  <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                    {/* Icon */}
                    {/* <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-ads" /></div> */}
                    <div className="fbox-ico ico-70 skyblue-color"><img src={checkmark} width={50} height={'auto'} /></div>

                    {/* <div><img src={checkmark} width={50} height={50} /></div> */}
                    {/* Text */}
                    <div className="fbox-txt">
                      {/* Title */}
                      <h5 className="h5-sm">95% prefer to use a service provider offering online booking over one that does not.</h5>
                      {/* Text */}
                      {/* <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat
                        at impedit felis undo auctor augue mauris

                      </p> */}
                    </div>
                  </div>
                </div>
                {/* FEATURE BOX #2 */}
                <div id="fb-5-2" className="col-md-6">
                  <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Icon */}
                    <div className="fbox-ico ico-70 skyblue-color"><img src={checkmark} width={50} height={'auto'} /></div>
                    {/* Text */}
                    <div className="fbox-txt">
                      {/* Title */}
                      <h5 className="h5-sm">59% of customers are frustrated with waiting on hold related to scheduling by phone. </h5>
                      {/* Text */}
                      {/* <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat
                        at impedit felis undo auctor augue mauris
                      </p> */}
                    </div>
                  </div>
                </div>
                {/* FEATURE BOX #3 */}
                <div id="fb-5-3" className="col-md-6">
                  <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                    {/* Icon */}
                    <div className="fbox-ico ico-70 skyblue-color"><img src={checkmark} width={50} height={'auto'} /></div>
                    {/* Text */}
                    <div className="fbox-txt">
                      {/* Title */}
                      <h5 className="h5-sm">40% of bookings are made outside of business hours.</h5>
                      {/* Text */}
                      {/* <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat
                        at impedit felis undo auctor augue mauris
                      </p> */}
                    </div>
                  </div>
                </div>
                {/* FEATURE BOX #4 */}
                <div id="fb-5-4" className="col-md-6">
                  <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="1s">
                    {/* Icon */}
                    <div className="fbox-ico ico-70 skyblue-color"><img src={checkmark} width={50} height={'auto'} /></div>
                    {/* Text */}
                    <div className="fbox-txt">
                      {/* Title */}
                      <h5 className="h5-sm">29% reduction in no-shows with online booking because of automated reminders to customers.</h5>
                      {/* Text */}
                      {/* <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat
                        at impedit felis undo auctor augue mauris
                      </p> */}
                    </div>
                  </div>
                </div>
                {/* FEATURE BOX #5 */}
                <div id="fb-5-3" className="col-md-6">
                  <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                    {/* Icon */}
                    <div className="fbox-ico ico-70 skyblue-color"><img src={checkmark} width={50} height={'auto'} /></div>
                    {/* Text */}
                    <div className="fbox-txt">
                      {/* Title */}
                      <h5 className="h5-sm">Online booking decreases back-and-forth emails, reduces customer wait time, and increases customer satisfaction. </h5>
                      {/* Text */}
                      {/* <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat
                        at impedit felis undo auctor augue mauris
                      </p> */}
                    </div>
                  </div>
                </div>
                {/* FEATURE BOX #6 */}
                <div id="fb-5-4" className="col-md-6">
                  <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="1s">
                    {/* Icon */}
                    <div className="fbox-ico ico-70 skyblue-color"><img src={checkmark} width={50} height={'auto'} /></div>
                    {/* Text */}
                    <div className="fbox-txt">
                      {/* Title */}
                      <h5 className="h5-sm">Online booking provides upselling opportunities that enhance revenues.</h5>
                      {/* Text */}
                      {/* <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat
                        at impedit felis undo auctor augue mauris
                      </p> */}
                    </div>
                  </div>
                </div>

              </div>  {/* End row */}
            </div>	{/* END FEATURES-5 HOLDER */}
          </div>	   {/* End container */}
        </section>	{/* END FEATURES-5 */}

        {/* FAQ SECTION*/}
        <section id="faq" className="wide-100 bg_whitesmoke division">
          <Box ref={faqRef} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack sx={{ maxWidth: 800 }}>
              <div className="section-title text-center mb-70">
                {/* Title */}
                <h2 className="h2-md">FAQ</h2>
                {/* Text */}
                {/* <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                    tempus, blandit and cursus varius magna tempus a dolor
                  </p> */}
              </div>
              {qaItems.map((qaItem) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontSize: '1.25rem' }}>
                      {qaItem.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ fontSize: '1.25rem' }}>
                      <span style={{ fontWeight: 600 }}>{qaItem.answer}</span>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          </Box>
        </section>
        {/* PROCESS-2
			============================================= */}
        <section id="process-2" className="bg_fit_02 wide-100 process-section division">
          <div className="container white-color wide-80">
            {/* SECTION TITLE */}
            <div className="row">
              <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                <div className="section-title text-center mb-60">
                  <h3 className="h3-sm">Move your booking online</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <ul className="process-maroon processbar pc-35">
                {/* PROCESS BOX #1 */}
                <li id="step-2-1" className="col-md-4">
                  <div className="pbox-2 pc-20 text-center">
                    <h5 className="h5-sm">Sign Up</h5>
                    <p className="p-lg">It is simple to join our service</p>
                  </div>
                </li>
                {/* PROCESS BOX #2 */}
                <li id="step-2-2" className="col-md-4">
                  <div className="pbox-2 pc-20 text-center">
                    <h5 className="h5-sm">Add Booking to Website</h5>
                    <p className="p-lg">We will help you add buttons to your website</p>
                  </div>
                </li>
                {/* PROCESS BOX #3 */}
                <li id="step-2-3" className="col-md-4">
                  <div className="pbox-2 pc-20 text-center">
                    <h5 className="h5-sm">Get Online Bookings</h5>
                    <p className="p-lg">Your customers can seamlessly book online right on your website</p>
                  </div>
                </li>
              </ul>
            </div>	  {/* End row */}
          </div>	   {/* End container */}
          <div className="col-md-12 col-lg-12 img-block">
            {/* CALL TO ACTION */}
            <a href="https://eaziebookdemo.com/" className="btn white-color btn-tra-grey red-hover">See Live Demos
            </a>
          </div>
        </section>	{/* END PROCESS-2 */}

          {/* PRICING
			============================================= */}
          <section ref={pricingRef} id="pricing-2" className="wide-100 pricing-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Simple and Transparent Pricing</h2>	
                    {/* Text */}	
                    <p className="p-xl">Our pricing is straightfoward. There are no hidden fees.
                    </p>
                  </div>	
                </div>
              </div>
              {/* PRICING TABLES */}
              <div className="row pricing-row">
                {/* SETUP FEE */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">One-Time Setup Fee</h5>									
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">495</span>
                      <sup className="validity dark-color"><span>.00</span></sup>
                      <p className="p-sm">One-time setup fee covers entering your information into EazieBook and linking your website with Eaziebook.</p>
                    </div>	
                  </div>
                </div>	{/* END SETUP FEE */}
                {/* BOOKING FEE TO BUSINESS OWNER */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">Business Owner Booking Fee</h5>	
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">0</span>
                      <sup className="validity dark-color"><span>.00</span></sup>
                      <p className="p-sm">There is no booking fee to you, the business owner.</p>
                    </div>	
                  </div>
                </div>	{/* END BOOKING FEE TO BUSINESS OWNER */}
                {/* CUSTOMER PROCESSING FEE */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">Customer Processing Fee</h5>	
                      <sup className="dark-color"></sup>								
                      <span className="dark-color">8</span>
                      <sup className="validity dark-color"><span>.0</span></sup><sup className="dark-color">%</sup>	
                      <p className="p-sm">EazieBook charges the customer an 8.0% processing fee for each booking.</p>
                    </div>	
                  </div>
                </div>	{/* END CUSTOMER PROCESSING FEE */}
              </div>	{/* END PRICING TABLES */}
            </div>	   {/* End container */}
          </section>	{/* END PRICING-2 */}


        {/* TESTIMONIALS-4
			============================================= */}
        <section id="reviews-4" className="bg_whitesmoke  hero-offset-nav pb-60 reviews-section division">
          <div className="container">
            {/* SECTION TITLE */}
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="section-title text-center mb-60">
                  {/* Title */}
                  <h2 className="h2-md">What People Are Saying About EazieBook</h2>
                  {/* Text */}
                  {/* <p className="p-xl">This is what companies are saying after experiencing EazieBook on their website.
                  </p> */}
                </div>
              </div>
            </div>
            {/* TESTIMONIALS-4 WRAPPER */}
            <div className="reviews-4-wrapper">
              <div className="row">
                {/* TESTIMONIALS LEFT COLUMN */}
                <div className="col-lg-6">
                  <div className="reviews-4-column">
                    {/* TESTIMONIAL #1 */}
                    <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Testimonial Text */}
                      <div className="review-4-txt">
                        <p>EazieBook is great, <span className="txt-highlight">it saves so much time,</span> especially for small to medium-sized companies.
                        </p>
                      </div>
                      {/* Testimonial Data */}
                      <div className="review-4-data">
                        {/* Rating */}
                        <div className="app-rating ico-20 yellow-color">
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                        </div>
                        {/* Author */}
                        <h5 className="h5-sm">John Marcum</h5>
                        <p>Media Executive</p>
                      </div>
                    </div>	{/* END TESTIMONIAL #1 */}
                    {/* TESTIMONIAL #2 */}
                    <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                      {/* Testimonial Text */}
                      <div className="review-4-txt">
                        <p>EazieBook <span className="txt-highlight">makes it possible</span> to get bookings while I'm not in the office.
                        </p>
                      </div>
                      {/* Testimonial Data */}
                      <div className="review-4-data">
                        {/* App Rating */}
                        <div className="app-rating ico-20 yellow-color">
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star-half-empty" />
                        </div>
                        {/* Author */}
                        <h5 className="h5-sm">Jeff Hodges</h5>
                        <p>Program Director</p>
                      </div>
                    </div>	{/* END TESTIMONIAL #2 */}
                    {/* TESTIMONIAL #3 */}
                    <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Testimonial Text */}
                      <div className="review-4-txt">
                        <p>Thanks to this platform, <span className="txt-highlight">our bookings have increased immensely!</span>
                        </p>
                      </div>
                      {/* Testimonial Data */}
                      <div className="review-4-data">
                        {/* Rating */}
                        <div className="app-rating ico-20 yellow-color">
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                        </div>
                        {/* Author */}
                        <h5 className="h5-sm">Greg Dewing</h5>
                        <p>Business Owner, Minnesota</p>
                      </div>
                    </div>	{/* END TESTIMONIAL #3 */}
                    {/* TESTIMONIAL #4 */}
                    <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                      {/* Testimonial Text */}
                      <div className="review-4-txt">
                        <p><span className="txt-highlight">Never imagined I could get online booking set up so quickly and easily on my website.</span>  Our customers had been waiting for a long time for this!
                        </p>
                      </div>
                      {/* Testimonial Data */}
                      <div className="review-4-data">
                        {/* App Rating */}
                        <div className="app-rating ico-20 yellow-color">
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                        </div>
                        {/* Author */}
                        <h5 className="h5-sm">Justin Flaco</h5>
                        <p>Property Manager</p>
                      </div>
                    </div>	{/* END TESTIMONIAL #4 */}
                  </div>
                </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                {/* TESTIMONIALS RIGHT COLUMN */}
                <div className="col-lg-6">
                  <div className="reviews-4-column">
                    {/* TESTIMONIAL #5 */}
                    <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Testimonial Text */}
                      <div className="review-4-txt">
                        <p>Credit card funds transfer <span className="txt-highlight">immediately and automatically.</span>  As soon as a customer books, the stripe payment goes directly through to my account.
                        </p>
                      </div>
                      {/* Testimonial Data */}
                      <div className="review-4-data">
                        {/* App Rating */}
                        <div className="app-rating ico-20 yellow-color">
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                        </div>
                        {/* Author */}
                        <h5 className="h5-sm">Dominic Pena</h5>
                        <p>CEO</p>
                      </div>
                    </div>	{/* END TESTIMONIAL #5 */}
                    {/* TESTIMONIAL #6 */}
                    <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                      {/* Testimonial Text */}
                      <div className="review-4-txt">
                        <p>The <span className="txt-highlight">layout is clean and easy to use.</span> The user interface is very intuitive and the calendar is fantastic!
                        </p>
                      </div>
                      {/* Testimonial Data */}
                      <div className="review-4-data">
                        {/* App Rating */}
                        <div className="app-rating ico-20 yellow-color">
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star-half-empty" />
                        </div>
                        {/* Author */}
                        <h5 className="h5-sm">Rebecca Sterling</h5>
                        <p>Hotel Manager</p>
                      </div>
                    </div>	{/* END TESTIMONIAL #6 */}
                    {/* TESTIMONIAL #7 */}
                    <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Testimonial Text */}
                      <div className="review-4-txt">
                        <p>We were missing opportunities, so many people told us they would have booked if there was an online booking option.  <span className="txt-highlight">Onboarding with EazieBook has single handedly turned our business around.</span> We no longer need to be by the phone to book our clients.
                        </p>
                      </div>
                      {/* Testimonial Data */}
                      <div className="review-4-data">
                        {/* App Rating */}
                        <div className="app-rating ico-20 yellow-color">
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star" />
                          <span className="flaticon-star-half-empty" />
                        </div>
                        {/* Author */}
                        <h5 className="h5-sm">Kayla Rogers</h5>
                        <p>Business Owner</p>
                      </div>
                    </div>	{/* END TESTIMONIAL #7 */}
                  </div>
                </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
              </div>  {/* End row */}
            </div>	{/* END TESTIMONIALS-4 WRAPPER */}
          </div>     {/* End container */}
          {/* GEOMETRIC OVERLAY */}
          <div className="bg_fixed geometric_overlay" />
        </section>	{/* END TESTIMONIALS-4 */}

        {/* CONTACT FORM
			============================================= */}

        <section id="contact-form" className="wide-60 content-section division" ref={contactRef}>
          <div className="section-title text-center mb-70">
            {/* Title */}
            <h2 className="h2-md">Contact</h2>
            {/* Text */}
            <p className="p-xl">Contact us any way you prefer.</p>
            <p className="p-xl" style={{color: '#821E0D'}}>Phone: <a href="tel:8008511366" style={{color: '#821E0D'}}>800-851-1366</a></p>
            <p className="p-xl" style={{color: '#821E0D'}}>Email: <a href="mailto:sales@eaziebook.com" style={{color: '#821E0D'}}>sales@eaziebook.com</a></p>
            <p className="p-xl" style={{color: '#821E0D'}}>Submit the form below:</p>
          </div>
          <ContactForm />
        </section>

        {/* FOOTER
			============================================= */}
        <section id="content-13" className="wide-90 content-section division">
          <div className="container white-color img-block">
            <a href="#">
              <img className="img-fluid" src="assets/images/eazie-book-logo.png" alt="brand-logo" />
            </a>
          </div>
          <div>
            <h6 className="p-lg text-center">&copy; 2022 EazieBook Inc.</h6>
          </div>	   {/* End container */}
        </section>	{/* END CONTENT-13 */}
      </div>	{/* END PAGE CONTENT */}

    </>
  );
}

export default LandingPage;