import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import './Payment.css';
import {
    TextField,
    Paper,
    Button,
    FormControlLabel,
    FormControl,
    Checkbox,
    Typography
} from '@mui/material';
import formatMoney from '../../formatters/money.format';
import InputMask from 'react-input-mask';
import {hourlyEmailToClient, hourlyEmailToRenter, multiDayEmailToRenter, multiDayEmailToClient} from './email';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import PaymentInfo from "./PaymentInfo";
import CustomPaymentInfo from "./CustomPaymentInfo";
import {getTimeFromDate} from "../Utils/share-functions";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import LinearProgress from "@mui/material/LinearProgress";

function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const invoiceInitial = {
    totals: {
        total: 0, ao: 0, tnf: 0, rf: 0, orf: 0, unitsBooked: 0
    },
    dueToday: {
        total: {}
    },
    dueLater: {
        total: {}
    },
    discount: {rate: 0}
}

export default function Payment() {
    const query = useQuery();
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const { isLoading } = useSelector(store => store.loadingReducer);
    const bookableItem = useSelector(store => store.selectedBookableItem);
    const tileTitle = bookableItem.tileTitle;
    const {deposit} = bookableItem;
    const startDate = query.get('startDate'), endDate = query.get('endDate'), date = query.get('date'),
        instanceItem = query.get('item'), clientId = query.get('clientId'), bookableItemHours = query.get('hours'),
        startHour = query.get('startHour'), endHour = query.get('endHour');
    const custom = query.get('custom') || '', customDuration = query.get('customDuration') || 0,
        customPrice = query.get('customPrice') || 0, customTnf = query.get('customTnf') || 0, customEf = query.get('customEf') || 0,
        customTotalPrice = Number(customPrice || 0) + Number(customTnf || 0) + Number(customEf || 0),
        customComment = query.get('customComment') || '', customTimestamp = query.get('customDate') || '';
    const customDate = new Date(Number(customTimestamp));
    const customStartTime = moment(getTimeFromDate(customDate), ['hh:mm:ss']).format('hh:mm A');
    const selectedAddOns = query.get('addOns')?.split('a') || [];
    const client = useSelector(store => store.activeClient), clientEmail = client.email,
        clientFirstName = client.first_name, clientPhone = client.phone_number_1;
    const [paymentSuccess, setPaymentSuccess] = React.useState(false);
    const [invoice, setInvoice] = useState(invoiceInitial);
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const [message, setMessage] = useState(null);
    const [payInFull, setPayInFull] = useState(true);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [email, setEmail] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState('card');
    const [agreement, setAgreement] = useState(false);

    const {
        totals: {
            total, ao, tnf, rf, orf, unitsBooked, sav
        },
        dueToday: {
            total: dtt
        },
        dueLater: {
            total: dlt
        },
        discount,
        seasonalPrice
    } = invoice;

    useEffect(() => {
        dispatch({
            type: 'FETCH_SELECTED_BOOKABLE_ITEM',
            payload: id
        });
        dispatch({
            type: 'FETCH_ACTIVE_CLIENT',
            payload: clientId
        });
    }, []);

    useEffect(() => {
        const fetchPricing = async () => {
            const serverPackage = {
                id, startDate, endDate, selectedAddOns, bookableItemHours,
                isMultiDay: bookableItem.duration_type === 'multiDay'
            }
            const {data: pricing} = await axios.post('/api/payment/invoice-calc', serverPackage);
            setInvoice(pricing)
        }
        fetchPricing();
    }, []);

    useEffect(() => {
        if(deposit) {
            setPayInFull(false);
        }
    }, [deposit]);

    const checkoutWithFixedPrice = async (paymentMethod, selectedPayment) =>
        await axios.post('/api/payment/fixed-price', {
        comment: customComment, timeStamp: customTimestamp, duration: customDuration, itemId: id,
            timeZoneOffset: new Date().getTimezoneOffset() * 60000, totalPrice: Math.round(customTotalPrice  * 100),
            paymentMethod, email, phoneNumber, firstName, lastName
    });

    const checkout = async (e) => {
        e.preventDefault();
        setPaymentInProgress(true);
        setMessage('');
        setPaymentSuccess(false);
        if (!stripe || !elements || !agreement) {
            setPaymentInProgress(false);
            setMessage('Please read agreement above');
            return;
        }
        try {
            if (selectedPayment === 'card') {
                dispatch({ type: 'START_LOADING' });
                const {error, paymentMethod} = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement),
                });
                if (custom === 'true') {
                    const { data: confirmationNumber } = await checkoutWithFixedPrice(paymentMethod, selectedPayment);
                    setPaymentSuccess(true);
                    history.push(`/checkout-completed/?confirmation-number=${confirmationNumber}&item=${id}&customDuration=${customDuration}&customDate=${customTimestamp}&custom=${true}&customComment=${customComment}`);
                    return;
                }
                if (paymentMethod || selectedPayment === 'cash' || selectedPayment === 'check') {
                    const data = await axios.post('/api/payment/create-booking', {
                        item: id, instanceItem, paymentMethod, firstName, lastName, phoneNumber, email,
                        startDate, endDate, date, clientEmail, clientFirstName, clientPhone, tileTitle, payInFull,
                        paymentType: selectedPayment, selectedAddOns, bookableItemHours, startHour, endHour
                    });
                    let dateURL;
                    if (bookableItem.duration_type === 'multiDay' || bookableItem.duration_type === 'multiNight') {
                        dateURL = `startDate=${startDate}&endDate=${endDate}`
                    } else if (bookableItem.duration_type === 'singleDay' || bookableItem.duration_type === 'singleNight') {
                        dateURL = `date=${date}`
                    }
                    if (data.status === 200) {
                        setPaymentSuccess(true);
                        // If multi-day or multi-night, then send multi-day version of confirmation emails to Renter and Client.
                        if (bookableItem.duration_type === 'multiDay' || bookableItem.duration_type === 'multiNight') {
                            // Send email to Renter.
                            multiDayEmailToRenter(firstName, null, email, bookableItem.company_name, bookableItem.phone_number_1, bookableItem.email, bookableItem.title, startDate, endDate, data.data, total, payInFull, dtt, dlt);
                            // Send email to Client.
                            multiDayEmailToClient(firstName, lastName, phoneNumber, email, bookableItem.email, bookableItem.title, startDate, endDate, data.data, total, payInFull, dtt, dlt)
                        }
                        // If single-day (assume it is hourly), then send single-day version of confirmation emails to Renter and Client.
                        if (bookableItem.duration_type === 'singleDay') {
                            // Send email to Renter.
                            hourlyEmailToRenter(firstName, null, email, bookableItem.company_name, bookableItem.phone_number_1, bookableItem.email, bookableItem.title, date, startHour, endHour, data.data, total, payInFull, dtt, dlt);
                            // Send email to Client.
                            hourlyEmailToClient(firstName, lastName, phoneNumber, email, bookableItem.email, bookableItem.title, date, startHour, endHour, data.data, total, payInFull, dtt, dlt)
                        }
                        history.push(`/checkout-completed/?confirmation-number=${data.data}&item=${bookableItem.id}&${dateURL}&startHour=${startHour}&endHour=${endHour}`);
                    }
                }
            }
        } catch (e) {
            console.error(e);
            setMessage('Error Occurred');
            setPaymentInProgress(false);
            setPaymentSuccess(false);
            dispatch({ type: 'LOADING_FINISHED_FAILURE' });
        } finally {
            setPaymentInProgress(false);
            setPaymentSuccess(false);
            dispatch({ type: 'LOADING_FINISHED_SUCCESS' });
        }
    }

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "20px",
                "::placeholder": {
                    color: "#717172",
                    fontSize: "1rem",
                }
            },
            invalid: {
                fontFamily: 'Arial, sans-serif',
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handlePayChange = (e) => {
        if (e.target.value === 'payInFull') setPayInFull(true);
        if (e.target.value === 'payInDeposits') setPayInFull(false);
    }

    const buttonSx = {
        ...(paymentSuccess && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const confirmButton = () => (
        <Box sx={{ m: 1, position: 'relative' }}>
            <Button type="submit" variant='contained' fullWidth sx={buttonSx}
                    disabled={paymentInProgress}>Confirm Order</Button>
            {paymentInProgress && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </Box>
    );

    return <>
        <Container align="center" maxWidth="lg" sx={{marginTop: 2}}>
            {
                isLoading ?
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
                : null
            }
            <Box>
                <Typography variant='h4'>Checkout</Typography>
            </Box>

            {/* DISABLE UNTIL STABLE */}

            {/* Use MUI Grid layout to render left side (summary of charges) and right side (credit card payment).
      Use nested MUI Grid "items".  That is, left side is one grid item. Right side is another grid item.
      Then, the left side has nexted grid items.  And right side has nested grid items. */}
            <Grid container spacing={2} sx={{marginTop: 0.5}}>


                {/* Left side - the summary of charges. */}
                <Grid item xs={12} md={6} textAlign="left">
                    <Paper sx={{padding: 2}}>
                        {!payInFull && <FormControl>
                            <div>
                                {!payInFull &&
                                    <p style={{textAlign: 'center', fontWeight: '200'}}>A deposit of {formatMoney(dtt)} is due today. 
                                        We will charge the balance ({formatMoney(dlt)}) two days before your
                                        booking.</p>
                                }
                            </div>
                        </FormControl>}
                        {custom === 'true' ? <CustomPaymentInfo duration={customDuration}
                                                                comments={customComment}
                                                                price={customPrice}
                                                                durationType={bookableItem.duration_type}
                                                                tnf={Number(customTnf)} total={customTotalPrice} ef={Number(customEf)}
                                                                customDate={customDate}
                                                                customStartTime={customStartTime}
                                                                customTimestamp={customTimestamp} /> :
                            <PaymentInfo seasonalPrice={seasonalPrice} unitsBooked={unitsBooked} discount={discount}
                                         orf={orf} ao={ao}
                                         sav={sav} tnf={tnf} dlt={dlt} dtt={dtt} payInFull={payInFull} total={total}
                                         bookableItem={bookableItem} />}
                    </Paper>
                </Grid>

                {/* Right side - credit card payment. */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{padding: 2}}>
                        <form onSubmit={checkout}>
                            {/* {user.authLevel && <>
                <Typography variant='h6'>Select a Payment Method</Typography>
                <FormControl>
                  <RadioGroup
                    onChange={(e) => handleSelectedPayment(e)}
                    defaultValue="card"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value={true} control={<Radio value='card' />} label="Card" />
                    <FormControlLabel value={false} control={<Radio value='cash' />} label="Cash" />
                  </RadioGroup>
                </FormControl>
              </>} */}
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField fullWidth required onChange={(e) => setFirstName(e.target.value)}
                                               name="firstName" label="First Name" variant="outlined"/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth required onChange={(e) => setLastName(e.target.value)}
                                               name="lastName" label="Last Name" variant="outlined"/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        disabled={false}
                                        maskChar=" "
                                        onChange={(event) => setPhoneNumber(event.target.value.replace(/\D/g, ''))}
                                    >
                                        {() => <TextField
                                            fullWidth
                                            required
                                            className="renter-information-input"
                                            type={'text'}
                                            name="phoneNumber"
                                            label="Phone Number"
                                            variant="outlined"
                                        />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth required type="email"
                                               onChange={(e) => setEmail(e.target.value)} name="email" label="E-Mail"
                                               variant="outlined"/>
                                </Grid>
                                <Grid item xs={12}>
                                    {selectedPayment === 'card' && <div style={{
                                        border: '1px solid #cbcbcb',
                                        borderRadius: '4px',
                                        paddingTop: '15px',
                                        paddingBottom: '15px',
                                        paddingLeft: '5px',
                                        fontSize: '1rem'
                                    }}>
                                        <CardElement options={cardStyle}/></div>}
                                </Grid>
                                <Grid item xs={12}>
                                    {stripe && confirmButton()}
                                </Grid>
                                <Grid item xs={12} textAlign="left">
                                    <FormControlLabel control={<Checkbox onChange={() => setAgreement(!agreement)}
                                                                         value={agreement} />}
                                                      label="I agree that my payment method will be kept on file for processing in case my purchase requires recurring charges or installments."/>
                                </Grid>
                            </Grid>
                            <Typography variant='h6' sx={{color: 'red'}}>{message}</Typography>
                        </form>
                    </Paper>
                </Grid>

            </Grid>
        </Container>

    </>
}
