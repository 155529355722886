import { combineReducers } from 'redux';
import errors from './errors.reducer';
import user from './user.reducer';
import clientInvite from './clientInvite.reducer';
import bookableItem from './bookableItem.reducer';
import selectedBookableItem from './selectedBookableItem.reducer';
import clients from './clients.reducer';
import itemList from './ItemNames.reducer';
import categoryList from './category.reducer';
import activeClient from './activeClient.reducer';
import companyName from './companyName.reducer';
import sideBySide from './sideBySide.reducer';
import clientSelectedItem from './setClientDetailItem.reducer';
import clientList from './clientList.reducer';
import renter from './renter.reducer';
import photoReducer from './photos.reducer';
import renterBooking from './renterBooking.reducer';
import selectedClientItem from './selectedClientItem.reducer';
import singleItemPhoto from './singleItemPhoto.reducer';
import accountWrapper from './accountWrapper.reducer';
import selectedBooking from './selectedBooking.reducer';
import itemRequest from './itemRequest.reducer'
import tax from './tax.reducer'
import contact from './contact.reducer';
import loadingReducer from "./loading.reducer";

// rootReducer is the primary reducer for our entire project
// It bundles up all the other reducers so our project can use them.
// This is imported in index.js as rootSaga

// Let's make a bigger object for our store, with the objects from our reducers.
// This is what we get when we use 'state' inside 'mapStateToProps'
const rootReducer = combineReducers({
  errors, // contains registrationMessage and loginMessage
  user, // will have an id and username if someone is logged in
  clientInvite,
  bookableItem, // will have an id and username if someone is logged in
  clients,
  itemList, // for the Autocomplete on the category input form
  categoryList, //stores all the categories in the category table
  selectedBookableItem, // selecting this bookableItem by ID to be edited
  activeClient,
  companyName,
  sideBySide,
  clientSelectedItem,
  clientList,
  renterBooking,
  renter,
  photoReducer,
  selectedClientItem,
  singleItemPhoto,
  selectedBooking,
  itemRequest,
  accountWrapper,
  tax,
  contact,
  loadingReducer
});

export default rootReducer;
