import axios from 'axios';
import {put, takeEvery} from 'redux-saga/effects';
import {formatDate, weekDayDict} from "../../components/Utils/share-functions";
// todo: when new added photo change order, it will be saved as a NULL
// todo: carousel photos order
function* bookableItemSaga() {
    yield takeEvery('FETCH_BOOKABLE_ITEM', fetchBookableItem);
    //triggered in addBookableItem Form on pressing submit button:
    yield takeEvery('POST_BOOKABLE_ITEM', postBookableItem);
    yield takeEvery('POST_PHOTO', postPhoto)
    yield takeEvery('FETCH_SELECTED_BOOKABLE_ITEM', fetchSelectedBookableItem);
    yield takeEvery('SAVE_BOOKABLE_ITEM', saveEditBookableItem);
    yield takeEvery('UPDATE_BOOKABLE_ITEM_PHOTO', handleSaveEditBookableItemPics);
    //we need a fetch photo saga. will be implemented later down the road
    yield takeEvery('FETCH_RENTER_HISTORY', fetchRenterHistory);
    yield takeEvery('FETCH_ITEM_PHOTOS', fetchItemPhotos);
    yield takeEvery('FETCH_PHOTOS', fetchPhotos);
    yield takeEvery('DELETE_SELECTED_ITEM', deleteSelectedItem);
    yield takeEvery('GET_CLIENT_BOOKED_ITEM_REQUEST', getClientBookedItems);
    yield takeEvery('FETCH_ITEM_BY_CATEGORY', fetchBookableItemsByCategory);
    yield takeEvery('FETCH_BOOKABLEITEM_AVAILABILITY', fetchAvailabilityByItem);
    yield takeEvery('FETCH_ITEM_BY_CLIENT_ID', fetchBookableItemsByClientId);
    yield takeEvery('EDIT_DELETE_ITEM_PHOTOS', editDeleteItemPhotos);
    yield takeEvery('FETCH_ITEM_PREV_PHOTOS', fetchItemPrevPhotos);
    yield takeEvery('FETCH_AVAILABLE_HOURS', fetchAvailableHours);
}

function* getClientBookedItems(action) {
    try {
        const response = yield axios.get(`/api/bookableItem/clientBookedItem/${action.clientId}`);
        yield put({
            type: 'CLIENT_BOOKED_ITEM_REQUEST_SUCCESS',
            data: response.data
        })
    } catch (error) {
        console.error('ERROR getting data in fetchClients', error);
        yield put({
            type: 'CLIENT_BOOKED_ITEM_REQUEST_FAILURE',
            data: error
        })
    }
}

function* deleteSelectedItem(action) {
    try {
        yield axios.delete(`/api/bookableItem/${action.payload.itemId}`)
        // yield axios.delete(`/api/bookableItem/availability/${action.payload.itemId}`)

        yield put({
            type: "FETCH_CLIENT_BOOKABLE_ITEM",
            payload: action.payload.clientId
        })
    } catch (error) {
        console.error('ERROR deleting selected item', error);
    }
}

function* fetchPhotos() {
    let photos = yield axios.get('/api/photos');

    yield put({
        type: 'SET_PHOTOS',
        payload: photos.data
    })
}

function* fetchItemPhotos(action) {
    try {
        const response = yield axios.get(`/api/photos/${action.payload}`);

        yield put({
            type: 'SET_ITEM_PHOTOS',
            payload: response.data,
        });
    } catch (error) {
        console.error('ERROR getting photos for item', error);
    }
}

function* fetchItemPrevPhotos(action) {
    try {
        const { data: prevPhotos } = yield axios.get(`/api/photos/${action.payload}`);
        yield put({
            type: 'SET_ITEM_PREV_PHOTOS',
            payload: prevPhotos
        });
    } catch (e) {
        console.error('ERROR getting previous photos for item', e);
    }
}

function* editDeleteItemPhotos(action) {
    try {
        const {prevPhotos, photoIdToDel} = action.payload;
        console.log(photoIdToDel, prevPhotos);
        yield put({
            type: 'SET_ITEM_PREV_PHOTOS',
            payload: prevPhotos.filter(p => p.id !== photoIdToDel)
        });
    } catch (e) {
        console.error('ERROR deleting photo when editing item', e);
    }
}

function* fetchRenterHistory() {
    const result = yield axios.get(`api/renter`)
    yield put({
        type: 'SET_RENTER_HISTORY',
        payload: result.data
    })
}

function* fetchBookableItem() {
    try {
        const result = yield axios.get(`/api/bookableItem`)
        yield put({
            type: 'SET_BOOKABLE_ITEM_LIST',
            payload: result.data
        })
    } catch {
        console.error('ERROR in GET bookableItem')
    }
} //end of fetchBookableItem

function* postPhoto(action) {
    try {
        const config = {
            headers: {"Contents-Type": "application/json"},
            withCredentials: true,
        };

        yield axios.post(`/api/photos/:id`, action.payload, config);
        /* yield put({
            type: 'FETCH_ITEM_PHOTOS',
            payload: action.itemId
        }) */
    } catch (error) {
        console.error('ADD photo failed', error);
    }
}

function* fetchSelectedBookableItem(action) {
    try {
        const result = yield axios.get(`/api/bookableItem/selected/${action.payload}`);

        yield put({
            type: 'SET_SELECTED_BOOKABLE_ITEM',
            payload: result.data
        })
    } catch (error) {
        console.error('ERROR in GET selected bookableItemId', error)
    }
} // end of fetchSelectedBookableItem

function* handleSaveEditBookableItemPics(action) {
    const {payload: { photos, itemId, photoToDelete }} = action;
    console.log(itemId, photos, photoToDelete, 17777);
    const formData = new FormData();
    formData.append('itemId', itemId);
    formData.append('photoToDelete', photoToDelete);

    if (photos) {
        for (const photo of photos) {
            if (photo instanceof File) {
                formData.append('photos', photo);
                formData.append(`sortOrder_${photo.name}_${photo.sortOrder}`, photo.sortOrder);
            } else {
                formData.append(`${photo.id}`, photo.sortOrder);
            }
        }
    }

    yield axios.put(`/api/bookableItem/photos`, formData);
}

function* saveEditBookableItem(action) {
    let {availability: availableDays, duration_type, id, startTime, endTime, photos, photoToDelete, addOns, hourly_increment} = action.payload.selectedBookableItem;
    console.log("🚀 ~ file: bookableItem.saga.js:195 ~ function*saveEditBookableItem ~ addOns", addOns);
    
    // TODO: refactor server calls into logical units of work
    try {
        yield put({ type: 'START_LOADING' });
        if (photos || photoToDelete) {
            yield put({
                type: 'UPDATE_BOOKABLE_ITEM_PHOTO',
                payload: {
                    photos,
                    itemId: id,
                    photoToDelete: photoToDelete || []
                }
            })
        }
        yield axios.post(`/api/bookableItem/editAddon`, { addOns, itemId: id });
        delete action.payload.selectedBookableItem.availabilityyy;
        yield axios.put(`/api/bookableItem`, action.payload);
        if (duration_type === 'singleDay') {
            yield put({ type: 'TRIGGER_UPDATE_DATE' });
        }
        if (duration_type !== 'singleDay' && availableDays.flat().every(d => typeof d === 'string')) {
            yield axios.delete(`/api/bookableItem/availability/${id}/${duration_type}`);
            yield axios.post(`/api/bookableItem/availability/${id}`,
                {availableDays: [...new Set(availableDays?.flat() ?? [])],
                    isSingleDay: false,
                    startHour: startTime ? startTime.getHours() : null,
                    endHour: endTime ? endTime.getHours() : null,
                    increment: hourly_increment
                }
            )
        }
        yield put({ type: 'FETCH_BOOKABLE_ITEM' });
        yield put({ type: 'LOADING_FINISHED_SUCCESS' });
    } catch (err) {
        console.error('FAILED update selected bookableItem', err);
        yield put({ type: 'LOADING_FINISHED_FAILURE' });
    }
}
// end saveEditBookableItem

//called in the add bookable item component 
function* postBookableItem(action) {
    if (!action.payload.newBookableItem) return;
    try {
        const {
            title,
            tileTitle,
            summary,
            details,
            rate,
            categoryId,
            availability: availableDays,
            photos,
            duration,
            leadTime,
            prepTime,
            minDuration, 
            maxDuration,
            clientFeePercent, 
            eazieBookFeePercent,
            startTime,
            endTime,
            hourlyIncrement, 
            deposit, 
            damage_deposit, 
            deposit_amt, 
            damage_deposit_amt,
            addOnPhotos,
            instances,
            itemTax,
            max_deposit_amt
        } = action.payload.newBookableItem;
        const {addons, discounts} = action.payload

        const {
            clientFirstName, 
            clientEmail
        } = action.payload
        const clientId = action.payload.id;

        yield put({ type: 'START_LOADING' });

        const formData = new FormData();
        formData.append('title', title);
        formData.append('tileTitle', tileTitle);
        formData.append('summary', summary);
        formData.append('details', details);
        formData.append('rate', rate);
        formData.append('categoryId', categoryId);
        formData.append('clientId', clientId);
        formData.append('duration', duration);
        formData.append('leadTime', leadTime);
        formData.append('prepTime', prepTime);
        formData.append('minDuration', minDuration);
        formData.append('maxDuration', maxDuration);
        formData.append('clientFeePercent', clientFeePercent);
        formData.append('eazieBookFeePercent', eazieBookFeePercent);
        formData.append('clientEmail', clientEmail)
        formData.append('clientFirstName', clientFirstName)
        formData.append('hourlyIncrement', hourlyIncrement)
        formData.append('deposit', deposit)
        formData.append('damage_deposit', damage_deposit)
        formData.append('deposit_amt', deposit_amt)
        formData.append('damage_deposit_amt', damage_deposit_amt)
        formData.append('addons', JSON.stringify(addons))
        formData.append('discounts', JSON.stringify(discounts))
        formData.append('instances', JSON.stringify(instances))
        formData.append('item_tax', JSON.stringify(itemTax))
        formData.append('max_deposit_amt', max_deposit_amt)
        if (photos) {
            for (const photo of photos) {
                formData.append('photos', photo);
                formData.append(`sortOrder_${photo.name}`, photo.sortOrder);
            }
        }
        if(addOnPhotos){
            let addOnPhotoMap = [];
            let index = 0;
            for(const photoSet of addOnPhotos){
                for(const photo of photoSet){
                    formData.append(`addOnPhotos`, photo);
                    addOnPhotoMap.push({itemIndex: photo.index, photoIndex: index})
                    index++
                }
            }
            formData.append('addOnPhotoMap', JSON.stringify(addOnPhotoMap));
        }
        // post to bookableItem
        const response = yield axios.post(`/api/bookableItem`, formData);
        const newItemId = response.data[0].id;
        // flatten the array and remove duplicate
        if (duration === 'singleDay') {
            yield put({ type: 'ON_NEW_ITEM_ID' });
            yield put({ type: 'SEND_NEW_ITEM_ID', payload: { newItemId } });
        } else {
            yield axios.post(`/api/bookableItem/availability/${newItemId}`, {
                availableDays: [...new Set(availableDays?.flat() ?? [])],
                isSingleDay: false,
                startHour: startTime ? startTime.getHours() : null,
                endHour: endTime ? endTime.getHours() : null,
                isAddNew: true,
                increment: hourlyIncrement
            });
        }
        //DELETE LOG
        yield put({ type: 'FETCH_BOOKABLE_ITEM' });
        yield put({ type: 'LOADING_FINISHED_SUCCESS' });
    } catch (err) {
        console.error('ERROR in POST BookableItem saga', err);
        yield put({ type: 'ERROR_IN_ADD_BOOKABLE_ITEM', payload: err });
        yield put({ type: 'LOADING_FINISHED_FAILURE' });
    }
} // end postBookableItem

function* fetchBookableItemsByCategory(action) {
    const {idList} = action.payload;

    try {
        const response = yield axios.get(`/api/bookableItem/bookableItemByCategory/${idList}`);
        yield put({
            type: 'SET_ITEM_BY_CATEGORY',
            payload: [...response.data]
        })
    } catch (error) {
        console.error('ERROR getting data in fetchClients', error);
    }
}

function* fetchBookableItemsByClientId(action) {
    const {clientId} = action.payload;
    try {
        const nocategoryItem = yield axios.get(`/api/bookableItem/bookableItemByCategory/nocategory/${clientId}`);
        yield put({
            type: 'SET_ITEM_BY_CLIENT_ID',
            payload: [...nocategoryItem.data]
        })
    } catch (error) {
        console.error('ERROR getting data in fetchClients', error);
    }
}

const getRepeatingWeekDay = availability => {
    if(!availability.length) return [];
    const weekDays = availability.filter(a => a.week_day !== undefined && a.week_day !== null).map(a => a.week_day);
    if (weekDays.length < 2) return [];
    // get the pattern
    let i = 1;
    const pattern = [weekDays[0]];
    while(i < weekDays.length && weekDays[i] !== weekDays[0]) {
        pattern.push(weekDays[i]);
        if (pattern.length > 7) return [];
        i++;
    }
    // translate to week day
    return pattern.map(p => weekDayDict[p]);
}

function* fetchAvailabilityByItem(action) {
    const { itemId, isEdit, adminFlag } = action.payload;
    try {
        const { data: availability } = yield axios.post(`/api/bookableItem/bookableItemAvailability`, {
            id: itemId,
            adminFlag
        });
        const { availabilityDate, rentedDate, availabilityDateWithDay } = availability;
        yield put({
            type: 'SET_AVAILABILITY_TO_ITEM',
            payload: isEdit ? availabilityDateWithDay : availabilityDate
        })
        yield put({
            type: 'SET_RENTED_DATE_TO_ITEM',
            payload: rentedDate
        })
        if (isEdit && availabilityDateWithDay) {
            yield put({
                type: 'SET_REPEATING_WEEKDAY_TO_ITEM',
                payload: getRepeatingWeekDay(availabilityDateWithDay)
            })
        }

    } catch (error) {
        console.error('ERROR getting availability: ', error);
    }
}

function* fetchAvailableHours(action) {
    const { itemId, date } = action.payload;
    try {
        const { data } = yield axios.get(`/api/bookableItem/availableTimeSlot?itemId=${itemId}&date=${formatDate(date)}`);
        yield put({
            type: 'SET_AVAILABLE_HOURS',
            payload: data
        })
    } catch (e) {
        console.error('ERROR getting availability', e);
    }
}

export default bookableItemSaga;
