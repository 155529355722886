import {useDropzone} from "react-dropzone";
import React, {useEffect, useMemo, useState} from 'react';
import Box from "@mui/material/Box";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const UploadPhotos = props => {
    const { handleDropFiles, prevPhoto, handleDeletePrev } = props;
    const [fileState, setFileState] = useState([]);
    const [maxSortOrder, setMaxSortOrder] = useState(0);
    const sortByOrder = (a, b) => a.sortOrder - b.sortOrder;
    //todo: error after order changed after add new one 
    const {getRootProps, getInputProps, isFocused, isDragReject, isDragAccept} = useDropzone({
        onDrop: acceptedFiles => {
            const filesDropped = acceptedFiles.map((file, index) => {
                const sortOrder = maxSortOrder + index + 1;
                return Object.assign(file, {
                    preview: file.prevFlag ? null : URL.createObjectURL(file),
                    sortOrder
                });
            });
            const newMaxSortOrder = maxSortOrder + filesDropped.length;
            setMaxSortOrder(newMaxSortOrder);
            setFileState(prev => {
                const next = [...filesDropped, ...prev.map(p => ({...p, sortOrder: p.sortOrder + acceptedFiles.length}))].sort(sortByOrder);
                handleDropFiles(next);
                return next;
            });
        }
    });
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    const grid = 8;

    const handleDeleteFile = currFile => {
        setFileState(prev => {
            const toDeleteIndex = currFile.source.index;
            handleDeletePrev(prev.find(p => p.sortOrder === toDeleteIndex));
            return prev.filter(p => p.sortOrder !== toDeleteIndex).map(p => (
                {
                    ...p,
                    sortOrder: p.sortOrder > toDeleteIndex ? p.sortOrder - 1 : p.sortOrder
                }
            )).sort(sortByOrder);
        })
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "lightgrey",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: 150,
        'margin-left': 120
    });

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            handleDeleteFile(result);
            return;
        }

        const items = reorder(
            fileState,
            result.source.index,
            result.destination.index
        );
        const next = items.map((file, index) => Object.assign(file, {
            preview: file.prevFlag ? null : URL.createObjectURL(file),
            sortOrder: index
        })).sort(sortByOrder);

        setFileState(next);
        handleDropFiles(next);
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => fileState.forEach(file => file.prevFlag ? null :  URL.revokeObjectURL(file.preview));
    }, []);

    useEffect(() => {
        setFileState(() => {
            return prevPhoto?.map(p => (
                {
                    ...p,
                    prevFlag: true,
                    sortOrder: p.sort_order
                }
            )).sort(sortByOrder) ?? [];
        })
    }, [prevPhoto]);

    const getPicSrc = file => {
        if (file.prevFlag) {
            return `/categoryList/picKey/${file.url}`;
        }
        return file.preview;
    }

    const getDraggableId = (index, file) => {
        if (file.prevFlag) {
            return `${index}${file.name}`;
        }
        return `${index}${file.path}`
    }

    const displayMultipleFiles = () => {
        return (
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {fileState.map((file, index) => {
                                    return (
                                        <Draggable index={index} key={getDraggableId(index, file)} draggableId={getDraggableId(index, file)}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div style={thumb} key={file.name}>
                                                        <div style={thumbInner}>
                                                            <img
                                                                src={getPicSrc(file)}
                                                                style={img}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        )
    }

    return (
        <section className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside style={thumbsContainer}>
                {fileState && fileState.length ? displayMultipleFiles() : null}
            </aside>
        </section>
    )
}

export default UploadPhotos;
