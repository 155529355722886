import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import formatMoney from "../../formatters/money.format";
import React from "react";
import defineUnits from "../../helpers/defineUnits.helper";

export default function PaymentInfo(props) {
    const {
        bookableItem,
        selectedAddOns,
        seasonalPrice,
        unitsBooked,
        discount,
        orf,
        ao,
        sav,
        tnf,
        dlt,
        dtt,
        payInFull,
        total
    } = props;
    const displayCheckoutDetail = () => {
        if (seasonalPrice?.seasonalPrice?.length) {
            const {averagePrice} = seasonalPrice;
            const priceDisplay = averagePrice.toFixed(2);
            return (
                <Grid item xs={6} textAlign="left">
                    <Typography><span style={{
                        textDecoration: 'line-through',
                        marginRight: '5px'
                    }}>${bookableItem.rate} x {unitsBooked ? unitsBooked : 0}</span><span>${priceDisplay} x {unitsBooked ? unitsBooked : 0}</span></Typography>
                </Grid>
            );
        }
        return (
            <Grid item xs={6} textAlign="left">
                <Typography>{bookableItem.rate} x {unitsBooked} {defineUnits(bookableItem.duration_type, unitsBooked)}</Typography>
            </Grid>
        );
    }
    const getUnit = (durationType) => {
        switch (durationType) {
            case 'multiDay':
                return 'day';
            case 'multiNight':
                return 'night';
            default:
                return 'hour';
        }
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography> Rate ${bookableItem && bookableItem.rate} per {getUnit(bookableItem.duration_type)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{borderBottom: 1}}></Box>
            </Grid>
            {displayCheckoutDetail()}
            <Grid item xs={6} textAlign="right">
                <Typography>{formatMoney(orf)}</Typography>
            </Grid>

            {/* Add-Ons */}
            {selectedAddOns?.length > 0 &&
                <>
                    <Grid item xs={6} textAlign="left">
                        <Typography>Add-Ons</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        <Typography>{formatMoney(ao)}</Typography>
                    </Grid>
                </>
            }

            {/* Discount */}
            {discount.rate > 0 &&
                <>
                    <Grid item xs={6} textAlign="left">
                        <Typography>{discount.rate}% Discount</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        <Typography>-{formatMoney(sav)}</Typography>
                    </Grid>
                </>
            }

            {/* Taxes & Fees */}
            <Grid item xs={6} textAlign="left">
                <Typography>Taxes & Fees</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
                <Typography>{formatMoney(tnf)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{borderBottom: 1}}></Box>
            </Grid>

            {/* Total */}
            <Grid item xs={6} textAlign="left">
                <Typography>Total</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
                <Typography>{formatMoney(total)}</Typography>
            </Grid>

            {/* If not paid in full */}
            {!payInFull &&
                <>
                    <Grid item xs={6} textAlign="left">
                        <Typography>Due Today</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        <Typography>{formatMoney(dtt)}</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="left">
                        <Typography>Due Later</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        <Typography>{formatMoney(dlt)}</Typography>
                    </Grid>
                </>
            }
        </Grid>
    )
}
