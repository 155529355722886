import React, { useState, useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Button, TextField, Grid, Box,
    Stack, FormControl } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import DropZone from '../ClientPhotos/DropZone'
export default function BookableItemRequest() {

    const client_id = useSelector(store => store.user.id)
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [detail, setDetail] = useState('');
    const [rate, setRate] = useState('');
    const [duration_type, setDurationType] = useState('');
    const [min_lead_time, setMinLeadTime] = useState('');
    const [duration, setDuration] = useState('');
    const [deposit, setDeposit] = useState(false);
    const [damage_deposit, setDamageDeposit] = useState('');
    const [capacity, setCapacity] = useState('');
    const [location, setLocation] = useState('');
    const [integration, setIntegration] = useState('');
    const [quantity, setQuantity] = useState('');
    const [add_on, setAddOn] = useState('')

    const photos = useSelector(store => store.photoReducer.photos)
    const clientFirstName = useSelector(store => store.user.first_name)
    const clientLastName = useSelector(store => store.user.last_name)
    const companyName = useSelector(store => store.user.company_name)
    const clientEmail = useSelector(store => store.user.email)
    const clientPhone = useSelector(store => store.user.phone_number_1)
    const history = useHistory()
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        history.push('/user');
        window.location.reload();
    };

    const handleDelete = (e, index) => {
        e.preventDefault()
        dispatch({
            type: 'DELETE_REQUEST_PHOTO', 
            payload: index
        })
    }

    const BootstrapButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        borderColor: '#821E0D',
        color: '#821E0D',
        borderRadius: '25px',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#821E0D',
            borderColor: '#821E0D',
            boxShadow: 'none',
            color: 'white',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#821E0D',
            borderColor: '#821E0D',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(130,30,13,.5)',
        },
    });

    useEffect(() => {
        dispatch({
            type: 'CLEAR_PHOTOS'
        })
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('title', title)
        formData.append('summary', summary)
        formData.append('detail', detail)
        formData.append('rate', rate)
        formData.append('duration', duration)
        formData.append('duration_type', duration_type)
        formData.append('min_lead_time', min_lead_time)
        formData.append('deposit', deposit)
        formData.append('damage_deposit', damage_deposit)
        formData.append('capacity', capacity)
        formData.append('location', location)
        formData.append('integration', integration)
        formData.append('quantity', quantity)
        formData.append('clientFirstName', clientFirstName)
        formData.append('clientLastName', clientLastName)
        formData.append('companyName', companyName)
        formData.append('clientEmail', clientEmail)
        formData.append('clientPhone', clientPhone)
        formData.append('add_on', add_on)

        
        for (let photo of photos) {
            // console.log('photo is', photo);
            formData.append('photos', photo)
        }

        axios.post(`/api/bookableItem/request/${client_id}`, 
            formData
            // {title, summary, detail, rate, duration, duration_type, min_lead_time, deposit, damage_deposit, capacity, location, integration, quantity, formData}
        )

        setOpen(true)
    }

    return (
        <>
            <Grid container maxWidth="md" mx="auto" direction="column" my={4} sx={{ padding: '20px' }}>
                <Box component='form'>
                    <Typography variant='h3' padding='10px' align='center'>New Bookable Item Request</Typography>
                    <Typography variant='h5' padding='14px' align='center'>Please enter the required information</Typography>
                    {/*  */}
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Title</Typography>
                        <Typography padding='5px' variant='h6'>Please enter the name of your item.</Typography>
                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Title'
                                multiline
                                rows={4}
                                
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Summary</Typography>
                        <Typography padding='5px' variant='h6'>Describe a brief summary.</Typography>
                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Summary'
                                multiline
                                rows={4}
                                
                                value={summary}
                                onChange={(e) => setSummary(e.target.value)}

                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Detail</Typography>
                        <Typography padding='5px' variant='h6'>Describe your item in detail.</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Detail'
                                multiline
                                rows={4}
                                
                                value={detail}
                                onChange={(e) => setDetail(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Rate</Typography>
                        <Typography padding='5px' variant='h6'>What is the rate of your item? Example: $5 per hour, $60 per day.</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Rate'
                                multiline
                                rows={4}
                                
                                value={rate}
                                onChange={(e) => setRate(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Duration Type</Typography>
                        <Typography padding='5px' variant='h6'>Will your item be daily, hourly, or other?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Duration Type'
                                multiline
                                rows={4}
                                
                                value={duration_type}
                                onChange={(e) => setDurationType(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Min Lead Time</Typography>
                        <Typography padding='5px' variant='h6'>Booking or Reservation Lead Time is the period of time (most typically measured in calendar days) between when a guest makes the reservation and the actual check-in/arrival date.</Typography>
                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Min Lead Time'
                                multiline
                                rows={4}
                                
                                value={min_lead_time}
                                onChange={(e) => setMinLeadTime(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Duration</Typography>
                        <Typography padding='5px' variant='h6'>What is the duration type of your bookable item? Example: Blocks of two hours, blocks of one day.</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Duration'
                                multiline
                                rows={4}
                                
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    {/* TODO: make a checkbox */}
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Deposit</Typography>
                        <Typography padding='5px' variant='h6'>Will you allow a partial upfront payment to be made at time of booking with the balance paid two days prior to reservation date? If so, what is the percentage of the total?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Deposit'
                                multiline
                                rows={4}
                                
                                onChange={(e) => setDeposit(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    {/* TODO: make a checkbox */}
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Damage Deposit</Typography>
                        <Typography padding='5px' variant='h6'>Will your item require a damage deposit? If so, how much?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Damage Deposit'
                                multiline
                                rows={4}
                                
                                onChange={(e) => setDamageDeposit(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Capacity</Typography>
                        <Typography padding='5px' variant='h6'>What is the maximum capacity for your item? Example: two people max, ten people max.</Typography>
                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Capacity'
                                multiline
                                rows={4}
                                
                                value={capacity}
                                onChange={(e) => setCapacity(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Location</Typography>
                        <Typography padding='5px' variant='h6'>What is the address of your item's location?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Location'
                                multiline
                                rows={4}
                                
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Integration (optional)</Typography>
                        <Typography padding='5px' variant='h6'>Where on your website would you like the book button for this item?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Integration'
                                multiline
                                rows={4}
                                value={integration}
                                onChange={(e) => setIntegration(e.target.value)}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Quantity</Typography>
                        <Typography padding='5px' variant='h6'>How many of these items do you have in your inventory?</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Quantity'
                                multiline
                                rows={4}
                                
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                            />
                        </FormControl>

                    </Grid>

                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Add-Ons</Typography>
                        <Typography padding='5px' variant='h6'>Will your item have add-ons? If so, describe them</Typography>

                        <FormControl margin='normal' fullWidth>
                            <TextField
                                variant='standard'
                                // label='Item Quantity'
                                multiline
                                rows={4}
                                value={add_on}
                                onChange={(e) => setAddOn(e.target.value)}
                            />
                        </FormControl>

                    </Grid>

                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }} padding='5px' variant='h5'>Item Photos</Typography>
                        <Typography padding='5px' variant='h6'>Upload your photos, if Applicable</Typography>

                        <FormControl margin='normal' fullWidth>
                            <DropZone isGallery={false} />

                        </FormControl>

                    </Grid>

                    <div className="images">
                        {photos &&
                            photos?.map((photo, index) => {
                                return ( 
                                <Stack padding={'8px'} direction={'row'}>
                                    <p>{photo.name}</p>
                                    <button onClick={(e) => handleDelete(e, index)}>delete</button>
                                </Stack>
                                );
                            })}
                    </div>
                    
                    <Box display="flex" justifyContent="right" margin="normal">
                        <Button
                            onClick={handleSubmit}
                            // type="submit"
                            variant="contained"
                            size="large"
                            style={{margin: '20px'}}
                        >
                            Submit Request
                        </Button>
                    </Box>

                </Box>
            </Grid>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Thank you for submitting a bookable item request!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            We will respond to you within one business day!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <BootstrapButton onClick={handleClose} autoFocus>
                            Close
                        </BootstrapButton>
                    </DialogActions>
                </Dialog>
            </div>

        </>
    )
}