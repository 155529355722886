import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import InputMask from 'react-input-mask';

function ContactForm() {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [comments, setComments] = useState('');

  const errors = useSelector((store) => store.errors);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addContact = (event) => {
    event.preventDefault();

    dispatch({
      type: 'ADD_CONTACT',
      payload: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        // To strip out parenthesis and dash in phone number, call "replace"
        // phone: values.textmask.replace(/\D/g, ''),
        phone: phone.replace(/\D/g, ''),
        organizationName: organizationName,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zip, zip,
        comments, comments
      },
    });
    //TODO: set up clear inputs on submit (a bit tricky cuz of phone masking)

    handleClickOpen();
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone('')
    setOrganizationName('')
    setComments('')
  }; // end add contact

  const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    borderColor: '#821E0D',
    color: '#821E0D',
    borderRadius: '25px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#821E0D',
      borderColor: '#821E0D',
      boxShadow: 'none',
      color: 'white',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#821E0D',
      borderColor: '#821E0D',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(130,30,13,.5)',
    },
  });

  return (
    <form className="formPanel" onSubmit={addContact}>

      {/* TODO: Handle error */}
      {/* {errors.contactFormMessage && (
        <h3 className="alert" role="alert">
          {errors.contactFormMessage}
        </h3>
      )} */}

      <Grid container maxWidth="md" mx="auto" direction="column" my={4} sx={{ padding: '20px' }}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="First Name"
                type="text"
                name="firstName"
                value={firstName}
                required
                variant='standard'
                onChange={(event) => setFirstName(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Last Name"
                type="text"
                name="lastName"
                value={lastName}
                required
                variant='standard'
                onChange={(event) => setLastName(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Email"
                type="text"
                name="email"
                value={email}
                required
                variant='standard'
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputMask
                mask="(999) 999-9999"
                disabled={false}
                maskChar=" "
                onChange={(event) => setPhone(event.target.value)}
                >
                  {() => <TextField label="Phone" required type={'text'} variant="standard" />}
              </InputMask>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Company Name"
                type="text"
                name="organizationName"
                value={organizationName}
                variant='standard'
                onChange={(event) => setOrganizationName(event.target.value)}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Address Line 1"
                type="text"
                name="address1"
                value={address1}
                variant='standard'
                onChange={(event) => setAddress1(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Address Line 2"
                type="text"
                name="address2"
                value={address2}
                variant='standard'
                onChange={(event) => setAddress2(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="City"
                type="text"
                name="city"
                value={city}
                variant='standard'
                onChange={(event) => setCity(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="State"
                type="text"
                name="state"
                value={state}
                variant='standard'
                onChange={(event) => setState(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Zip"
                type="text"
                name="zip"
                value={zip}
                variant='standard'
                onChange={(event) => setZip(event.target.value)}
              />
            </FormControl>
          </Grid> */}
        </Grid>

        <Grid container item xs={12} justifyContent="center">
          <FormControl fullWidth margin="normal">
            <TextField
              id="comments"
              label="Comments"
              multiline
              rows={4}
              value={comments}
              variant='standard'

              onChange={(event) => setComments(event.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid container item xs={6} justifyContent="center">
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              {/* <Button type="submit" variant="contained" size="large" startIcon={<PersonAddIcon />}>
                Submit
              </Button> */}
              <BootstrapButton type="submit" variant="outlined">
                Submit
              </BootstrapButton>

            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {/* Render "Thank You" pop-up window after user clicks Submit button */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Thank you for contacting us!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We will respond to you within one business day.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <BootstrapButton onClick={handleClose} autoFocus>
              Close
            </BootstrapButton>
          </DialogActions>
        </Dialog>
      </div>
    </form>
  );
}

export default ContactForm;
