import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, FormControl, FormControlLabel, Checkbox, Button, PersonAddIcon, Input, InputLabel, Typography, Box } from '@mui/material'
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import CompanyNav from '../Nav/CompanyNav';
import InputMask from 'react-input-mask';



import { styled } from '@mui/material/styles';


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

export default function SignUp() {



    const [values, setValues] = React.useState({
        textmask: '(123) 000-0000'
    });

    const history = useHistory()

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setcompanyName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [comments, setComments] = useState('');
    const [termsOfService, setTermsOfService] = useState(false)
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch()
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
        // setPhone(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
        history.push('/home')
        window.location.reload();
    };


    const handleSubmit = (e) => {
        e.preventDefault()
        if (termsOfService === false) {
            alert('please agree to our terms of service')
            return
        }

        dispatch({
            type: 'ADD_SIGNUP', 
            payload: {
                firstName: firstName, 
                lastName: lastName, 
                email: email, 
                phone: phone, 
                companyName: companyName
            }
        })
        setOpen(true)

    }
    const BootstrapButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        borderColor: '#821E0D',
        color: '#821E0D',
        borderRadius: '25px',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#821E0D',
            borderColor: '#821E0D',
            boxShadow: 'none',
            color: 'white',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#821E0D',
            borderColor: '#821E0D',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(130,30,13,.5)',
        },
    });


    return (
        <>
            <CompanyNav/>
            <Grid container maxWidth="md" mx="auto" direction="column" my={4} sx={{ padding: '20px' }}>
                <Box component='form' onSubmit={handleSubmit}>


                    <Grid item my={3} spacing={2}>
                        <Typography variant='h3'>Become an EazieBook customer today!</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="First Name"
                                    type="text"
                                    name="firstName"
                                    value={firstName}
                                    required
                                    variant='standard'
                                    onChange={(event) => setFirstName(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Last Name"
                                    type="text"
                                    name="lastName"
                                    value={lastName}
                                    required
                                    variant='standard'
                                    onChange={(event) => setLastName(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Email"
                                    type="text"
                                    name="email"
                                    value={email}
                                    required
                                    variant='standard'
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                              <InputMask
                                mask="(999) 999-9999"
                                disabled={false}
                                maskChar=" "
                                onChange={(event) => setPhone(event.target.value.replace(/\D/g, ''))}
                                >
                                {() => <TextField label="Phone" required type={'text'} variant="standard" />}
                              </InputMask>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Company Name"
                                    type="text"
                                    name="companyName"
                                    value={companyName}
                                    variant='standard'
                                    onChange={(event) => setcompanyName(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Typography marginTop={2} sx={{ fontSize: '1.5em' }} >View our <a href="Eaziebook Terms of Service 220818.pdf" target={'_blank'} > <span style={{color: 'blue'}}>Terms of Service</span></a></Typography>

                    <Grid container item xs={6} justifyContent="center">

                        <Grid item>
                        </Grid>
                        <FormControlLabel control={<Checkbox onChange={() => setTermsOfService(!termsOfService)} />} label="I have read and agree to the Terms of Service" />

                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                {/* <Button type="submit" variant="contained" size="large" startIcon={<PersonAddIcon />}>
                                 Submit
                                 </Button> */}
                                <BootstrapButton type="submit"  variant="outlined">
                                    Submit
                                </BootstrapButton>

                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Thank you for signing up!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            We will respond to you within one business day with your username/password combo!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <BootstrapButton onClick={handleClose} autoFocus>
                            Close
                        </BootstrapButton>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )

}