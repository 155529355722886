import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useQuery from "../../hooks/useQuery";
import {Container, Paper, TextField, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import {styled} from "@mui/material/styles";
import Button from '@mui/material/Button';
import {Grid} from "@material-ui/core";
import {DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {disableDates} from "../Utils/share-functions";
import {CalendarPicker} from "@mui/x-date-pickers/CalendarPicker";
import SingleDaySelectTimeSlot from "../Calendar/SingleDaySelectTimeSlot";
export default function BookableItemCustom() {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const [dateRange, setDateRange] = useState([null, null]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedHour, setSelectedHour] = useState(null);
    const item = useSelector(store => store.selectedBookableItem);
    const avail = useSelector(store => store.selectedBookableItem.availabilityyy);
    const {tax: taxArr, title = '', summary = '', detail = '', duration_type, hourly_increment,
        availableHours, eaziebook_fee_percent} = item;
    const durationType = duration_type === 'multiNight' ? 'Nights' : duration_type === 'multiDay' ? 'Days' : 'Hours';
    const availability = useSelector(store => store.selectedBookableItem.availabilityyy?.masterList || []);
    const {id} = useParams();
    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    useEffect(() => {
        dispatch({
            type: 'FETCH_SELECTED_BOOKABLE_ITEM',
            payload: id
        });
    }, [id]);
    const price = Number(query.get('price')) || 0,
        duration = Number(query.get('duration')) || 0,
        comments = query.get('comments') || '';
    let itemTax = taxArr && taxArr[0] ? taxArr.reduce((prev, curr) => prev + curr.rate, 0) * .01 : 0;
    const tnf = Number((price * itemTax).toFixed(2));
    const ef = Number((price * (eaziebook_fee_percent || 0) * .01).toFixed(2));
    const totalPrice = USDollar.format(price + tnf + ef);
    const Item = styled(Container)(({theme}) => ({
        padding: theme.spacing(1),
        textAlign: 'center'
    }));
    const PaperItem = styled(Paper)(({theme}) => ({
        padding: theme.spacing(1),
        textAlign: 'center',
        width: '50%'
    }));
    const checkAvail = date => !availability.includes(date.toISOString().replace(/(T\d{2})/, 'T00'));
    const checkOutCust = () => {
        const [hours, minutes, seconds] = selectedHour.split(':');
        selectedDate.setHours(hours, minutes, seconds);
        history.push(`/checkout/${id}?customDate=${selectedDate.getTime()}&customTnf=${tnf}&customEf=${ef}&customDuration=${duration}&customPrice=${price}&custom=true&customComment=${comments}`);
    }
    const onDateChanges = newDate => {
        dispatch({
            type: 'FETCH_AVAILABLE_HOURS',
            payload: {
                itemId: id,
                date: newDate
            }
        });
        setSelectedDate(newDate);
    }
    const onHourChanges = newHour => {
        setSelectedHour(newHour);
    }

    return <>
        <Box sx={{width: '100%'}}>
            <Stack spacing={2} alignItems="center">
                <Item>
                    <Typography variant="h4" gutterBottom>
                        {title || ''}
                    </Typography>
                </Item>
                <Item>
                    <Typography variant="subtitle2" gutterBottom>
                        {summary || ''}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {detail || ''}
                    </Typography>
                </Item>

                {duration_type === 'multiDay' || duration_type === 'multiNight' ?
                    <Item style={{display: 'flex', justifyContent: 'center'}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateRangePicker onChange={newValue => setDateRange(newValue)}
                                             value={dateRange}
                                             disablePast
                                             shouldDisableDate={date => checkAvail(date)}
                                             renderInput={
                                                 (startProps, endProps) =>
                                                     <React.Fragment>
                                                         <TextField {...startProps} />
                                                         <Box sx={{mx: 2}}> to </Box>
                                                         <TextField {...endProps} />
                                                     </React.Fragment>
                                             }
                            />
                        </LocalizationProvider>
                    </Item>
                    :
                    <Item sx={{width: '20%'}}>
                        <>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <CalendarPicker
                                    disablePast={true}
                                    date={selectedDate}
                                    onChange={onDateChanges}
                                    shouldDisableDate={dates => disableDates(dates, [...new Set(avail.masterList)])}
                                    openTo={'day'}
                                    views={['day']}
                                />
                            </LocalizationProvider>
                            <SingleDaySelectTimeSlot
                                selectedDate={selectedDate}
                                minHour={duration}
                                maxHour={duration}
                                hoursSelection={availableHours}
                                hoursAvail={availableHours}
                                hourlyIncrement={hourly_increment}
                                onHourChangesCust={onHourChanges}
                                hideDuration={true}
                                startHourCust={selectedHour}
                            />
                        </>
                    </Item>
                }

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <PaperItem elevation={3} sx={{padding: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} textAlign="left" className={'text-left'}>
                                <Typography fontWeight='bold' variant="body1" gutterBottom>
                                    Duration:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right" className={'text-right'}>
                                <Typography fontWeight='bold' variant="body1" gutterBottom>
                                    {duration} {durationType}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="left" className={'text-left'}>
                                <Typography fontWeight='bold' variant="body1" gutterBottom>
                                    Price:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right" className={'text-right'}>
                                <Typography fontWeight='bold' variant="body1" gutterBottom>
                                    {USDollar.format(price)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="left" className={'text-left'}>
                                <Typography fontWeight='bold' variant="body1" gutterBottom>
                                    Tax & Fee:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="left" className={'text-right'}>
                                <Typography fontWeight='bold' variant="body1" gutterBottom>
                                    {USDollar.format(tnf + ef)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{borderBottom: 1}}></Box>
                            </Grid>
                            <Grid item xs={6} textAlign="left" className={'text-left'}>
                                <Typography fontWeight='bold' variant="body1" gutterBottom>
                                    Total Price:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right" className={'text-right'}>
                                <Typography fontWeight='bold' variant="body1" gutterBottom>
                                    {totalPrice}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{borderBottom: 1}}></Box>
                            </Grid>
                            <Grid item xs={6} textAlign="left" className={'text-left'}>
                                <Typography fontWeight='bold' variant="body1" gutterBottom>
                                    Comments:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right" className={'text-right'}>
                                <Typography variant="body1" gutterBottom>
                                    {comments}
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaperItem>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button variant="contained" onClick={checkOutCust} disabled={!selectedHour}>Check Out</Button>
                </div>
            </Stack>
        </Box>
    </>
};
