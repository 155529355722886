import * as React from 'react';
import CardViewGrid from "./CardViewGrid";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {CssBaseline, Container} from "@mui/material";

const RenterReservation = () => {

    const dispatch = useDispatch();
    const {data: categoryList} = useSelector(({categoryList: list}) => list);
    const itemFromCategory = useSelector((store) => store.bookableItem.bookableItemReducer);
    const itemFromClientId = useSelector((store) => store.bookableItem.itemFromClientId);
    const [categoryOnScreen, setCategoryOnScreen] = useState([]);
    const {id: clientId} = useParams();

    const setCategoryFlag = (obj, categoryFlag) => ({
        ...obj,
        categoryFlag
    });

    const categories = id => [...categoryList.filter(c => c.parentId === id)];
    const items = id => [...[...itemFromCategory, ...itemFromClientId].filter(i => i.categoryId === id)];
    const displayNext = (id = null) => [
        ...categories(id).map(c => setCategoryFlag(c, true)),
        ...items(id).map(i => setCategoryFlag(i, false))
    ];

    const handleUpdateScreen = item => setCategoryOnScreen(() => displayNext(item.id));

    useEffect(() => {
        dispatch({type: 'GET_CATEGORY_LIST_REQUEST', clientId});
        dispatch({type: 'FETCH_ITEM_BY_CLIENT_ID', payload: {
                clientId
            }
        });
    }, [dispatch]);

    useEffect(() => {
        if (categoryList.length) {
            dispatch({
                type: 'FETCH_ITEM_BY_CATEGORY', payload: {
                    idList: categoryList.map(c => c.id)
                }
            });
        }
    }, [categoryList]);

    useEffect(() => {
        setCategoryOnScreen(() => displayNext());
    }, [itemFromCategory, itemFromClientId]);

    return (
        <React.Fragment>
            <CssBaseline/>
            <Container sx={{alignItems: 'center', maxWidth: '80%'}} maxWidth={false}>
                {/* {categoryOnScreen.length && categoryOnScreen[0].parentId ? goBack() : null} */}
                {categoryOnScreen.length ? <CardViewGrid
                    updateScreen={handleUpdateScreen}
                    categoryOnScreen={categoryOnScreen}
                /> : null}
            </Container>
        </React.Fragment>
    );
}

export default RenterReservation;
