import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Button, Container, Grid, Input, TextField, Typography} from '@mui/material'
import {useHistory, useParams} from "react-router-dom";
import BookableItemInfo from './BookableItemInfo'
import BookableItemDates from './BookableItemDates'
import AddIcon from '@mui/icons-material/Add';
// import './AddBookableItem.css';
import 'react-quill/dist/quill.snow.css';
import UploadPhotos from "../UploadPhotos/UploadPhotos";
import BookableItemSingleDayHours from "./BookableItemSingleDayHours";
import AddonInputRow from './InputRows';
import DiscountRow from './DiscountRow'


function addBookableItem() {
    const [addons, setAddons] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const { addBookableItemInputs } = useSelector(store => store.bookableItem);
    const { serverError } = useSelector(store => store.errors);
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const client = useSelector(store => store.activeClient)
    const clientFirstName = client.first_name;
    const clientEmail = client.email
    const [instances, setInstances] = useState([])

    //grab the list of item names from the reducer
    // styles for thumbnail previews (photo upload)
    const handleDropFiles = files => {
        dispatch({
            type: 'ADD_FORM_ONCHANGE',
            payload: { property: 'photos', value: files }
        });
    }

    const instanceMaker = (x) => {
        let instance = []

        for (let i = 0; i < x; i++) {
            instance.push('')
        }
        setInstances(instance)
    }

    const handleDeleteDiscount = (e, index) => {
        const newArray = JSON.parse(JSON.stringify(discounts));
        newArray.splice(index, 1)
        setDiscounts(newArray)
    }

    const { id: clientId } = useParams();

    //category input state variables
    /* const [parentId, setParentId] = useState('');
    const [newItem, setNewItem] = useState(''); */

    useEffect(() => {
        dispatch({
            type: 'CLEAR_ADD_FORM'
        })
        dispatch({
            type: 'FETCH_COMPANY_NAME'
        });
        dispatch({
            type: 'FETCH_ACTIVE_CLIENT',
            payload: clientId
        })
    }, []);

    useEffect(() => {
        if (clientId && clientId.length) {
            dispatch({ type: 'GET_CATEGORY_LIST_REQUEST', clientId });
        }
    }, [clientId])

    useEffect(() => {
        if (addBookableItemInputs.onPostingSingleDate === false) {
            setTimeout(() => {
                history.push(`/viewBookableItem/${params.id}`);
            }, 1000)
        }
    }, [addBookableItemInputs?.onPostingSingleDate])

    const handleChange = (evt, property) => {
        const directChangeableList = ['summary', 'details', 'categoryId', 'itemTax', 'hourlyIncrement'];
        if (directChangeableList.includes(property)) {
            // setNewBookableItem({ ...newBookableItem, [property]: evt })
            dispatch({
                type: 'ADD_FORM_ONCHANGE',
                payload: { property: property, value: evt }
            });
        }
        else if (property === 'deposit' || property === 'damage_deposit'){
            const checkVal = eval(evt.target.value)
            dispatch({
                type: 'ADD_FORM_ONCHANGE',
                payload: { property: property, value: !checkVal }
            });

        }
        else {
            // setNewBookableItem({ ...newBookableItem, [property]: evt.target.value })
            dispatch({
                type: 'ADD_FORM_ONCHANGE',
                payload: { property: property, value: evt.target.value }
            });
        }
    };

    const addNewBookableItem = (evt, addons) => {
        evt.preventDefault();
        let alertMsg = '';
        const validate = () => {
            if (!addBookableItemInputs.photos) {
                alertMsg = 'please upload a photo';
                return false;
            }
            if (instances.length === 0) {
                return false
            }
            if (!addBookableItemInputs.categoryId
                || !addBookableItemInputs.categoryId
                || addBookableItemInputs.categoryId === '0') {
                alertMsg = 'please select a category';
                return false;
            }
            return true;
        }
        if (!validate()) {
            alert(alertMsg)
            return
        }
        dispatch({
            type: 'POST_BOOKABLE_ITEM',
            payload: {
                newBookableItem: addBookableItemInputs,
                clientFirstName: clientFirstName,
                clientEmail: clientEmail,
                addons: addons,
                discounts: discounts, 
                id: params.id
            }
        })
        if (serverError.response && serverError.response.status && serverError.response.status !== 500) {
            return null
        } else {
            if (addBookableItemInputs.duration !== 'singleDay' && addBookableItemInputs.duration !== 'singleNight') {
                history.push(`/user`);
            }
        }
    }

    const setInstanceField = (e, index) => {
        let newInstances = [...instances]
        newInstances[index] = e.target.value
        setInstances(newInstances)
        dispatch({
            type: 'ADD_FORM_ONCHANGE',
            payload: { property: 'instances', value: newInstances }
        });
    }

    return (
        <>
            <Container component='main'>
                <Box
                    onSubmit={event => addNewBookableItem(event, addons)}
                    component="form"
                >
                    <BookableItemInfo handleChange={handleChange} />
                    <Grid container maxWidth='md' mx='auto' direction='column' alignContent='center' my={4}>
                        <Grid item sm={2}>
                            <Typography padding='20px' variant='h4'>Select And Name Your instances</Typography>

                        </Grid>
                        <Grid item alignSelf='center' sm={2} padding='20px'>
                            <Input
                                type='number'
                                required
                                placeholder='Instance #'
                                onChange={(e) => instanceMaker(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignContent='center' maxWidth="md" mx="auto" direction="column" my={4} padding={4}>
                        {instances.map((item, index) => (
                            <Grid item padding='12px' sm={10}>
                                {/* <FormControl fullWidth margin='normal'> */}
                                    <TextField
                                        onChange={(e) => setInstanceField(e, index)}
                                        key={index}
                                        fullWidth
                                        variant='standard'
                                        placeholder={`instance ${index + 1}`}
                                        required
                                        size='medium'
                                        sx={{width: { sm: 100, md: 400 }}}
                                    />
                                {/* </FormControl> */}

                            </Grid>
                        ))}
                    </Grid>
                    <Typography padding='12px' align='center' variant='h4' >Add your discounts, if applicable</Typography>
                    <Box display='flex' padding='22px' justifyContent='center' margin='normal'>
                        <Button
                            variant="contained"

                            onClick={() => {
                                setDiscounts(currentState => {
                                    const newItem = { minUnits: '', discountRate : '' };
                                    return currentState.concat(newItem); // ([...discounts, newItem])
                                });
                            }} 

                            padding='10px'
                            >
                            Add a discount
                        </Button>
                    </Box>

                    {/* TODO: make this work with discounts  */}

                    <Grid container maxWidth="md" mx="auto" direction="column" padding={4}>
                        {discounts.map((discount, index) => <DiscountRow key={index} handleDeleteDiscount={handleDeleteDiscount} setDiscounts={setDiscounts} discount={discount} index={index} />)}
                    </Grid>

                    {/* photo upload */}
                    <Grid container maxWidth='md' mx='auto' direction='column' alignContent='center' padding='8px' my={4}>
                        <Grid item>
                            <UploadPhotos
                                handleDropFiles={handleDropFiles}
                                disableFlag={false}
                                handleSelectedCover={event => handleChange(event, 'cover')}
                            />
                        </Grid>
                    </Grid>
                    {/* end photo upload */}

                    {addBookableItemInputs.duration === 'singleDay' ?
                        <BookableItemSingleDayHours clientId={clientId}
                                                    increment={addBookableItemInputs?.hourlyIncrement}
                                                    newItemIdForAdding={addBookableItemInputs?.newItemId ? addBookableItemInputs.newItemId.newItemId : null} />
                        : <BookableItemDates />}
                    <Box display='flex' justifyContent='center' margin='normal'>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setAddons(currentState => {
                                    const newItem = { description: '', price: '' };
                                    return currentState.concat(newItem);    // ([...addOns, newItem])
                                });
                            }} >
                            Create an Addon
                        </Button>
                    </Box>

                    <Grid container maxWidth="md" mx="auto" direction="column" padding={4}>
                        {addons.map((addon, index) => <AddonInputRow setAddons={setAddons} addon={addon} index={index} />)}
                    </Grid>


                    <Box display="flex" justifyContent="right" margin="normal">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            startIcon={<AddIcon />}
                        >
                            Add Item
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    )
}



export default addBookableItem;