import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import formatDate from '../../formatters/date.format';
import {intlNumFormat} from '../../formatters/money.format';

import { useSelector } from 'react-redux';

const FinancialTable = () => {
  const [rows, setRows] = useState([]);
  const [reportType, setReportType] = useState('ALL');
  const [totals, setTotals] = useState({eaziebook_fee: 0, tax: 0, client_fee: 0});

  useEffect(() => {
    const init = async () => {
      const {data: answer} = await axios.put('/api/reports/financial-reports', {reportType});
      setRows(answer.payments);
      setTotals(answer.totals);
    }
    init()
  }, [reportType]);
  const auth = useSelector(store => store.user.authLevel);

  if(!rows || !totals) return null;

  const RenderToolBar = () => {

    return <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={reportType}
          label="Age"
          onChange={evt => setReportType(evt.target.value)}
        >
          <MenuItem value={'ALL'}>Show All</MenuItem>
          <MenuItem value={'YTD'}>Show YTD</MenuItem>
        </Select>
      </FormControl>
    </>
  }

  const RenderTotals = () => {
      if(auth === 'admin'){
        return (
        <Box sx={{ pl: 2 }}>
          <Typography variant="h6" gutterBottom='true'>
              Total EazieBook Fees: {intlNumFormat(totals.eaziebook_fee)}
          </Typography>
        </Box>)
      } else {
        return (
        <Box sx={{ pl: 2 }}>
          <Typography variant="h6" gutterBottom='true'>
            Total Rental Fees: {intlNumFormat(totals.amount)}
          </Typography>
          <Typography variant="h6" gutterBottom='true'>
            Total Taxes: {intlNumFormat(totals.tax)}
          </Typography>
          <Typography variant="h6" gutterBottom='true'>
            Total Other Fees: {intlNumFormat(totals.eaziebook_fee)}
          </Typography>
        </Box>)
      }
  }

  const RenderTable = () => {
    return (<>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Payment ID</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Subtotal</TableCell>
              <TableCell align="right">Tax</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={[{ '&:last-child td, &:last-child th': { border: 0 } }, row.credit_debit === 'debit' && {backgroundColor: '#ff220091'}]}
              >
                <TableCell component="th" scope="row">
                  {formatDate(row.payment_date)}
                </TableCell>
                <TableCell >{row.payment_intent}</TableCell>
                <TableCell align="right">{intlNumFormat(row.total)}</TableCell>
                <TableCell align="right">{intlNumFormat(row.amount)}</TableCell>
                <TableCell align="right">{intlNumFormat(row.tax)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>)
  }
  
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5" align='center' gutterBottom='true'>
          Financial Report
        </Typography>
        <RenderToolBar />
      </Box>
      <RenderTotals />
      <RenderTable />
    </>
  )
}

export default FinancialTable;