import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";
import {useLocation, useParams} from "react-router-dom";
import {useSelector} from 'react-redux'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 750,
        },
    },
};

function SelectTax({ onTaxChange, selectionWidth = 800 , editMode = false, currentTaxSelection = [] }) {
    const [selections, setSelections] = React.useState([]);
    const [taxSelected, setTaxSelected] = React.useState(currentTaxSelection.map(item => item.title));
    const getClientId = () => {
        if (editMode) {
            const useQuery = () => new URLSearchParams(useLocation().search);
            return useQuery().get('clientId');
        }
        const { id } = useParams();
        return id;
    }

    const clientId = getClientId();

    React.useEffect(() => {
        axios.get(`/api/tax/${clientId}`).then(res => setSelections(res.data)).catch(err => console.error(err));
    }, []);

    React.useEffect(() => {
        if(editMode){
            setTaxSelected((currentTaxSelection ?? []).map(i => i.title));
        }
    }, [currentTaxSelection.length])

    const handleChange = event => {
        const {
            target: { value },
        } = event;
        setTaxSelected(() => {
            // On autofill, we get a stringified value.
            const next = typeof value === 'string' ? value.split(',') : value;
            onTaxChange(selections.filter(s => next.includes(s.title)));
            return next;
        });

    };

    return (
        <div>
            {/* <FormControl sx={{ m: 1, width: selectionWidth }}> */}
            <FormControl fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">Tax</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={taxSelected}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tax" />}
                    renderValue={selected => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {selections.filter(s => s.title && s.title.length).map(({id, title, description, rate}) => (
                        <MenuItem key={id} value={title}>
                            <Checkbox checked={taxSelected.indexOf(title) > -1} />
                            <ListItemText primary={`${title}: ${description} - Tax Rate:${rate}`} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
export default SelectTax;
