import React, {useEffect, useState} from 'react'
import {Grid, FormControl, Typography, Button} from '@mui/material'
import {useDispatch} from "react-redux";
import {mapDateToString} from "../Utils/share-functions";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import moment from "moment";

export default function EditBookableItemDates(props) {
    const {multiDayAvailability} = props;
    const dispatch = useDispatch();
    const [dateSlots, setDateSlots] = useState(new Map());
    const [pickerCount, setPickerCount] = useState(0);

    const availability = (multiDayAvailability() ?? [])
        .filter((item, index, arr) => arr.indexOf(item) === index)
        .sort((a, b) => {
            const dateA = new Date(a), dateB = new Date(b);
            return dateA.getTime() - dateB.getTime();
        });
    // from backend and from change is different
    useEffect(() => {
        if (availability.length) {
            const flatArray = availability.flat();
            const map = new Map();
            let count = 0;
            let arr = [flatArray[0]];
            for (let i = 1; i < flatArray.length; i++) {
                const prevDate = flatArray[i - 1], curr = flatArray[i];
                if (Date.parse(curr) - Date.parse(prevDate) === 86400000) {
                    arr.push(curr);
                } else {
                    map.set(count, arr);
                    count++;
                    arr = [curr];
                }
            }
            map.set(count, arr);
            setDateSlots(map);
            setPickerCount(count + 1);
        }
    }, [availability.length]);

    const getUpdatedDates = (startDate, endDate) => {
        const dateArray = [], stopDate = moment(endDate);
        let currentDate = moment(startDate);
        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format("YYYY-MM-DDT00:00:00.000") + "Z");
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }

    const checkAndHandleOverLapping = next => {
        const arr = [...next.values()];
        arr.forEach((curr, index, original) => {
            const prevIndex = index - 1, prev = original[prevIndex];
            if (prev) {
                const prevLast = prev[prev.length - 1], currFirst = curr[0];
                // if it has overlapping, merge two overlapping arrays into one
                if(new Date(currFirst) < new Date(prevLast)) {
                    const newNext = [...new Set([...prev, ...curr])].sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
                    next.set(prevIndex, newNext);
                    next.delete(index);
                    setPickerCount(prev => prev - 1);
                }
            }
        })
    }

    const changeDate = (range, index) => {
        if (range[0] && range[1]) {
            const next = new Map(dateSlots.set(index, getUpdatedDates(...range)));
            checkAndHandleOverLapping(next);
            setDateSlots(() => {
                dispatch({
                    type: 'EDIT_FORM_ONCHANGE',
                    payload: {
                        property: 'availability',
                        value: [...next.values()]
                    },
                });
                return next;
            });
        }

    };

    const handleDelete = index => {
        setDateSlots(slots => {
            slots.delete(index);
            setPickerCount(pickerCount - 1);
            dispatch({
                type: 'EDIT_FORM_ONCHANGE',
                payload: {
                    property: 'availability',
                    value: [...slots.values()]
                },
            });
            return new Map(slots);
        });
    }

    const getValue = arr => [mapDateToString(arr[0]), mapDateToString(arr[arr.length - 1])]

    const pickerItem = i => (
        <Grid item alignSelf="center" key={i}>
            <FormControl margin="normal">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                        disablePast
                        value={dateSlots.has(i) ? getValue(dateSlots.get(i)) : [null, null]}
                        onChange={(newValue) => changeDate(newValue, i)}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{mx: 2}}> to </Box>
                                <TextField {...endProps} />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </FormControl>
            <Button variant="contained" size="small"
                    style={{margin: '5px', marginTop: '15px'}}
                    onClick={() => handleDelete(i)}>
                Delete
            </Button>
        </Grid>
    );
    return (
        <Grid container maxWidth="md" alignContent='center' mx="auto" direction="column" mb={4}>
            <Grid item>
                <Typography component="h2" variant="h5">
                    Enter your item's availability
                </Typography>
            </Grid>
            {pickerCount ? [...Array(pickerCount)].map((e, i) => pickerItem(i)) : null}
            <Button variant="contained" size="small"
                    style={{margin: '5px', marginTop: '15px'}}
                    onClick={() => setPickerCount(pickerCount + 1)}>
                Add More
            </Button>
        </Grid>
    )
}