import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from 'dompurify';
import './RenterViewBookableItem.css'
import RenterSelectDate from "../RenterReservation/RenterSelectDate";
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import formatMoney from '../../formatters/money.format';
import { CarouselDisplay } from "../carousel/CarouselDisplay";
import AddOnModel from './Modal/AddOnModal';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import DonationForm from './Donation/DonationForm';

const invoiceInitial = {
  totals: {
    total: 0, ao: 0, tnf: 0, rf: 0, orf: 0, unitsBooked: 0
  },
  dueToday: {
    totals: {}
  },
  dueLater: {
    totals: {}
  },
  discount: { rate: 0 }
}

export default function RenterViewBookableItem() {
  const dispatch = useDispatch();
  const history = useHistory();
  const bookableItem = useSelector(store => store.selectedBookableItem);
  const bookableItemDate = useSelector(store => store.renterBooking.bookingInput.date);
  const bookableItemHours = useSelector(store => store.renterBooking.bookingInput.hours);
  const { startHour, endHour } = useSelector(store => store.renterBooking.bookingInput);
  const bookableItemInput = useSelector(store => store.renterBooking.bookingInput);
  const [selectedAddOn, setSelectedAddOn] = useState(null);//used for Modal
  const [selectedAddOns, setSelectedAddOns] = useState([]);//used for booking
  const [modal, setModal] = useState(false);
  const [invoice, setInvoice] = useState(invoiceInitial);
  const { id } = useParams();
  const { clientId, itemLoading, duration_type } = bookableItem;
  const {
    totals: {
      total, ao, tnf, orf, unitsBooked, sav,
    },
    seasonalPrice,
    discount
  } = invoice;

  //Render only on Page Load, or url change
  useEffect(() => {
    dispatch({ type: 'CLEAR_BOOKING_INPUT' });
    dispatch({
      type: 'FETCH_SELECTED_BOOKABLE_ITEM',
      payload: id
    });
  }, [id]);

  //Side effect for bookableItemDate
  useEffect(() => {
    const fetchPricing = async () => {
      const serverPackage = { id, startDate: bookableItemDate[0], endDate: duration_type === 'multiDay' || duration_type === 'multiNight' ? bookableItemDate[bookableItemDate.length - 1] : null, selectedAddOns, bookableItemHours, isMultiDay: duration_type === 'multiDay' }
      const { data: pricing } = await axios.post('/api/payment/invoice-calc', serverPackage);
      setInvoice(pricing)
    }
    fetchPricing().then(() => null);
  }, [bookableItemInput, selectedAddOns]);

  const handleCheckout = () => {
    let dateURL;
    if (duration_type === 'multiDay' || duration_type === 'multiNight') {
      dateURL = `startDate=${bookableItemDate[0]}&endDate=${bookableItemDate[bookableItemDate.length - 1]}`
    } else if (duration_type === 'singleDay' || duration_type === 'singleNight') {
      dateURL = `date=${bookableItemDate}&hours=${bookableItemHours}&startHour=${startHour}&endHour=${endHour}`
    }
    const addOnURL = `&addOns=${selectedAddOns.join('a')}`;
                                                    // TODO: figure out mapping for multiple instances
    history.push(`/${'checkout'}/${bookableItem.id}?item=${bookableItem.instances[0].id}&${dateURL}&daysBooked=${unitsBooked}&clientId=${clientId}${addOnURL.replace(/(%$)/, '')}`)
  }

  const selectAddOn = (e, id) => {
    if (e.target.type === 'checkbox' || e.target.id === 'add-on-icon' || e.target.id === 'add-on-details') {
      return
    }
    // new way to set selected AddOn, send and an array of Id'd
    const newSelectedAddOns = [...selectedAddOns];
    if (selectedAddOns.includes(id)) {
      const index = selectedAddOns.indexOf(id)
      newSelectedAddOns.splice(index, 1);
    } else {
      newSelectedAddOns.push(id)
    }
    setSelectedAddOns(newSelectedAddOns);
  }
  const handleCheck = (id) => {
    const newSelectedAddOns = [...selectedAddOns];
    if (selectedAddOns.includes(id)) {
      const index = selectedAddOns.indexOf(id)
      newSelectedAddOns.splice(index, 1);
    } else {
      newSelectedAddOns.push(id)
    }
    setSelectedAddOns(newSelectedAddOns);
  }
  const selectedClass = (index, id) => {
    if (selectedAddOns.includes(id)) {
      return ' selected-add-on'
    }
    return ' '
  }
  const iconClick = (e, item) => {
    setSelectedAddOn(item)
    setModal(true);
  }

  const checkBookNowDisabled = () => {
    // disable button if user hasn't selected date and time
    if (duration_type === 'singleDay') {
      return !startHour || !endHour;
    } else {
      return !bookableItemDate;
    }
  }
  const bookNowDisabled = checkBookNowDisabled();

  //Prevent XSS attacks
  const dirtyHTML = `
        <div style="text-align:center">
        <h2>${bookableItem.title || ''}</h2>
        </div>
        ${bookableItem.summary || ''}
        ${bookableItem.detail || ''}
    `;
  const cleanHTML = DOMPurify.sanitize(dirtyHTML, {
    USE_PROFILES: { html: true },
  });

  const displayCheckoutDetail = () => {
    if (seasonalPrice?.seasonalPrice?.length) {
      const { finalSeasonalPrice, averagePrice } = seasonalPrice;
      const priceDisplay = averagePrice.toFixed(2);
        return (
            <div className='checkout-details'>
              <p><span style={{textDecoration:'line-through', marginRight:'5px'}}>${bookableItem.rate} x {unitsBooked ? unitsBooked : 0}</span>
                <span>Average Price: ${priceDisplay} x {unitsBooked ? unitsBooked : 0}</span></p>
              <p>${finalSeasonalPrice}</p>
            </div>
        );
    }
    return (
        <div className='checkout-details'>
          <p>${bookableItem.rate} x {unitsBooked ? unitsBooked : 0}</p>
          <p>{formatMoney(orf)}</p>
        </div>
    );
  }

  const DetailsAccordion = () => {
    const [expanded, setExpanded] = useState(false)
    return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} style={{ marginBottom: '1rem', borderRadius: '5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Discounts
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {
              bookableItem.discounts.map(dis => {
                let units;
                switch (duration_type) {
                    case 'multiDay':
                        units = 'days';
                        break;
                    case 'multiNight':
                        units = 'nights';
                        break;
                    default:
                        units = 'hours';
                }
                return (
                  <p>{dis.rate}% at {dis.min_units} {units}</p>
                )
              })
            }
          </Typography>
        </AccordionDetails>
      </Accordion>
    )
  }

  if (itemLoading) {
    return <>
      <Container align="center">
        <Box marginTop={2} marginBottom={2}>
          <Typography variant='h5'>
            Loading ...
         </Typography>
        </Box>
        {/* <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box> */}
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      </Container>
    </>
  }

  return <>
    {/* Horizontally center carousel. Set max width of the div to be equal to the max width of the image in the carousel - this
        will make the left/rigth navigation arrows not so far spread apart. */}
    <div style={{ marginTop: 30, maxWidth: 670, marginLeft: 'auto', marginRight: 'auto' }}><CarouselDisplay pics={bookableItem.url} /></div>

    <div id='renter-view-bookable-item-container'>

      {bookableItem &&
        <div id='renter-view-bookable-item-left' dangerouslySetInnerHTML={{ __html: cleanHTML }} />
      }

      <div id='renter-view-bookable-item-right'>
        {duration_type && duration_type === 'donation' ? <DonationForm bookableItemId={bookableItem.id} clientId={clientId} /> : (
          <Paper elevation={3} sx={{ padding: 3 }} id='renter-review-checkout'>
            <div className='checkout-details-rate'>
              {bookableItem.hourly_increment ? 
                <p> Rate ${bookableItem && bookableItem.rate} per hour</p>
                :
                <p> Rate ${bookableItem && bookableItem.rate} per {duration_type === 'multiNight' ? 'night' : 'day'}</p>
            }
            </div>

            {bookableItem?.discounts?.length > 0 &&
              <DetailsAccordion />
            }

            {/* <h2>{selectDateText()}</h2> */}

            <div className='checkout-details'>
              <RenterSelectDate item={bookableItem} />
            </div>

            <div className='price-divider' id='calendar-separator'></div>



            {bookableItem.add_ons.length > 0 && <>
              <div className='add-ons'>
                <p>Add-Ons: </p>
                {bookableItem.add_ons.map((item, index) => (
                  <div key={item.id} id={index} className={'add-ons-item' + selectedClass(index, item.id)} onClick={e => selectAddOn(e, item.id)}>
                    <div id={index} >
                      <span id={index} className='add-on-description'>
                        <p id={index} >{item.title} </p>
                        <p onClick={e => iconClick(e, item)} className='add-on-details' id='add-on-details'>Details</p>
                      </span>
                      <p id={index} >Price: {formatMoney(item.price * 100)}</p>
                    </div>
                    <Checkbox name={index} onChange={() => handleCheck(item.id)} checked={selectedAddOns.includes(item.id)} />
                  </div>
                ))}

              </div>

              <div className='price-divider' id='calendar-separator'></div>
            </>}

            {displayCheckoutDetail()}

            {bookableItem.add_ons.length > 0 &&
              <div className='checkout-details'>
                <p>Add-Ons</p>
                <p>{formatMoney(ao)}</p>
              </div>
            }
            {discount.rate > 0 &&
              <div className='checkout-details'>
                <p>{discount.rate}% Discount</p>
                <p>-{formatMoney(sav)}</p>
              </div>
            }

            <div className='checkout-details'>
              <p>Taxes & Fees</p>
              <p>{formatMoney(tnf)}</p>
            </div>

            <div className='price-divider'></div>

            <div className='checkout-details'>
              <p><strong>Total</strong></p>
              <p><strong>{formatMoney(total)}</strong></p>
            </div>


            <Button disabled={bookNowDisabled} sx={{ width: '100%' }} variant="contained" onClick={handleCheckout} >
              BOOK NOW
            </Button>
          </Paper>
        )}

      </div>
    </div>
    {modal && <AddOnModel handleClose={() => setModal(false)} addOn={selectedAddOn} />}
  </>
}
