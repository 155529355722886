import { combineReducers } from 'redux';

const renterBookingReducer = (state = [], action) => {
    switch (action.type) {
        case 'REVIEWING_BOOKING':
            return action.payload;
        case 'UPDATE_BOOKABLE_ITEM':
            return{
                ...state,
                ...action.payload,
            };
        case "SET_BOOKING_CONFIRMATION":
            return action.payload
    }
    return state;
};

const bookingInput = (state = { date: '', daysBooked: '', selectedInstance: null }, action) => {
    switch (action.type) {
        case 'BOOKING_FORM_ONCHANGE':
            return { ...state, [action.payload.property]: action.payload.value };
        case 'SET_SELECTED_INSTANCE':
            return {
                ...state,
                selectedInstance: action.payload
            }
        case 'CLEAR_BOOKING_INPUT':
            return { date: '' };
        default:
            return state;
    }
};

const itemReservations = (state = [], action) => {
    switch(action.type) {
        case 'SET_ITEM_RESERVATIONS':
            return action.payload;
        default: 
            return state;
    }
};

const adminCustomers = (state = [], action) => {
    switch(action.type) {
        case 'SET_ADMIN_CUSTOMERS':
            return action.payload;
        default:
            return state;
    }
}

const renterBooking = combineReducers({
    renterBookingReducer, 
    bookingInput,
    adminCustomers,
    itemReservations
})

export default renterBooking;