const addAddOnTotal = (arr) => {
    if(!arr)return 0
    let totalAddOns = 0;
    let addOnTaxes = 0;
    for(let item of arr){
        let addOnTaxRate = 0;
        for(let {rate} of item.tax){
            addOnTaxRate += rate
        }
        totalAddOns += ( Number(item.price));
        addOnTaxes += ( Number(item.price) * .01 * addOnTaxRate);
    }
    return {totalAddOns: Math.round(totalAddOns * 100), addOnTaxes: Math.round(addOnTaxes * 100)}
}

const costCalculator = ({
    //sales_tax_rate comes from renter_booking, while tax_percentage comes from the form in addCharge component
        rate, eaziebook_fee_percent, client_fee_percent,
        deposit_amt, startDate, endDate, bookableItemHours, duration_type,
        charge, tax_percentage, addables, discounts, tax, max_deposit_amt
    }) => {
    // todo: handle max_deposit_amt
    if(charge){
        const eazieFee = Math.round(Number(charge) * eaziebook_fee_percent * .01);
        const clientFee = Math.round((Number(charge) + (Number(charge) * tax_percentage * .01) + eazieFee ) / ((1 / (client_fee_percent * .01)) - 1 + (tax_percentage * .01)));
        const taxes = Math.round((Number(charge) + clientFee) * tax_percentage * .01);
        const total = Number(charge) + eazieFee + clientFee + taxes;
        return{eazieFee, clientFee, taxes, total}
    }
    const addedAddOns = addables && addables.filter(item => item?.selected);

    const end_date = new Date(endDate);
    const start_date = new Date (startDate);
    let timeDiff = (end_date.getTime() - start_date.getTime());
    if(isNaN(timeDiff))timeDiff = 0;

    const unitsBooked = duration_type === 'multiDay' || duration_type === 'multiNight'
        ? Math.round(timeDiff / (1000 * 3600 * 24)) : Number(bookableItemHours);

    let totalTaxRate = 0;
    for(let taxItem of tax){
        totalTaxRate += taxItem.rate;
    }
    const discountCalc = (discounts) => {
        //iterate through discounts, which are in order from SQL,
        //then return the values to be multiplied to apply discount
        if(!discounts) return {discountRate: 1};
        let currentRate = {rate: 0};
        for(let discount of discounts){
            if(discount.min_units <= unitsBooked){
                currentRate = discount;
            }
        }
        return {...currentRate, rate: 1 - (currentRate.rate / 100)}
    }
    const {rate: discountRate} = discountCalc(discounts, unitsBooked);
    const rental_fee = Math.round(((rate  * 100)) * unitsBooked * discountRate);
    const {totalAddOns, addOnTaxes} = addAddOnTotal(addedAddOns);
    const eazieFee = Math.round((rental_fee + totalAddOns) * eaziebook_fee_percent * .01);
    const clientFee = Math.round((rental_fee + ((rental_fee + totalAddOns) * totalTaxRate * .01) + eazieFee + totalAddOns) / ((1 / (client_fee_percent * .01)) - 1 + (totalTaxRate * .01)));
    const rentalTaxes = Math.round((rental_fee + clientFee) * totalTaxRate * .01);
    const total = rental_fee + eazieFee + clientFee + rentalTaxes + totalAddOns;
    const taxesNFees = eazieFee + clientFee + rentalTaxes + addOnTaxes;
    const orgTotal = Math.round(rental_fee / discountRate);
    const rentalDeposit = Math.round(rental_fee * deposit_amt * .01);
    const eazieDeposit = Math.round(eazieFee * deposit_amt * .01);
    const clientDeposit = Math.round(clientFee * deposit_amt * .01);
    let totalDeposit = Math.round(total * deposit_amt * .01);
    const taxDeposit = Math.round((rentalTaxes + addOnTaxes) * deposit_amt * .01);
    const rentalDepositRemaining = Math.round(total * (1 - (deposit_amt * .01)));
    const eazieDepositRemaining = Math.round(eazieFee * (1 - (deposit_amt * .01)));
    const clientDepositRemaining = Math.round(total * (1 - (deposit_amt * .01)));
    let totalDepositRemaining = Math.round(total * (1 - (deposit_amt * .01)));
    const taxDepositRemaining = Math.round(total * (1 - (deposit_amt * .01)));
    if (max_deposit_amt && max_deposit_amt < totalDeposit) {
        totalDeposit = max_deposit_amt;
        totalDepositRemaining = total - totalDeposit;
    }
    return {
        rental_fee, eazieFee, clientFee, rentalTaxes, total, taxesNFees,
        rentalDeposit, eazieDeposit, clientDeposit, totalDeposit, taxDeposit,
        rentalDepositRemaining, eazieDepositRemaining, clientDepositRemaining, totalDepositRemaining, taxDepositRemaining,
        totalAddOns, orgTotal, 
    };
}

export default costCalculator